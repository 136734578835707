import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
    getCOPRBKSquawk,
    updateCOPRBKSquawk,
} from "../../../actions/coprbksquawk";

const initialState = {
    coprbksquawkDate: "",
    // coprbksquawkMarket: "",
    // coprbksquawkTicker: "",
    // coprbksquawkExchange: "",
    coprbksquawkCompany: "",
    coprbksquawkCompanyUrl: "",
    coprbksquawkPRUrl: "",
    coprbksquawkSector: "",
    coprbksquawkSubSector: "",
    coprbksquawkSize: "",
    coprbksquawkStructure: "",
    coprbksquawkPricing: "",
    coprbksquawkCategory: "",
    coprbksquawkProcess: "",
    coprbksquawkType: "",
    coprbksquawkCapStruc: "",
    coprbksquawkAssets: "",
    coprbksquawkLiquidity: "",
    coprbksquawkMaturityWall: "",
    coprbksquawkDebtTradingLevels: "",
    coprbksquawkCounsel: "",
    coprbksquawk3PDD: "",
    coprbksquawkAdvisor: "",
    coprbksquawkLender: "",
    coprbksquawkSponsor: "",
    // coprbksquawkNotes: "",
    // coprbksquawkStatus: "",
    coprbksquawkDocketNum: "",
    coprbksquawkCaseNum: "",
    coprbksquawkCaseJurisdiction: "",
    coprbksquawkCaseDetail: "",
    coprbksquawkPOR: "",
    coprbksquawkDIPTerms: "",
    coprbksquawkStalkingHorseBidder: "",
    coprbksquawkStalkingHorseBid: "",
};

const EditCOPRBKSquawkForm = ({
    getCOPRBKSquawk,
    updateCOPRBKSquawk,
    coprbksquawk: { coprbksquawk },
}) => {
    const [formData, setFormData] = useState({ initialState });
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        coprbksquawkDate,
        // coprbksquawkMarket,
        // coprbksquawkTicker,
        // coprbksquawkExchange,
        coprbksquawkCompany,
        coprbksquawkCompanyUrl,
        coprbksquawkPRUrl,
        coprbksquawkSector,
        coprbksquawkSubSector,
        coprbksquawkSize,
        coprbksquawkStructure,
        coprbksquawkPricing,
        coprbksquawkCategory,
        coprbksquawkProcess,
        coprbksquawkType,
        coprbksquawkCapStruc,
        coprbksquawkAssets,
        coprbksquawkLiquidity,
        coprbksquawkMaturityWall,
        coprbksquawkDebtTradingLevels,
        coprbksquawkCounsel,
        coprbksquawk3PDD,
        coprbksquawkAdvisor,
        coprbksquawkLender,
        coprbksquawkSponsor,
        // coprbksquawkNotes,
        // coprbksquawkStatus,
        coprbksquawkDocketNum,
        coprbksquawkCaseNum,
        coprbksquawkCaseJurisdiction,
        coprbksquawkCaseDetail,
        coprbksquawkPOR,
        coprbksquawkDIPTerms,
        coprbksquawkStalkingHorseBidder,
        coprbksquawkStalkingHorseBid,
    } = formData;

    useEffect(() => {
        if (!coprbksquawk) getCOPRBKSquawk(id);
        if (coprbksquawk) {
            const updatedFormData = { ...initialState };
            for (const key in coprbksquawk) {
                if (key in updatedFormData)
                    updatedFormData[key] = coprbksquawk[key];
            }
            setFormData(updatedFormData);
        }
    }, [coprbksquawk, getCOPRBKSquawk, id]);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprbksquawkSize) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprbksquawkSize)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprbksquawkSize) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Edit Deal</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        updateCOPRBKSquawk(coprbksquawk._id, formData);
                        navigate(`/repository`);
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Transaction Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkDate"
                                value={coprbksquawkDate}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprbksquawkCompany"
                                value={coprbksquawkCompany}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkCompanyUrl"
                                value={coprbksquawkCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Deal Type</small>
                            <select
                                name="coprbksquawkType"
                                value={coprbksquawkType}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="A&E">A&E</option>
                                <option value="Debt Exchange">
                                    Debt Exchange
                                </option>
                                <option value="Combo">A&E + Exchange</option>
                                <option value="Pre Pack">Pre Pack</option>
                                <option value="Chapter 11">Chapter 11</option>
                                <option value="Chapter 11">Chapter 7</option>
                                <option value="Section 363">Section 363</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sector</small>
                            <select
                                name="coprbksquawkSector"
                                value={coprbksquawkSector}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Communication Services">
                                    Communication Services
                                </option>
                                <option value="Consumer Discretionary">
                                    Consumer Discretionary
                                </option>
                                <option value="Consumer Staples">
                                    Consumer Staples
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Financials">Financials</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Industrials">Industrials</option>
                                <option value="Materials">Materials</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Technology">Technology</option>
                                <option value="Utilities">Utilities</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sub-sector</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkSubSector"
                                value={coprbksquawkSubSector}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <small>* Size (e.g. 55000000)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkSize"
                                value={coprbksquawkSize}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <small>* Structure (DIP, etc)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkStructure"
                                value={coprbksquawkStructure}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprbksquawkPRUrl"
                                value={coprbksquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Restructuring Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Transaction</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Category</small>
                            <select
                                name="coprbksquawkCategory"
                                value={coprbksquawkCategory}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="0">Select</option>
                                <option value="Voluntary">Voluntary</option>
                                <option value="Involuntary">Involuntary</option>
                                <option value="Watchlist">Watchlist</option>
                                <option value="Other">Other</option>
                                <option value="Straw">Straw Man</option>
                            </select>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Process</small>
                            <select
                                name="coprbksquawkProcess"
                                value={coprbksquawkProcess}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="0">Select</option>
                                <option value="Out-of-Court">
                                    Out-Of-Court
                                </option>
                                <option value="In-Court">In-Court</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>
                                Deal Pricing (e.g. L + 200, 10.50%, etc)
                            </small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkPricing"
                                value={coprbksquawkPricing}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Assets (i.e. collateral detail)</small>
                            <textarea
                                name="coprbksquawkAssets"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkAssets}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Capital Structure</small>
                            <textarea
                                name="coprbksquawkCapStruc"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkCapStruc}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Maturity Wall</small>
                            <textarea
                                name="coprbksquawkMaturityWall"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkMaturityWall}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Debt Trading Levels</small>
                            <textarea
                                name="coprbksquawkDebtTradingLevels"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkDebtTradingLevels}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>
                                Liquidity (Cash + RC - LC - Restr. Cash)
                            </small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprbksquawkLiquidity"
                                value={coprbksquawkLiquidity}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p />
                <span className="hide-sm">
                    <strong>Participants</strong>
                </span>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Advisor (or "NA")</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkAdvisor"
                                value={coprbksquawkAdvisor}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Sponsor (or "Non-sponsored")</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkSponsor"
                                value={coprbksquawkSponsor}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Lender (lead only)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkLender"
                                value={coprbksquawkLender}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Legal Counsel (lead only)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkCounsel"
                                value={coprbksquawkCounsel}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>3rd Party Diligence (or "NA")</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawk3PDD"
                                value={coprbksquawk3PDD}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p />
                <span className="hide-sm">
                    <strong>Supplemental In-Court Detail</strong>
                </span>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Docket Number</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkDocketNum"
                                value={coprbksquawkDocketNum}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Case Number</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkCaseNum"
                                value={coprbksquawkCaseNum}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Case Jurisdiction</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprbksquawkCaseJurisdiction"
                                value={coprbksquawkCaseJurisdiction}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Case Detail</small>
                            <textarea
                                name="coprbksquawkCaseDetail"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkCaseDetail}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>POR Detail</small>
                            <textarea
                                name="coprbksquawkPOR"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkPOR}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>DIP Terms</small>
                            <textarea
                                name="coprbksquawkDIPTerms"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkDIPTerms}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Stalking Horse Bidder</small>
                            <textarea
                                name="coprbksquawkStalkingHorseBidder"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprbksquawkStalkingHorseBidder}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Stalking Horse Bid Amount</small>
                    <br />
                    <input
                        type="number"
                        maxLength="48"
                        style={{ width: "30em" }}
                        placeholder=""
                        name="coprbksquawkStalkingHorseBid"
                        value={coprbksquawkStalkingHorseBid}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Update"
                />
                <Link className="btn btn-light my-1" to="/restructuring">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

EditCOPRBKSquawkForm.propTypes = {
    getCOPRBKSquawk: PropTypes.func.isRequired,
    updateCOPRBKSquawk: PropTypes.func.isRequired,
    coprbksquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprbksquawk: state.coprbksquawk,
});

export default connect(mapStateToProps, {
    getCOPRBKSquawk,
    updateCOPRBKSquawk,
})(EditCOPRBKSquawkForm);
