import {
    GET_BKSQUAWKS,
    GET_BKSQUAWK,
    BKSQUAWK_ERROR,
    UPDATE_BKLIKES,
    DELETE_BKSQUAWK,
    ADD_BKSQUAWK,
    ADD_BKSQUAWK_COMMENT,
    REMOVE_BKSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    bksquawks: [],
    bksquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_BKSQUAWKS:
            return {
                ...state,
                bksquawks: payload,
                loading: false,
            };
        case GET_BKSQUAWK:
            return {
                ...state,
                bksquawk: payload,
                loading: false,
            };
        case ADD_BKSQUAWK:
            return {
                ...state,
                bksquawks: [payload, ...state.bksquawks],
                loading: false,
            };
        case DELETE_BKSQUAWK:
            return {
                ...state,
                bksquawks: state.bksquawks.filter(
                    (bksquawk) => bksquawk._id !== payload
                ),
                loading: false,
            };
        case BKSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_BKLIKES:
            return {
                ...state,
                bksquawks: state.bksquawks.map((bksquawk) =>
                    bksquawk._id === payload.id
                        ? { ...bksquawk, likes: payload.likes }
                        : bksquawk
                ),
                loading: false,
            };
        case ADD_BKSQUAWK_COMMENT:
            return {
                ...state,
                bksquawk: { ...state.bksquawk, comments: payload },
                loading: false,
            };
        case REMOVE_BKSQUAWK_COMMENT:
            return {
                ...state,
                bksquawk: {
                    ...state.bksquawk,
                    comments: state.bksquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
