import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRVCSQUAWK,
    UPDATE_COPRVCSQUAWK,
    GET_COPRVCSQUAWKS,
    GET_COPRVCSQUAWKS_REPO,
    GET_COPRVCSQUAWK,
    COPRVCSQUAWK_ERROR,
    UPDATE_COPRVCLIKES,
    ADD_COPRVCSQUAWK,
    ADD_COPRVCSQUAWK_COMMENT,
    REMOVE_COPRVCSQUAWK_COMMENT,
} from "./types";

// Get COPR vcsquawks
export const getCOPRVCSquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprvcsquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRVCSQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRVCSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR vcsquawks -- user repo
export const getCOPRVCSquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprvcsquawks/repo?keyword=${keyword}`);

            dispatch({
                type: GET_COPRVCSQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRVCSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprvcsquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRVCLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRVCSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprvcsquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRVCLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRVCSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR vcsquawk
export const deleteCOPRVCSquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprvcsquawks/${id}`);

            dispatch({
                type: DELETE_COPRVCSQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRVCSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR vcsquawk
export const addCOPRVCSquawk = (formdata) => async (dispatch) => {
    try {
        const res = await api.post("/coprvcsquawks", formdata);

        dispatch({
            type: ADD_COPRVCSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRVCSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR vcsquawk
export const updateCOPRVCSquawk = (id, updatedFormdata) => async (dispatch) => {
    try {
        const res = await api.put(`/coprvcsquawks/${id}`, updatedFormdata);

        dispatch({
            type: UPDATE_COPRVCSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal Updated", "success"));
    } catch (err) {
        dispatch({
            type: COPRVCSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Get COPR vcsquawk
export const getCOPRVCSquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprvcsquawks/${id}`);

        dispatch({
            type: GET_COPRVCSQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRVCSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR vcsquawk comment
export const addCOPRVCSquawkComment =
    (coprvcsquawkId, formdata) => async (dispatch) => {
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        try {
            const res = await api.post(
                `/coprvcsquawks/comment/${coprvcsquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRVCSQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRVCSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR vcsquawk comment
export const deleteCOPRVCSquawkComment =
    (coprvcsquawkId, coprvccommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprvcsquawks/comment/${coprvcsquawkId}/${coprvccommentId}`
            );

            dispatch({
                type: REMOVE_COPRVCSQUAWK_COMMENT,
                payload: coprvccommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRVCSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
