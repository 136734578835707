import {
    GET_COPRSQUAWKS,
    GET_COPRSQUAWKS_REPO,
    GET_COPRSQUAWK,
    COPRSQUAWK_ERROR,
    UPDATE_COPRLIKES,
    DELETE_COPRSQUAWK,
    UPDATE_COPRSQUAWK,
    CLEAR_COPRSQUAWK,
    ADD_COPRSQUAWK,
    ADD_COPRSQUAWK_COMMENT,
    REMOVE_COPRSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprsquawks: [],
    coprsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRSQUAWKS:
        case GET_COPRSQUAWKS_REPO:
            return {
                ...state,
                coprsquawks: payload,
                loading: false,
            };
        case GET_COPRSQUAWK:
            return {
                ...state,
                coprsquawk: payload,
                loading: false,
            };
        case ADD_COPRSQUAWK:
            return {
                ...state,
                coprsquawks: [payload, ...state.coprsquawks],
                loading: false,
            };
        case UPDATE_COPRSQUAWK:
            return {
                ...state,
                coprsquawks: state.coprsquawks.map((coprsquawk) =>
                    coprsquawk._id === payload.id
                        ? [...payload, ...state.coprsquawks]
                        : coprsquawk
                ),
                loading: false,
            };
        case CLEAR_COPRSQUAWK:
            return {};
        case DELETE_COPRSQUAWK:
            return {
                ...state,
                coprsquawks: state.coprsquawks.filter(
                    (coprsquawk) => coprsquawk._id !== payload
                ),
                loading: false,
            };
        case COPRSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRLIKES:
            return {
                ...state,
                coprsquawks: state.coprsquawks.map((coprsquawk) =>
                    coprsquawk._id === payload.id
                        ? { ...coprsquawk, likes: payload.likes }
                        : coprsquawk
                ),
                loading: false,
            };
        case ADD_COPRSQUAWK_COMMENT:
            return {
                ...state,
                coprsquawk: { ...state.coprsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRSQUAWK_COMMENT:
            return {
                ...state,
                coprsquawk: {
                    ...state.coprsquawk,
                    comments: state.coprsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
