import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRCollSquawkItem from "./DetailedCOPRCollSquawkItem";
import COPRCollSquawkCommentForm from "./COPRCollSquawkCommentForm";
import COPRCollSquawkCommentItem from "./COPRCollSquawkCommentItem";
import { getCOPRCollSquawk } from "../../../actions/coprcollsquawk";

const COPRCollSquawk = ({
    getCOPRCollSquawk,
    coprcollsquawk: { coprcollsquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRCollSquawk(id);
    }, [getCOPRCollSquawk, id]);

    return loading || coprcollsquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/collateral" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Collateral Details</h3>
            <br />
            <DetailedCOPRCollSquawkItem coprcollsquawk={coprcollsquawk} />
            <p />
            <hr />
            <br />
            <COPRCollSquawkCommentForm coprcollsquawkId={coprcollsquawk._id} />
            <br />
            <div className="comments">
                {coprcollsquawk.comments.map((comment) => (
                    <COPRCollSquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprcollsquawkId={coprcollsquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRCollSquawk.propTypes = {
    getCOPRCollSquawk: PropTypes.func.isRequired,
    coprcollsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprcollsquawk: state.coprcollsquawk,
});

export default connect(mapStateToProps, { getCOPRCollSquawk })(COPRCollSquawk);
