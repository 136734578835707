import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
    const authLinks = (
        <ul>
            <li>
                <Link to="/privatecredit">Hoot</Link>
            </li>
            <li>
                <Link to="/repository">MyDeals</Link>
            </li>
            <li>
                <Link to="/quickcalcs">Calcs</Link>
            </li>
            <li>
                <Link to="/ibankr">iBankr</Link>
            </li>
            {/* <li>
                <Link to="/quad">
                    <i className="fas fa-user-friends" />{" "}
                    <span className="hide-sm">Quad</span>
                </Link>
            </li> */}
            {/* <li>
                <Link to="/events">Flock</Link>
            </li>
            <li>
                <Link to="/jobs">Jobs</Link>
            </li> */}
            {/* <li>
                <Link to="/profiles">Squawkers</Link>
            </li> */}
            <li>
                <Link to="/dashboard">
                    {/* <i className="fas fa-user" />{" "} */}
                    <span className="hide-sm">Bio</span>
                </Link>
            </li>
            <li>
                <a onClick={logout} href="#!">
                    <i className="fas fa-sign-out-alt" />{" "}
                    <span className="hide-sm">Logout</span>
                </a>
            </li>
        </ul>
    );

    const guestLinks = (
        <ul>
            {/* <li>
                <Link to="/profiles">Quals</Link>
            </li> */}
            {/* <li>
                <Link to="/about">About</Link>
            </li> */}
            <li>
                <Link to="/preview">Preview</Link>
            </li>
            <li>
                <Link to="/register">Register</Link>
            </li>
            <li>
                <Link to="/login">Login</Link>
            </li>
        </ul>
    );

    return (
        <nav className="navbar bg-dark">
            <h1>
                {isAuthenticated ? (
                    <Link to="/privatecredit">Squawkify</Link>
                ) : (
                    <Link to="/">Squawkify</Link>
                )}
            </h1>
            {!loading && (
                <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
            )}
        </nav>
    );
};

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
