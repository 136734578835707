import {
    GET_COPRCOLLSQUAWKS,
    GET_COPRCOLLSQUAWKS_REPO,
    GET_COPRCOLLSQUAWK,
    COPRCOLLSQUAWK_ERROR,
    UPDATE_COPRCOLLLIKES,
    DELETE_COPRCOLLSQUAWK,
    UPDATE_COPRCOLLSQUAWK,
    ADD_COPRCOLLSQUAWK,
    ADD_COPRCOLLSQUAWK_COMMENT,
    REMOVE_COPRCOLLSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprcollsquawks: [],
    coprcollsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRCOLLSQUAWKS:
        case GET_COPRCOLLSQUAWKS_REPO:
            return {
                ...state,
                coprcollsquawks: payload,
                loading: false,
            };
        case GET_COPRCOLLSQUAWK:
            return {
                ...state,
                coprcollsquawk: payload,
                loading: false,
            };
        case ADD_COPRCOLLSQUAWK:
            return {
                ...state,
                coprcollsquawks: [payload, ...state.coprcollsquawks],
                loading: false,
            };
        case UPDATE_COPRCOLLSQUAWK:
            return {
                ...state,
                coprcollsquawks: state.coprcollsquawks.map((coprcollsquawk) =>
                    coprcollsquawk._id === payload.id
                        ? [...payload, ...state.coprcollsquawks]
                        : coprcollsquawk
                ),
                loading: false,
            };
        case DELETE_COPRCOLLSQUAWK:
            return {
                ...state,
                coprcollsquawks: state.coprcollsquawks.filter(
                    (coprcollsquawk) => coprcollsquawk._id !== payload
                ),
                loading: false,
            };
        case COPRCOLLSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRCOLLLIKES:
            return {
                ...state,
                coprcollsquawks: state.coprcollsquawks.map((coprcollsquawk) =>
                    coprcollsquawk._id === payload.id
                        ? { ...coprcollsquawk, likes: payload.likes }
                        : coprcollsquawk
                ),
                loading: false,
            };
        case ADD_COPRCOLLSQUAWK_COMMENT:
            return {
                ...state,
                coprcollsquawk: { ...state.coprcollsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRCOLLSQUAWK_COMMENT:
            return {
                ...state,
                coprcollsquawk: {
                    ...state.coprcollsquawk,
                    comments: state.coprcollsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
