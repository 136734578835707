import React, { useState } from "react";

const RecurringRevenueDCF = () => {
    // Software RR harvest scenario
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        loanAmount: "",
        ltmRevenue: "",
        churn: "",
        costOfRevenuePercent: "",
        fixedExpensePercentOfRevenue: "",
        gaPercentOfRevenue: "",
        taxes: "",
        discountFactor: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        renewalRate: "",
        yrOneRevenue: "",
        yrTwoRevenue: "",
        yrThreeRevenue: "",
        yrFourRevenue: "",
        yrFiveRevenue: "",
        yrSixRevenue: "",
        baseYrCostOfRevenue: "",
        yrOneCostOfRevenue: "",
        yrTwoCostOfRevenue: "",
        yrThreeCostOfRevenue: "",
        yrFourCostOfRevenue: "",
        yrFiveCostOfRevenue: "",
        yrSixCostOfRevenue: "",
        // baseYrGrossMargin: "",
        // yrOneGrossMargin: "",
        // yrTwoGrossMargin: "",
        // yrThreeGrossMargin: "",
        // yrFourGrossMargin: "",
        // yrFiveGrossMargin: "",
        // yrSixGrossMargin: "",
        // baseYrOpIncome: "",
        // yrOneOpIncome: "",
        // yrTwoOpIncome: "",
        // yrThreeOpIncome: "",
        // yrFourOpIncome: "",
        // yrFiveOpIncome: "",
        // yrSixOpIncome: "",
        baseYrNetIncome: "",
        yrOneNetIncome: "",
        yrTwoNetIncome: "",
        yrThreeNetIncome: "",
        yrFourNetIncome: "",
        yrFiveNetIncome: "",
        yrSixNetIncome: "",
        baseYrMidpointOfCashFlow: "",
        yrOneMidpointOfCashFlow: "",
        yrTwoMidpointOfCashFlow: "",
        yrThreeMidpointOfCashFlow: "",
        yrFourMidpointOfCashFlow: "",
        yrFiveMidpointOfCashFlow: "",
        yrSixMidpointOfCashFlow: "",
        baseYrDiscountFactor: "",
        yrOneDiscountFactor: "",
        yrTwoDiscountFactor: "",
        yrThreeDiscountFactor: "",
        yrFourDiscountFactor: "",
        yrFiveDiscountFactor: "",
        yrSixDiscountFactor: "",
        netValueOfRecurringRevenue: "",
        // netValueOfRecurringRevenuePlus: "",
        // netValueOfRecurringRevenueMinus: "",
        leverageMultiple: "",
        // leverageMultiplePlus: "",
        // leverageMultipleMinus: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            loanAmount,
            ltmRevenue,
            churn,
            costOfRevenuePercent,
            fixedExpensePercentOfRevenue,
            gaPercentOfRevenue,
            taxes,
            discountFactor,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !loanAmount ||
            !ltmRevenue ||
            !churn ||
            !costOfRevenuePercent ||
            !fixedExpensePercentOfRevenue ||
            !gaPercentOfRevenue ||
            !taxes ||
            !discountFactor
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(loanAmount) ||
            isNaN(ltmRevenue) ||
            isNaN(churn) ||
            isNaN(costOfRevenuePercent) ||
            isNaN(fixedExpensePercentOfRevenue) ||
            isNaN(gaPercentOfRevenue) ||
            isNaN(taxes) ||
            isNaN(discountFactor)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(loanAmount) <= 0 ||
            Number(ltmRevenue) <= 0 ||
            Number(churn) <= 0 ||
            Number(costOfRevenuePercent) <= 0 ||
            Number(fixedExpensePercentOfRevenue) <= 0 ||
            Number(gaPercentOfRevenue) <= 0 ||
            Number(taxes) <= 0 ||
            Number(discountFactor) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        loanAmount,
        ltmRevenue,
        churn,
        costOfRevenuePercent,
        fixedExpensePercentOfRevenue,
        gaPercentOfRevenue,
        taxes,
        discountFactor,
    }) => {
        const renewalRate = 1 - Number(churn) / 100;
        // Revenue projections
        const yrOneRevenue = Number(ltmRevenue) * renewalRate;
        const yrTwoRevenue = Number(yrOneRevenue) * renewalRate;
        const yrThreeRevenue = Number(yrTwoRevenue) * renewalRate;
        const yrFourRevenue = Number(yrThreeRevenue) * renewalRate;
        const yrFiveRevenue = Number(yrFourRevenue) * renewalRate;
        const yrSixRevenue = Number(yrFiveRevenue) * renewalRate;
        // Cost of revenue projections
        const costOfRevs = Number(costOfRevenuePercent) / 100;
        const baseYrCostOfRevenue = Number(ltmRevenue) * costOfRevs;
        const yrOneCostOfRevenue = Number(yrOneRevenue) * costOfRevs;
        const yrTwoCostOfRevenue = Number(yrTwoRevenue) * costOfRevs;
        const yrThreeCostOfRevenue = Number(yrThreeRevenue) * costOfRevs;
        const yrFourCostOfRevenue = Number(yrFourRevenue) * costOfRevs;
        const yrFiveCostOfRevenue = Number(yrFiveRevenue) * costOfRevs;
        const yrSixCostOfRevenue = Number(yrSixRevenue) * costOfRevs;
        // Net Income
        const FE = fixedExpensePercentOfRevenue / 100;
        const GA = gaPercentOfRevenue / 100;
        const tax = 1 - taxes / 100;
        const baseYrNetIncome =
            tax *
            (Number(ltmRevenue) -
                Number(baseYrCostOfRevenue) -
                Number(ltmRevenue) * FE -
                Number(ltmRevenue) * GA);
        const yrOneNetIncome =
            tax *
            (Number(yrOneRevenue) -
                Number(yrOneCostOfRevenue) -
                Number(yrOneRevenue) * FE -
                Number(yrOneRevenue) * GA);
        const yrTwoNetIncome =
            tax *
            (Number(yrTwoRevenue) -
                Number(yrTwoCostOfRevenue) -
                Number(yrTwoRevenue) * FE -
                Number(yrTwoRevenue) * GA);
        const yrThreeNetIncome =
            tax *
            (Number(yrThreeRevenue) -
                Number(yrThreeCostOfRevenue) -
                Number(yrThreeRevenue) * FE -
                Number(yrThreeRevenue) * GA);
        const yrFourNetIncome =
            tax *
            (Number(yrFourRevenue) -
                Number(yrFourCostOfRevenue) -
                Number(yrFourRevenue) * FE -
                Number(yrFourRevenue) * GA);
        const yrFiveNetIncome =
            tax *
            (Number(yrFiveRevenue) -
                Number(yrFiveCostOfRevenue) -
                Number(yrFiveRevenue) * FE -
                Number(yrFiveRevenue) * GA);
        const yrSixNetIncome =
            tax *
            (Number(yrSixRevenue) -
                Number(yrSixCostOfRevenue) -
                Number(yrSixRevenue) * FE -
                Number(yrSixRevenue) * GA);
        // Mid-Point of Cash Flow
        const partialPeriod = 1;
        const baseYrMidpointOfCashFlow = partialPeriod / 2;
        const yrOneMidpointOfCashFlow =
            baseYrMidpointOfCashFlow + partialPeriod;
        const yrTwoMidpointOfCashFlow = yrOneMidpointOfCashFlow + partialPeriod;
        const yrThreeMidpointOfCashFlow =
            yrTwoMidpointOfCashFlow + partialPeriod;
        const yrFourMidpointOfCashFlow =
            yrThreeMidpointOfCashFlow + partialPeriod;
        const yrFiveMidpointOfCashFlow =
            yrFourMidpointOfCashFlow + partialPeriod;
        const yrSixMidpointOfCashFlow =
            yrFiveMidpointOfCashFlow + partialPeriod;
        // Discount Factors
        const discount = 1 + discountFactor / 100;
        const baseYrDiscountFactor =
            1 / Math.pow(discount, baseYrMidpointOfCashFlow);
        const yrOneDiscountFactor =
            1 / Math.pow(discount, yrOneMidpointOfCashFlow);
        const yrTwoDiscountFactor =
            1 / Math.pow(discount, yrTwoMidpointOfCashFlow);
        const yrThreeDiscountFactor =
            1 / Math.pow(discount, yrThreeMidpointOfCashFlow);
        const yrFourDiscountFactor =
            1 / Math.pow(discount, yrFourMidpointOfCashFlow);
        const yrFiveDiscountFactor =
            1 / Math.pow(discount, yrFiveMidpointOfCashFlow);
        const yrSixDiscountFactor =
            1 / Math.pow(discount, yrSixMidpointOfCashFlow);
        // Calcs DCF value && debt / DCF value
        const netValueOfRecurringRevenue =
            baseYrNetIncome * baseYrDiscountFactor +
            yrOneNetIncome * yrOneDiscountFactor +
            yrTwoNetIncome * yrTwoDiscountFactor +
            yrThreeNetIncome * yrThreeDiscountFactor +
            yrFourNetIncome * yrFourDiscountFactor +
            yrFiveNetIncome * yrFiveDiscountFactor +
            yrSixNetIncome * yrSixDiscountFactor;
        const leverageMultiple = loanAmount / netValueOfRecurringRevenue;

        if (isFinite(netValueOfRecurringRevenue)) {
            const computedNetValueOfRecurringRevenue = netValueOfRecurringRevenue.toFixed(
                2
            );
            const computedLeverageMultiple = leverageMultiple.toFixed(2);
            const computedRenewalRate = (renewalRate * 100).toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                netValueOfRecurringRevenue: computedNetValueOfRecurringRevenue,
                leverageMultiple: computedLeverageMultiple,
                renewalRate: computedRenewalRate,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            loanAmount: "",
            ltmRevenue: "",
            churn: "",
            costOfRevenuePercent: "",
            fixedExpensePercentOfRevenue: "",
            gaPercentOfRevenue: "",
            taxes: "",
            discountFactor: "",
        });

        setResults({
            renewalRate: "",
            yrOneRevenue: "",
            yrTwoRevenue: "",
            yrThreeRevenue: "",
            yrFourRevenue: "",
            yrFiveRevenue: "",
            yrSixRevenue: "",
            baseYrCostOfRevenue: "",
            yrOneCostOfRevenue: "",
            yrTwoCostOfRevenue: "",
            yrThreeCostOfRevenue: "",
            yrFourCostOfRevenue: "",
            yrFiveCostOfRevenue: "",
            yrSixCostOfRevenue: "",
            // baseYrGrossMargin: "",
            // yrOneGrossMargin: "",
            // yrTwoGrossMargin: "",
            // yrThreeGrossMargin: "",
            // yrFourGrossMargin: "",
            // yrFiveGrossMargin: "",
            // yrSixGrossMargin: "",
            // baseYrOpIncome: "",
            // yrOneOpIncome: "",
            // yrTwoOpIncome: "",
            // yrThreeOpIncome: "",
            // yrFourOpIncome: "",
            // yrFiveOpIncome: "",
            // yrSixOpIncome: "",
            baseYrNetIncome: "",
            yrOneNetIncome: "",
            yrTwoNetIncome: "",
            yrThreeNetIncome: "",
            yrFourNetIncome: "",
            yrFiveNetIncome: "",
            yrSixNetIncome: "",
            baseYrMidpointOfCashFlow: "",
            yrOneMidpointOfCashFlow: "",
            yrTwoMidpointOfCashFlow: "",
            yrThreeMidpointOfCashFlow: "",
            yrFourMidpointOfCashFlow: "",
            yrFiveMidpointOfCashFlow: "",
            yrSixMidpointOfCashFlow: "",
            baseYrDiscountFactor: "",
            yrOneDiscountFactor: "",
            yrTwoDiscountFactor: "",
            yrThreeDiscountFactor: "",
            yrFourDiscountFactor: "",
            yrFiveDiscountFactor: "",
            yrSixDiscountFactor: "",
            netValueOfRecurringRevenue: "",
            // netValueOfRecurringRevenuePlus: "",
            // netValueOfRecurringRevenueMinus: "",
            leverageMultiple: "",
            // leverageMultiplePlus: "",
            // leverageMultipleMinus: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Software Lending Recurring Revenue Analysis</strong>
                <br />
                <small>(5yr DCF -- RR harvest scenario)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Loan Amount:</small>
                                <input
                                    type="text"
                                    name="loanAmount"
                                    placeholder=""
                                    value={userValues.loanAmount}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Base Year RR:</small>
                                <input
                                    type="text"
                                    name="ltmRevenue"
                                    placeholder=""
                                    value={userValues.ltmRevenue}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <strong>Model Assumptions</strong>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Churn %:</small>
                                <input
                                    type="text"
                                    name="churn"
                                    placeholder=""
                                    value={userValues.churn}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Cost of Revenue (% of revenue):</small>
                                <input
                                    type="text"
                                    name="costOfRevenuePercent"
                                    placeholder=""
                                    value={userValues.costOfRevenuePercent}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Fixed Expenses (% of revenue):</small>
                                <input
                                    type="text"
                                    name="fixedExpensePercentOfRevenue"
                                    placeholder=""
                                    value={
                                        userValues.fixedExpensePercentOfRevenue
                                    }
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*G & A (% of revenue):</small>
                                <input
                                    type="text"
                                    name="gaPercentOfRevenue"
                                    placeholder=""
                                    value={userValues.gaPercentOfRevenue}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Taxes %:</small>
                                <input
                                    type="text"
                                    name="taxes"
                                    placeholder=""
                                    value={userValues.taxes}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*CF Discount Rate %:</small>
                                <input
                                    type="text"
                                    name="discountFactor"
                                    placeholder=""
                                    value={userValues.discountFactor}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            Implied Renewal Rate: &nbsp;
                            {results.renewalRate}%<br />
                            Debt / RR DCF (x): &nbsp;
                            {results.leverageMultiple}x
                        </h6>
                        <p />
                        <div>
                            <label id="label">RR DCF Value:</label>
                            <input
                                type="text"
                                value={results.netValueOfRecurringRevenue
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default RecurringRevenueDCF;
