import {
    GET_COPRBKSQUAWKS,
    GET_COPRBKSQUAWKS_REPO,
    GET_COPRBKSQUAWK,
    COPRBKSQUAWK_ERROR,
    UPDATE_COPRBKLIKES,
    DELETE_COPRBKSQUAWK,
    UPDATE_COPRBKSQUAWK,
    ADD_COPRBKSQUAWK,
    ADD_COPRBKSQUAWK_COMMENT,
    REMOVE_COPRBKSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprbksquawks: [],
    coprbksquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRBKSQUAWKS:
        case GET_COPRBKSQUAWKS_REPO:
            return {
                ...state,
                coprbksquawks: payload,
                loading: false,
            };
        case GET_COPRBKSQUAWK:
            return {
                ...state,
                coprbksquawk: payload,
                loading: false,
            };
        case ADD_COPRBKSQUAWK:
            return {
                ...state,
                coprbksquawks: [payload, ...state.coprbksquawks],
                loading: false,
            };
        case UPDATE_COPRBKSQUAWK:
            return {
                ...state,
                coprbksquawks: state.coprbksquawks.map((coprbksquawk) =>
                    coprbksquawk._id === payload.id
                        ? [...payload, ...state.coprbksquawks]
                        : coprbksquawk
                ),
                loading: false,
            };
        case DELETE_COPRBKSQUAWK:
            return {
                ...state,
                coprbksquawks: state.coprbksquawks.filter(
                    (coprbksquawk) => coprbksquawk._id !== payload
                ),
                loading: false,
            };
        case COPRBKSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRBKLIKES:
            return {
                ...state,
                coprbksquawks: state.coprbksquawks.map((coprbksquawk) =>
                    coprbksquawk._id === payload.id
                        ? { ...coprbksquawk, likes: payload.likes }
                        : coprbksquawk
                ),
                loading: false,
            };
        case ADD_COPRBKSQUAWK_COMMENT:
            return {
                ...state,
                coprbksquawk: { ...state.coprbksquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRBKSQUAWK_COMMENT:
            return {
                ...state,
                coprbksquawk: {
                    ...state.coprbksquawk,
                    comments: state.coprbksquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
