import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRPESquawkItem from "./DetailedCOPRPESquawkItem";
import COPRPESquawkCommentForm from "./COPRPESquawkCommentForm";
import COPRPESquawkCommentItem from "./COPRPESquawkCommentItem";
import { getCOPRPESquawk } from "../../../actions/coprpesquawk";

const COPRPESquawk = ({
    getCOPRPESquawk,
    coprpesquawk: { coprpesquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRPESquawk(id);
    }, [getCOPRPESquawk, id]);

    return loading || coprpesquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/privateequity" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRPESquawkItem coprpesquawk={coprpesquawk} />
            <p />
            <hr />
            <br />
            <COPRPESquawkCommentForm coprpesquawkId={coprpesquawk._id} />
            <br />
            <div className="comments">
                {coprpesquawk.comments.map((comment) => (
                    <COPRPESquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprpesquawkId={coprpesquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRPESquawk.propTypes = {
    getCOPRPESquawk: PropTypes.func.isRequired,
    coprpesquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprpesquawk: state.coprpesquawk,
});

export default connect(mapStateToProps, { getCOPRPESquawk })(COPRPESquawk);
