import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
// import formatDate from "../../../utils/formatDate";
import {
    addLike,
    removeLike,
    deleteCOPRVCSquawk,
    getCOPRVCSquawk,
} from "../../../actions/coprvcsquawk";

// user,
// username,

const COPRVCSquawkItem = ({
    addLike,
    removeLike,
    deleteCOPRVCSquawk,
    getCOPRVCSquawk,
    auth,
    coprvcsquawk: {
        _id,
        user,
        coprvcsquawkDate,
        coprvcsquawkProduct,
        coprvcsquawkCompany,
        coprvcsquawkCompanyUrl,
        // coprvcsquawkPRUrl,
        coprvcsquawkSector,
        coprvcsquawkSubSector,
        coprvcsquawkSize,
        coprvcsquawkRound,
        coprvcsquawkLeadInvestor,
        // coprvcsquawkMarket,
        // coprvcsquawkTicker,
        // coprvcsquawkExchange,
        // coprvcsquawkStatus,
        likes,
        comments,
        date,
    },
}) => (
    <div>
        <Row>
            <Col xs={12}>
                <Card className="mb-2 mr-2 cardanimation">
                    <CardBody className="text-center">
                        <CardTitle>
                            {/* {coprvcsquawkPRUrl === "" ? ( */}
                            <strong>{coprvcsquawkCompany}</strong>
                            {/* ) : (
                            <a href={coprvcsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                                <strong>{coprvcsquawkCompany}</strong>
                            </a>
                        )} */}
                        </CardTitle>
                        <CardSubtitle>
                            <strong>
                                $
                                {coprvcsquawkSize
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                        </CardSubtitle>
                        <br />
                        <CardSubtitle>
                            <small>{coprvcsquawkRound}</small>
                        </CardSubtitle>
                        <p />
                        <CardSubtitle>
                            <small>{coprvcsquawkProduct}</small>
                        </CardSubtitle>
                        <br />
                        <CardSubtitle>
                            <small>{coprvcsquawkSector}</small>
                        </CardSubtitle>
                        <CardSubtitle>
                            <small>{coprvcsquawkSubSector}</small>
                        </CardSubtitle>
                        <p />
                        <CardText>
                            <small>{}</small>
                        </CardText>
                        <CardText>
                            <strong>{coprvcsquawkLeadInvestor}</strong>
                        </CardText>
                        <CardText>
                            <small>
                                {new Date(coprvcsquawkDate).toLocaleDateString(
                                    "en-US",
                                    {
                                        month: "long",
                                        year: "numeric",
                                    }
                                )}
                            </small>
                        </CardText>
                    </CardBody>
                    {/* <p className="my-1 text-center">
                    <small>SRP:&nbsp;{coprsquawkPricing}bps</small>
                </p> */}
                    <p className="my-1 text-center">
                        <small>
                            {/* <a href={coprvcsquawkCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                            {coprvcsquawkCompanyUrl}
                        </small>
                    </p>
                    <p />
                    {/* <p className="post-date text-center">
                    <small>Squawk Date: {formatDate(date)}</small>
                </p> */}
                    <div className="text-center mb-2">
                        <button
                            onClick={(e) => addLike(_id)}
                            type="button"
                            className="btn btn-secondary"
                        >
                            <i>Lift</i>{" "}
                            <span>
                                {likes.length > 0 && (
                                    <span>{likes.length}</span>
                                )}
                            </span>
                        </button>
                        <button
                            onClick={(e) => removeLike(_id)}
                            type="button"
                            className="btn btn-secondary"
                        >
                            <i>Hit</i>
                        </button>
                    </div>
                    <Link
                        to={`/venturecapital/${_id}`}
                        className="btn btn-primary btn-block"
                    >
                        Dealwire{" "}
                        {comments.length > 0 && (
                            <span className="comment-count">
                                {comments.length}
                            </span>
                        )}
                    </Link>
                </Card>
                <div className="text-center inline-block">
                    {!auth.loading && user === auth.user._id && (
                        <div className="crudButton">
                            <button
                                onClick={(e) => deleteCOPRVCSquawk(_id)}
                                type="button"
                                title="Delete"
                                className="btn btn-danger btn-block"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    )}
                    &nbsp;&nbsp;
                    {!auth.loading && user === auth.user._id && (
                        <Link
                            to={`/venturecapital/edit/${_id}`}
                            className="crudButton"
                        >
                            <button
                                onClick={(e) => getCOPRVCSquawk(_id)}
                                type="button"
                                title="Edit"
                                className="btn btn-light btn-block"
                            >
                                <i>Edit</i>
                            </button>
                        </Link>
                    )}
                    <p />
                </div>
            </Col>
        </Row>
    </div>
);

COPRVCSquawkItem.propTypes = {
    coprvcsquawk: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
    deleteCOPRVCSquawk: PropTypes.func.isRequired,
    getCOPRVCSquawk: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    addLike,
    removeLike,
    deleteCOPRVCSquawk,
    getCOPRVCSquawk,
})(COPRVCSquawkItem);
