import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRCollSquawkItem = ({
    coprcollsquawk: {
        _id,
        user,
        username,
        coprcollsquawkDate,
        coprcollsquawkSector,
        coprcollsquawkSubSector,
        coprcollsquawkCompany,
        coprcollsquawkCompanyUrl,
        // coprcollsquawkPRUrl,
        coprcollsquawkType,
        coprcollsquawkAssetType,
        coprcollsquawkDetails,
        coprcollsquawkValuation,
        coprcollsquawkRecoveryPercent,
        coprcollsquawkNotes,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprcollsquawkPRUrl === "" ? ( */}
                    <strong>{coprcollsquawkCompany}</strong>
                    {/* ) : (
                        <a href={coprcollsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <strong>Company: {coprcollsquawkCompany}</strong>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprcollsquawkCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprcollsquawkCompanyUrl}
                    </small>
                </Col>
                <Col>
                    <small>
                        {new Date(coprcollsquawkDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Sector: {coprcollsquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprcollsquawkSubSector}</small>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <strong>Asset / Collateral Summary</strong>
            <Row>
                <Col>
                    <small>Transaction: {coprcollsquawkType}</small>
                </Col>
                <Col>
                    <small>Asset: {coprcollsquawkAssetType}</small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Valuation: $
                        {coprcollsquawkValuation
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Recovery (%): {coprcollsquawkRecoveryPercent}</small>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <small>Asset Overview: {coprcollsquawkDetails}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Notes: {coprcollsquawkNotes}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRCollSquawkItem.propTypes = {
    coprcollsquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRCollSquawkItem;
