export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
// General squawks
export const GET_SQUAWKS = "GET_SQUAWKS";
export const GET_SQUAWK = "GET_SQUAWK";
export const SQUAWK_ERROR = "SQUAWK_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_SQUAWK = "DELETE_SQUAWK";
export const ADD_SQUAWK = "ADD_SQUAWK";
export const ADD_SQUAWK_COMMENT = "ADD_SQUAWK_COMMENT";
export const REMOVE_SQUAWK_COMMENT = "REMOVE_SQUAWK_COMMENT";
// Restructuring squawks
export const GET_BKSQUAWKS = "GET_BKSQUAWKS";
export const GET_BKSQUAWK = "GET_BKSQUAWK";
export const BKSQUAWK_ERROR = "BKSQUAWK_ERROR";
export const UPDATE_BKLIKES = "UPDATE_BKLIKES";
export const DELETE_BKSQUAWK = "DELETE_BKSQUAWK";
export const ADD_BKSQUAWK = "ADD_BKSQUAWK";
export const ADD_BKSQUAWK_COMMENT = "ADD_BKSQUAWK_COMMENT";
export const REMOVE_BKSQUAWK_COMMENT = "REMOVE_BKSQUAWK_COMMENT";
// Collateral | Fixed Asset squawks
export const GET_COLLSQUAWKS = "GET_COLLSQUAWKS";
export const GET_COLLSQUAWK = "GET_COLLSQUAWK";
export const COLLSQUAWK_ERROR = "COLLSQUAWK_ERROR";
export const UPDATE_COLLLIKES = "UPDATE_COLLLIKES";
export const DELETE_COLLSQUAWK = "DELETE_COLLSQUAWK";
export const ADD_COLLSQUAWK = "ADD_COLLSQUAWK";
export const ADD_COLLSQUAWK_COMMENT = "ADD_COLLSQUAWK_COMMENT";
export const REMOVE_COLLSQUAWK_COMMENT = "REMOVE_COLLSQUAWK_COMMENT";
// Credit squawks
export const GET_CREDITSQUAWKS = "GET_CREDITSQUAWKS";
export const GET_CREDITSQUAWK = "GET_CREDITSQUAWK";
export const CREDITSQUAWK_ERROR = "CREDITSQUAWK_ERROR";
export const UPDATE_CREDITLIKES = "UPDATE_CREDITLIKES";
export const DELETE_CREDITSQUAWK = "DELETE_CREDITSQUAWK";
export const ADD_CREDITSQUAWK = "ADD_CREDITSQUAWK";
export const ADD_CREDITSQUAWK_COMMENT = "ADD_CREDITSQUAWK_COMMENT";
export const REMOVE_CREDITSQUAWK_COMMENT = "REMOVE_CREDITSQUAWK_COMMENT";
// Fund squawks
export const GET_FUNDSQUAWKS = "GET_FUNDSQUAWKS";
export const GET_FUNDSQUAWK = "GET_FUNDSQUAWK";
export const FUNDSQUAWK_ERROR = "FUNDSQUAWK_ERROR";
export const UPDATE_FUNDLIKES = "UPDATE_FUNDLIKES";
export const DELETE_FUNDSQUAWK = "DELETE_FUNDSQUAWK";
export const ADD_FUNDSQUAWK = "ADD_FUNDSQUAWK";
export const ADD_FUNDSQUAWK_COMMENT = "ADD_FUNDSQUAWK_COMMENT";
export const REMOVE_FUNDSQUAWK_COMMENT = "REMOVE_FUNDSQUAWK_COMMENT";
// Job squawks
export const GET_JOBSQUAWKS = "GET_JOBSQUAWKS";
export const GET_JOBSQUAWK = "GET_JOBSQUAWK";
export const JOBSQUAWK_ERROR = "JOBSQUAWK_ERROR";
export const UPDATE_JOBLIKES = "UPDATE_JOBLIKES";
export const DELETE_JOBSQUAWK = "DELETE_JOBSQUAWK";
export const ADD_JOBSQUAWK = "ADD_JOBSQUAWK";
export const ADD_JOBSQUAWK_COMMENT = "ADD_JOBSQUAWK_COMMENT";
export const REMOVE_JOBSQUAWK_COMMENT = "REMOVE_JOBSQUAWK_COMMENT";
// Legal Documentation squawks
export const GET_LEGALSQUAWKS = "GET_LEGALSQUAWKS";
export const GET_LEGALSQUAWK = "GET_LEGALSQUAWK";
export const LEGALSQUAWK_ERROR = "LEGALSQUAWK_ERROR";
export const UPDATE_LEGALLIKES = "UPDATE_LEGALLIKES";
export const DELETE_LEGALSQUAWK = "DELETE_LEGALSQUAWK";
export const ADD_LEGALSQUAWK = "ADD_LEGALSQUAWK";
export const ADD_LEGALSQUAWK_COMMENT = "ADD_LEGALSQUAWK_COMMENT";
export const REMOVE_LEGALSQUAWK_COMMENT = "REMOVE_LEGALSQUAWK_COMMENT";
// M&A squawks
export const GET_MNASQUAWKS = "GET_MNASQUAWKS";
export const GET_MNASQUAWK = "GET_MNASQUAWK";
export const MNASQUAWK_ERROR = "MNASQUAWK_ERROR";
export const UPDATE_MNALIKES = "UPDATE_MNALIKES";
export const DELETE_MNASQUAWK = "DELETE_MNASQUAWK";
export const ADD_MNASQUAWK = "ADD_MNASQUAWK";
export const ADD_MNASQUAWK_COMMENT = "ADD_MNASQUAWK_COMMENT";
export const REMOVE_MNASQUAWK_COMMENT = "REMOVE_MNASQUAWK_COMMENT";
// Meet n Greet squawks
export const GET_MNGSQUAWKS = "GET_MNGSQUAWKS";
export const GET_MNGSQUAWK = "GET_MNGSQUAWK";
export const MNGSQUAWK_ERROR = "MNGSQUAWK_ERROR";
export const UPDATE_MNGLIKES = "UPDATE_MNGLIKES";
export const DELETE_MNGSQUAWK = "DELETE_MNGSQUAWK";
export const ADD_MNGSQUAWK = "ADD_MNGSQUAWK";
export const ADD_MNGSQUAWK_COMMENT = "ADD_MNGSQUAWK_COMMENT";
export const REMOVE_MNGSQUAWK_COMMENT = "REMOVE_MNGSQUAWK_COMMENT";
// Private Equity squawks
export const GET_PESQUAWKS = "GET_PESQUAWKS";
export const GET_PESQUAWK = "GET_PESQUAWK";
export const PESQUAWK_ERROR = "PESQUAWK_ERROR";
export const UPDATE_PELIKES = "UPDATE_PELIKES";
export const DELETE_PESQUAWK = "DELETE_PESQUAWK";
export const ADD_PESQUAWK = "ADD_PESQUAWK";
export const ADD_PESQUAWK_COMMENT = "ADD_PESQUAWK_COMMENT";
export const REMOVE_PESQUAWK_COMMENT = "REMOVE_PESQUAWK_COMMENT";
// Venture Capital squawks
export const GET_VCSQUAWKS = "GET_VCSQUAWKS";
export const GET_VCSQUAWK = "GET_VCSQUAWK";
export const VCSQUAWK_ERROR = "VCSQUAWK_ERROR";
export const UPDATE_VCLIKES = "UPDATE_VCLIKES";
export const DELETE_VCSQUAWK = "DELETE_VCSQUAWK";
export const ADD_VCSQUAWK = "ADD_VCSQUAWK";
export const ADD_VCSQUAWK_COMMENT = "ADD_VCSQUAWK_COMMENT";
export const REMOVE_VCSQUAWK_COMMENT = "REMOVE_VCSQUAWK_COMMENT";
// COPRS squawks
export const GET_COPRSQUAWKS = "GET_COPRSQUAWKS";
export const GET_COPRSQUAWKS_REPO = "GET_COPRSQUAWKS_REPO";
export const GET_COPRSQUAWK = "GET_COPRSQUAWK";
export const COPRSQUAWK_ERROR = "COPRSQUAWK_ERROR";
export const UPDATE_COPRLIKES = "UPDATE_COPRLIKES";
export const DELETE_COPRSQUAWK = "DELETE_COPRSQUAWK";
export const UPDATE_COPRSQUAWK = "UPDATE_COPRSQUAWK";
export const EDIT_COPRSQUAWK = "EDIT_COPRSQUAWK";
export const CLEAR_COPRSQUAWK = "CLEAR_COPRSQUAWK";
export const ADD_COPRSQUAWK = "ADD_COPRSQUAWK";
export const ADD_COPRSQUAWK_COMMENT = "ADD_COPRSQUAWK_COMMENT";
export const REMOVE_COPRSQUAWK_COMMENT = "REMOVE_COPRSQUAWK_COMMENT";
// COPRS bksquawks
export const GET_COPRBKSQUAWKS = "GET_COPRBKSQUAWKS";
export const GET_COPRBKSQUAWKS_REPO = "GET_COPRBKSQUAWKS_REPO";
export const GET_COPRBKSQUAWK = "GET_COPRBKSQUAWK";
export const COPRBKSQUAWK_ERROR = "COPRBKSQUAWK_ERROR";
export const UPDATE_COPRBKLIKES = "UPDATE_COPRBKLIKES";
export const DELETE_COPRBKSQUAWK = "DELETE_COPRBKSQUAWK";
export const UPDATE_COPRBKSQUAWK = "UPDATE_COPRBKSQUAWK";
export const ADD_COPRBKSQUAWK = "ADD_COPRBKSQUAWK";
export const ADD_COPRBKSQUAWK_COMMENT = "ADD_COPRBKSQUAWK_COMMENT";
export const REMOVE_COPRBKSQUAWK_COMMENT = "REMOVE_COPRBKSQUAWK_COMMENT";
// COPRS pesquawks
export const GET_COPRPESQUAWKS = "GET_COPRPESQUAWKS";
export const GET_COPRPESQUAWKS_REPO = "GET_COPRPESQUAWKS_REPO";
export const GET_COPRPESQUAWK = "GET_COPRPESQUAWK";
export const COPRPESQUAWK_ERROR = "COPRPESQUAWK_ERROR";
export const UPDATE_COPRPELIKES = "UPDATE_COPRPELIKES";
export const DELETE_COPRPESQUAWK = "DELETE_COPRPESQUAWK";
export const UPDATE_COPRPESQUAWK = "UPDATE_COPRPESQUAWK";
export const ADD_COPRPESQUAWK = "ADD_COPRPESQUAWK";
export const ADD_COPRPESQUAWK_COMMENT = "ADD_COPRPESQUAWK_COMMENT";
export const REMOVE_COPRPESQUAWK_COMMENT = "REMOVE_COPRPESQUAWK_COMMENT";
// COPRS vcsquawks
export const GET_COPRVCSQUAWKS = "GET_COPRVCSQUAWKS";
export const GET_COPRVCSQUAWKS_REPO = "GET_COPRVCSQUAWKS_REPO";
export const GET_COPRVCSQUAWK = "GET_COPRVCSQUAWK";
export const COPRVCSQUAWK_ERROR = "COPRVCSQUAWK_ERROR";
export const UPDATE_COPRVCLIKES = "UPDATE_COPRVCLIKES";
export const DELETE_COPRVCSQUAWK = "DELETE_COPRVCSQUAWK";
export const UPDATE_COPRVCSQUAWK = "UPDATE_COPRVCSQUAWK";
export const ADD_COPRVCSQUAWK = "ADD_COPRVCSQUAWK";
export const ADD_COPRVCSQUAWK_COMMENT = "ADD_COPRVCSQUAWK_COMMENT";
export const REMOVE_COPRVCSQUAWK_COMMENT = "REMOVE_COPRVCSQUAWK_COMMENT";
// COPRS mnasquawks
export const GET_COPRMNASQUAWKS = "GET_COPRMNASQUAWKS";
export const GET_COPRMNASQUAWKS_REPO = "GET_COPRMNASQUAWKS_REPO";
export const GET_COPRMNASQUAWK = "GET_COPRMNASQUAWK";
export const COPRMNASQUAWK_ERROR = "COPRMNASQUAWK_ERROR";
export const UPDATE_COPRMNALIKES = "UPDATE_COPRMNALIKES";
export const DELETE_COPRMNASQUAWK = "DELETE_COPRMNASQUAWK";
export const UPDATE_COPRMNASQUAWK = "UPDATE_COPRMNASQUAWK";
export const ADD_COPRMNASQUAWK = "ADD_COPRMNASQUAWK";
export const ADD_COPRMNASQUAWK_COMMENT = "ADD_COPRMNASQUAWK_COMMENT";
export const REMOVE_COPRMNASQUAWK_COMMENT = "REMOVE_COPRMNASQUAWK_COMMENT";
// COPRS collsquawks
export const GET_COPRCOLLSQUAWKS = "GET_COPRCOLLSQUAWKS";
export const GET_COPRCOLLSQUAWKS_REPO = "GET_COPRCOLLSQUAWKS_REPO";
export const GET_COPRCOLLSQUAWK = "GET_COPRCOLLSQUAWK";
export const COPRCOLLSQUAWK_ERROR = "COPRCOLLSQUAWK_ERROR";
export const UPDATE_COPRCOLLLIKES = "UPDATE_COPRCOLLLIKES";
export const DELETE_COPRCOLLSQUAWK = "DELETE_COPRCOLLSQUAWK";
export const UPDATE_COPRCOLLSQUAWK = "UPDATE_COPRCOLLSQUAWK";
export const ADD_COPRCOLLSQUAWK = "ADD_COPRCOLLSQUAWK";
export const ADD_COPRCOLLSQUAWK_COMMENT = "ADD_COPRCOLLSQUAWK_COMMENT";
export const REMOVE_COPRCOLLSQUAWK_COMMENT = "REMOVE_COPRCOLLSQUAWK_COMMENT";
// COPRS fundsquawks
export const GET_COPRFUNDSQUAWKS = "GET_COPRFUNDSQUAWKS";
export const GET_COPRFUNDSQUAWKS_REPO = "GET_COPRFUNDSQUAWKS_REPO";
export const GET_COPRFUNDSQUAWK = "GET_COPRFUNDSQUAWK";
export const COPRFUNDSQUAWK_ERROR = "COPRFUNDSQUAWK_ERROR";
export const UPDATE_COPRFUNDLIKES = "UPDATE_COPRFUNDLIKES";
export const DELETE_COPRFUNDSQUAWK = "DELETE_COPRFUNDSQUAWK";
export const UPDATE_COPRFUNDSQUAWK = "UPDATE_COPRFUNDSQUAWK";
export const ADD_COPRFUNDSQUAWK = "ADD_COPRFUNDSQUAWK";
export const ADD_COPRFUNDSQUAWK_COMMENT = "ADD_COPRFUNDSQUAWK_COMMENT";
export const REMOVE_COPRFUNDSQUAWK_COMMENT = "REMOVE_COPRFUNDSQUAWK_COMMENT";
