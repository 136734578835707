import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRSquawkItem from "./COPRSquawkItem";
import SearchBox from "./COPRSearchBox";
import { getCOPRSquawks } from "../../../actions/coprsquawk";
import DealWallTurret from "../DealWallTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRSquawks = ({
    getCOPRSquawks,
    coprsquawk: { coprsquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRSquawks(keyword);
    }, [getCOPRSquawks, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealWallTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Select private credit
                deals:
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link to="/add-coprsquawk" className="btn btn-primary mb-2">
                        <i className="text-primary"></i> Add Deal
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            <p />
            {coprsquawks.length === 0 && (
                <div>
                    <strong>
                        No matching results...please revise your search!
                    </strong>
                </div>
            )}
            <Row md={4}>
                {coprsquawks.map((coprsquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRSquawkItem
                            key={coprsquawk._id}
                            coprsquawk={coprsquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRSquawks.propTypes = {
    getCOPRSquawks: PropTypes.func.isRequired,
    coprsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprsquawk: state.coprsquawk,
});

export default connect(mapStateToProps, { getCOPRSquawks })(COPRSquawks);
