import React from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Landing = ({ isAuthenticated }) => {
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <section className="landingblur">
            <div className="dark-overlay">
                <div className="landing-inner">
                    <h1 className="x-large">Squawkify</h1>
                    <br />
                    <h6 className="lead italic">
                        data analytics - tools - social
                    </h6>
                    <p />
                    <p className="lead">
                        Private market deal aggregator (PE, VC, IB, Private
                        Credit, etc)
                    </p>
                    <br />
                    <p />
                    <p className="lead">
                        <strong>
                            Be smarter. Level up your market signals.
                        </strong>
                    </p>
                    <p />
                    <div className="buttons">
                        <Link to="/register" className="btn btn-primary">
                            Sign Up
                        </Link>
                        <Link to="/login" className="btn btn-light">
                            Login
                        </Link>
                    </div>
                    <p />
                    <br />
                    <div>
                        <Link to="/preview" style={{ color: "#FFF" }}>
                            <h4 className="lead italic">Learn More</h4>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

Landing.propTypes = {
    isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
