import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ABL from "./credit/ABL";
import RR from "./credit/RR";

const IBankr = () => {
    return (
        <section className="container">
            <div className="allButFooter">
                <h3 className="text-center">
                    Welcome to <strong>Private Credit iBankr</strong>!
                </h3>
                <p />
                <br />
                <Tabs defaultActiveKey="abl" className="mb-3">
                    <Tab eventKey="abl" title="ABL (Asset-Based)">
                        <h6 className="text-primary text-center">
                            Asset-Based Lender
                        </h6>
                        <p />
                        <br />
                        <ABL />
                    </Tab>
                    <Tab eventKey="saas" title="SaaS / Recurring Revenue">
                        <h6 className="text-primary text-center">
                            Recurring Revenue / Cash Flow Lender
                        </h6>
                        <p />
                        <br />
                        <RR />
                    </Tab>
                </Tabs>
                <p />
                <br />
                <small className="italic">
                    <strong>Disclaimer:</strong> the information contained
                    herein is not intended to be a source of advice or credit
                    analysis with respect to the material presented, and the
                    information contained in this website does not constitute
                    investment advice.
                </small>
            </div>
        </section>
    );
};

export default IBankr;
