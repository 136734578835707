import React, { useState } from "react";

const CashFlowWaterfall = () => {
    // Simplified cash flow waterfall analysis
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        operatingCashFlow: "",
        capex: "",
        plusOther: "",
        minusOther: "",
        snrDebtRepayment: "",
        snrInterest: "",
        revolverDrawdown: "",
        revolverRepayment: "",
        defaultRepayment: "",
        cashFlowSweep: "",
        subDebtRepayment: "",
    });
    // state to storage the results of the calculation
    const [results, setResults] = useState({
        cfBeforeFinancing: "",
        cfAfterSnrDebtSvc: "",
        cfAfterRevolver: "",
        cfAfterRepayOfDefault: "",
        cfAfterSweep: "",
        cfAfterSnrDebt: "",
        cfToEquity: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            operatingCashFlow,
            capex,
            plusOther,
            minusOther,
            snrDebtRepayment,
            snrInterest,
            revolverDrawdown,
            revolverRepayment,
            defaultRepayment,
            cashFlowSweep,
            subDebtRepayment,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !operatingCashFlow ||
            !capex ||
            !plusOther ||
            !minusOther ||
            !snrDebtRepayment ||
            !snrInterest ||
            !revolverDrawdown ||
            !revolverRepayment ||
            !defaultRepayment ||
            !cashFlowSweep ||
            !subDebtRepayment
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(operatingCashFlow) ||
            isNaN(capex) ||
            isNaN(plusOther) ||
            isNaN(minusOther) ||
            isNaN(snrDebtRepayment) ||
            isNaN(snrInterest) ||
            isNaN(revolverDrawdown) ||
            isNaN(revolverRepayment) ||
            isNaN(defaultRepayment) ||
            isNaN(cashFlowSweep) ||
            isNaN(subDebtRepayment)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers - allowing 0 inputs
        if (
            Number(operatingCashFlow) <= 0 ||
            Number(capex) < 0 ||
            Number(plusOther) < 0 ||
            Number(minusOther) < 0 ||
            Number(snrDebtRepayment) < 0 ||
            Number(snrInterest) < 0 ||
            Number(revolverDrawdown) < 0 ||
            Number(revolverRepayment) < 0 ||
            Number(defaultRepayment) < 0 ||
            Number(cashFlowSweep) < 0 ||
            Number(subDebtRepayment) < 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        operatingCashFlow,
        capex,
        plusOther,
        minusOther,
        snrDebtRepayment,
        snrInterest,
        revolverDrawdown,
        revolverRepayment,
        defaultRepayment,
        cashFlowSweep,
        subDebtRepayment,
    }) => {
        // Cash flow before financing
        const cfBeforeFinancing =
            Number(operatingCashFlow) -
            Number(capex) +
            Number(plusOther) -
            Number(minusOther);
        // Cash flow after senior debt service
        const cfAfterSnrDebtSvc =
            cfBeforeFinancing - Number(snrDebtRepayment) - Number(snrInterest);
        // Cash flow after revolver
        const negCFAfterSnrDebtSvc = Math.max(-cfAfterSnrDebtSvc, 0);
        const posCFAfterSnrDebtSvc = Math.max(cfAfterSnrDebtSvc, 0);
        const plusRevolverDraws = Math.min(
            negCFAfterSnrDebtSvc,
            Number(revolverDrawdown)
        );
        const minusRepaymentsOfRevolver = Math.min(
            posCFAfterSnrDebtSvc,
            Number(revolverRepayment)
        );
        const cfAfterRevolver =
            cfAfterSnrDebtSvc + plusRevolverDraws - minusRepaymentsOfRevolver;
        // Cash flow after repay of default
        const maxCFAfterRevolver = Math.max(cfAfterRevolver, 0);
        const minusRepaymentOfDefault = Math.min(
            maxCFAfterRevolver,
            Number(defaultRepayment)
        );
        const cfAfterRepayOfDefault = cfAfterRevolver - minusRepaymentOfDefault;
        // Cash flow after sweep
        const maxCFAfterRepayOfDefault = Math.max(cfAfterRepayOfDefault, 0);
        const minusCFSweeps = Math.min(maxCFAfterRepayOfDefault, cashFlowSweep);
        const cfAfterSweep = cfAfterRepayOfDefault - minusCFSweeps;
        // Cash flow after senior debt
        const plusDefaultOnSnrDebt = Math.max(-cfAfterSweep, 0);
        const cfAfterSnrDebt = cfAfterSweep + plusDefaultOnSnrDebt;
        // Cash flow to equity
        const maxCFAfterSnrDebt = Math.max(cfAfterSnrDebt, 0);
        const minusRepaymentsOfSubDebt = Math.min(
            maxCFAfterSnrDebt,
            subDebtRepayment
        );
        const cfToEquity = cfAfterSnrDebt - minusRepaymentsOfSubDebt;

        if (isFinite(cfBeforeFinancing)) {
            const computedCFBeforeFinancing = cfBeforeFinancing.toFixed(2);
            const computedCFAfterSnrDebtSvc = cfAfterSnrDebtSvc.toFixed(2);
            const computedCFAfterRevolver = cfAfterRevolver.toFixed(2);
            const computedCFAfterRepayOfDefault = cfAfterRepayOfDefault.toFixed(
                2
            );
            const computedCFAfterSweep = cfAfterSweep.toFixed(2);
            const computedCFAfterSnrDebt = cfAfterSnrDebt.toFixed(2);
            const computedCFToEquity = cfToEquity.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                cfBeforeFinancing: computedCFBeforeFinancing,
                cfAfterSnrDebtSvc: computedCFAfterSnrDebtSvc,
                cfAfterRevolver: computedCFAfterRevolver,
                cfAfterRepayOfDefault: computedCFAfterRepayOfDefault,
                cfAfterSweep: computedCFAfterSweep,
                cfAfterSnrDebt: computedCFAfterSnrDebt,
                cfToEquity: computedCFToEquity,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            operatingCashFlow: "",
            capex: "",
            plusOther: "",
            minusOther: "",
            snrDebtRepayment: "",
            snrInterest: "",
            revolverDrawdown: "",
            revolverRepayment: "",
            defaultRepayment: "",
            cashFlowSweep: "",
            subDebtRepayment: "",
        });

        setResults({
            cfBeforeFinancing: "",
            cfAfterSnrDebtSvc: "",
            cfAfterRevolver: "",
            cfAfterRepayOfDefault: "",
            cfAfterSweep: "",
            cfAfterSnrDebt: "",
            cfToEquity: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Simplified Cash Flow Waterfall Analysis</strong>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <strong>Step 1 </strong>
                        <small>
                            {" "}
                            -- Cash Flow before Financing (use 'Other' for
                            relevant line items, or enter '0' if not
                            applicable):
                        </small>

                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Operating Cash Flow:</small>
                                <input
                                    type="text"
                                    name="operatingCashFlow"
                                    placeholder=""
                                    value={userValues.operatingCashFlow}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*minus: Capex</small>
                                <input
                                    type="text"
                                    name="capex"
                                    placeholder=""
                                    value={userValues.capex}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*plus: Other (e.g. proceeds)</small>
                                <input
                                    type="text"
                                    name="plusOther"
                                    placeholder=""
                                    value={userValues.plusOther}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*minus: Other (e.g. fees)</small>
                                <input
                                    type="text"
                                    name="minusOther"
                                    placeholder=""
                                    value={userValues.minusOther}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <p />
                        <strong>Steps 2 through 7 </strong>
                        <small>
                            -- provide the inputs below, or enter '0' if not
                            applicable:
                        </small>

                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Senior Debt Repayment:</small>
                                <input
                                    type="text"
                                    name="snrDebtRepayment"
                                    placeholder=""
                                    value={userValues.snrDebtRepayment}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Senior Interest:</small>
                                <input
                                    type="text"
                                    name="snrInterest"
                                    placeholder=""
                                    value={userValues.snrInterest}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Revolver Drawdown:</small>
                                <input
                                    type="text"
                                    name="revolverDrawdown"
                                    placeholder=""
                                    value={userValues.revolverDrawdown}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Revolver Repayment:</small>
                                <input
                                    type="text"
                                    name="revolverRepayment"
                                    placeholder=""
                                    value={userValues.revolverRepayment}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Default Repayment:</small>
                                <input
                                    type="text"
                                    name="defaultRepayment"
                                    placeholder=""
                                    value={userValues.defaultRepayment}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Cash Flow Sweep:</small>
                                <input
                                    type="text"
                                    name="cashFlowSweep"
                                    placeholder=""
                                    value={userValues.cashFlowSweep}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Sub Debt Repayment:</small>
                                <input
                                    type="text"
                                    name="subDebtRepayment"
                                    placeholder=""
                                    value={userValues.subDebtRepayment}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            Operating Cash Flow: &nbsp; $
                            {userValues.operatingCashFlow
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            ** Estimates below may vary slightly due to
                            rounding.
                            {/* Free Cash Flow: &nbsp;
                            {results.leverageMultiple}x */}
                        </h6>
                        <p />
                        <div>
                            <label id="label">
                                Cash Flow Before Financing:
                            </label>
                            <input
                                type="text"
                                value={results.cfBeforeFinancing
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">
                                Cash Flow After Senior Debt Service:
                            </label>
                            <input
                                type="text"
                                value={results.cfAfterSnrDebtSvc
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Cash Flow After Revolver:</label>
                            <input
                                type="text"
                                value={results.cfAfterRevolver
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">
                                Cash Flow After Repay of Default:
                            </label>
                            <input
                                type="text"
                                value={results.cfAfterRepayOfDefault
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Cash Flow After Sweep:</label>
                            <input
                                type="text"
                                value={results.cfAfterSweep
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">
                                Cash Flow After Senior Debt:
                            </label>
                            <input
                                type="text"
                                value={results.cfAfterSnrDebt
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Cash Flow to Equity:</label>
                            <input
                                type="text"
                                value={results.cfToEquity
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default CashFlowWaterfall;
