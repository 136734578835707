import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRVCSquawkItem = ({
    coprvcsquawk: {
        _id,
        user,
        username,
        coprvcsquawkDate,
        coprvcsquawkProduct,
        coprvcsquawkCompany,
        coprvcsquawkCompanyUrl,
        // coprvcsquawkPRUrl,
        coprvcsquawkSector,
        coprvcsquawkSubSector,
        coprvcsquawkSize,
        coprvcsquawkStructure,
        coprvcsquawkPricing,
        coprvcsquawkRound,
        coprvcsquawkInvestor,
        coprvcsquawkCounsel,
        coprvcsquawk3PDD,
        coprvcsquawkPreMoney,
        coprvcsquawkPostMoney,
        coprvcsquawkDilution,
        // coprvcsquawkStatus,
        // coprvcsquawkMarket,
        // coprvcsquawkTicker,
        // coprvcsquawkExchange,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprvcsquawkPRUrl === "" ? ( */}
                    <strong>{coprvcsquawkCompany}</strong>
                    {/* ) : (
                        <a href={coprvcsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <small>Company: {coprvcsquawkCompany}</small>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprvcsquawkCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprvcsquawkCompanyUrl}
                    </small>
                </Col>
                <Col>
                    <small>{coprvcsquawkProduct}</small>
                </Col>
                <Col>
                    <small>
                        Date:{" "}
                        {new Date(coprvcsquawkDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Amount: $
                        {coprvcsquawkSize
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Round: {coprvcsquawkRound}</small>
                </Col>
                <Col>
                    <small>Sector: {coprvcsquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprvcsquawkSubSector}</small>
                </Col>
            </Row>
            <br />
            <strong>Transaction</strong>
            <Row>
                <Col>
                    <small>
                        Pre-Money Valuation ($):{" "}
                        {coprvcsquawkPreMoney
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>
                        Post-Money Valuation ($):{" "}
                        {coprvcsquawkPostMoney
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Structure: {coprvcsquawkStructure}</small>
                </Col>
                <Col>
                    <small>Dilution: {coprvcsquawkDilution}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>{coprvcsquawkPricing}</small>
                </Col>
            </Row>
            <br />
            <strong>Participants</strong>
            <Row>
                <Col>
                    <small>Investor(s): {coprvcsquawkInvestor}</small>
                </Col>
                <Col>
                    <small>Legal Counsel: {coprvcsquawkCounsel}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>3PDD: {coprvcsquawk3PDD}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRVCSquawkItem.propTypes = {
    coprvcsquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRVCSquawkItem;
