import {
    GET_CREDITSQUAWKS,
    GET_CREDITSQUAWK,
    CREDITSQUAWK_ERROR,
    UPDATE_CREDITLIKES,
    DELETE_CREDITSQUAWK,
    ADD_CREDITSQUAWK,
    ADD_CREDITSQUAWK_COMMENT,
    REMOVE_CREDITSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    creditsquawks: [],
    creditsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CREDITSQUAWKS:
            return {
                ...state,
                creditsquawks: payload,
                loading: false,
            };
        case GET_CREDITSQUAWK:
            return {
                ...state,
                creditsquawk: payload,
                loading: false,
            };
        case ADD_CREDITSQUAWK:
            return {
                ...state,
                creditsquawks: [payload, ...state.creditsquawks],
                loading: false,
            };
        case DELETE_CREDITSQUAWK:
            return {
                ...state,
                creditsquawks: state.creditsquawks.filter(
                    (creditsquawk) => creditsquawk._id !== payload
                ),
                loading: false,
            };
        case CREDITSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_CREDITLIKES:
            return {
                ...state,
                creditsquawks: state.creditsquawks.map((creditsquawk) =>
                    creditsquawk._id === payload.id
                        ? { ...creditsquawk, likes: payload.likes }
                        : creditsquawk
                ),
                loading: false,
            };
        case ADD_CREDITSQUAWK_COMMENT:
            return {
                ...state,
                creditsquawk: { ...state.creditsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_CREDITSQUAWK_COMMENT:
            return {
                ...state,
                creditsquawk: {
                    ...state.creditsquawk,
                    comments: state.creditsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
