import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
import { getCOPRSquawks } from "../../actions/coprsquawk";
import { getCOPRPESquawks } from "../../actions/coprpesquawk";
import { getCOPRVCSquawks } from "../../actions/coprvcsquawk";

const SquawkActivity = ({
    coprsquawk: { coprsquawks },
    coprpesquawk: { coprpesquawks },
    coprvcsquawk: { coprvcsquawks },
    getCOPRSquawks,
    getCOPRPESquawks,
    getCOPRVCSquawks,
}) => {
    useEffect(() => {
        getCOPRSquawks();
        getCOPRPESquawks();
        getCOPRVCSquawks();
    }, [getCOPRSquawks, getCOPRPESquawks, getCOPRVCSquawks]);

    // Credit Squawks
    const getCreditDealSizeCount = () => {
        return coprsquawks.reduce(
            (totalDeals, deal) =>
                totalDeals + Number(deal.coprsquawkDebtAmount),
            0
        );
    };

    // PE Squawks
    const getPEDealSizeCount = () => {
        return coprpesquawks.reduce(
            (totalDeals, deal) => totalDeals + Number(deal.coprpesquawkSize),
            0
        );
    };

    // VC Squawks
    const getVCDealSizeCount = () => {
        return coprvcsquawks.reduce(
            (totalDeals, deal) => totalDeals + Number(deal.coprvcsquawkSize),
            0
        );
    };

    return (
        <section className="container">
            <Row md={4}>
                <Col sm={12} md={4} lg={3} xl={3}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Most Active</strong>
                                <br />
                            </CardTitle>
                            <CardSubtitle>
                                <small className="italic">
                                    (by deal volume)
                                </small>
                            </CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={4} lg={3} xl={3}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Venture Capital</strong>
                            </CardTitle>
                            <CardSubtitle>
                                <small>
                                    $
                                    {Number(getVCDealSizeCount())
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </small>
                            </CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={4} lg={3} xl={3}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Private Credit</strong>
                            </CardTitle>
                            <CardSubtitle>
                                <small>
                                    $
                                    {Number(getCreditDealSizeCount())
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </small>
                            </CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm={12} md={4} lg={3} xl={3}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Private Equity</strong>
                            </CardTitle>
                            <CardSubtitle>
                                <small>
                                    $
                                    {Number(getPEDealSizeCount())
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </small>
                            </CardSubtitle>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </section>
    );
};

SquawkActivity.propTypes = {
    coprsquawk: PropTypes.object.isRequired,
    getCOPRSquawks: PropTypes.func.isRequired,
    coprpesquawk: PropTypes.object.isRequired,
    getCOPRPESquawks: PropTypes.func.isRequired,
    coprvcsquawk: PropTypes.object.isRequired,
    getCOPRVCSquawks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    coprsquawk: state.coprsquawk,
    coprpesquawk: state.coprpesquawk,
    coprvcsquawk: state.coprvcsquawk,
});

export default connect(mapStateToProps, {
    getCOPRSquawks,
    getCOPRPESquawks,
    getCOPRVCSquawks,
})(SquawkActivity);
