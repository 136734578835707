import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";

const PrivateRoute = ({
    component: Component,
    auth: { isAuthenticated, loading },
    // ...rest
}) => {
    if (loading) return <Spinner />;
    if (isAuthenticated) return <Component />;

    return <Navigate to="/login" />;
};

// MAJOR CHANGE: React Router v6 cleanup = no need to render props -- Replaced
// (
//     <Route
//         {...rest}
//         render={(props) =>
//             loading ? (
//                 <h1>Loading</h1>
//             ) : isAuthenticated ? (
//                 <Component {...props} />
//             ) : (
//                 <Redirect to="/login" />
//             )
//         }
//     />
// );

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
