import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
} from "reactstrap";
import PropTypes from "prop-types";
// import formatDate from "../../utils/formatDate";

const ProfileTombstone = ({
    tombstone: {
        client,
        amount,
        category,
        firm,
        role,
        // when,
        // contribution,
        // note,
    },
}) => (
    <div>
        <Row>
            <Col xs={12}>
                <Card className="mb-2 mr-2">
                    <CardBody className="text-center">
                        <CardTitle>
                            <strong>
                                <small>{client}</small>
                            </strong>
                        </CardTitle>
                        <CardSubtitle>
                            <small>{amount}</small>
                        </CardSubtitle>
                        <CardText>
                            <small>{category}</small>
                        </CardText>
                        <CardText className="hide-sm">
                            <small>{firm}</small>
                        </CardText>
                        <CardText className="hide-sm">
                            <small>Title: {role}</small>
                        </CardText>
                        {/* <CardText>
                    <small>Closed: {formatDate(when)}</small>
                </CardText> */}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>
);

/* <p className="hide-sm">
        <small>Cred: {contribution}</small>
    </p>
    <p className="hide-sm">
        <small>Notes: {note}</small>
    </p> */

ProfileTombstone.propTypes = {
    tombstone: PropTypes.object.isRequired,
};

export default ProfileTombstone;
