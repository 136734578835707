import {
    GET_COPRMNASQUAWKS,
    GET_COPRMNASQUAWKS_REPO,
    GET_COPRMNASQUAWK,
    COPRMNASQUAWK_ERROR,
    UPDATE_COPRMNALIKES,
    DELETE_COPRMNASQUAWK,
    UPDATE_COPRMNASQUAWK,
    ADD_COPRMNASQUAWK,
    ADD_COPRMNASQUAWK_COMMENT,
    REMOVE_COPRMNASQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprmnasquawks: [],
    coprmnasquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRMNASQUAWKS:
        case GET_COPRMNASQUAWKS_REPO:
            return {
                ...state,
                coprmnasquawks: payload,
                loading: false,
            };
        case GET_COPRMNASQUAWK:
            return {
                ...state,
                coprmnasquawk: payload,
                loading: false,
            };
        case ADD_COPRMNASQUAWK:
            return {
                ...state,
                coprmnasquawks: [payload, ...state.coprmnasquawks],
                loading: false,
            };
        case UPDATE_COPRMNASQUAWK:
            return {
                ...state,
                coprmnasquawks: state.coprmnasquawks.map((coprmnasquawk) =>
                    coprmnasquawk._id === payload.id
                        ? [...payload, ...state.coprmnasquawks]
                        : coprmnasquawk
                ),
                loading: false,
            };
        case DELETE_COPRMNASQUAWK:
            return {
                ...state,
                coprmnasquawks: state.coprmnasquawks.filter(
                    (coprmnasquawk) => coprmnasquawk._id !== payload
                ),
                loading: false,
            };
        case COPRMNASQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRMNALIKES:
            return {
                ...state,
                coprmnasquawks: state.coprmnasquawks.map((coprmnasquawk) =>
                    coprmnasquawk._id === payload.id
                        ? { ...coprmnasquawk, likes: payload.likes }
                        : coprmnasquawk
                ),
                loading: false,
            };
        case ADD_COPRMNASQUAWK_COMMENT:
            return {
                ...state,
                coprmnasquawk: { ...state.coprmnasquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRMNASQUAWK_COMMENT:
            return {
                ...state,
                coprmnasquawk: {
                    ...state.coprmnasquawk,
                    comments: state.coprmnasquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
