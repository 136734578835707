import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { addCOPRVCSquawk } from "../../../actions/coprvcsquawk";

const COPRVCSquawkForm = ({ addCOPRVCSquawk }) => {
    const navigate = useNavigate();
    const routePath = useLocation();
    const NavToPageTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        NavToPageTop();
    }, [routePath]);

    const [formData, setFormData] = useState({
        coprvcsquawkDate: "",
        coprvcsquawkProduct: "",
        coprvcsquawkCompany: "",
        coprvcsquawkCompanyUrl: "",
        coprvcsquawkPRUrl: "",
        coprvcsquawkSector: "",
        coprvcsquawkSubSector: "",
        coprvcsquawkSize: "",
        coprvcsquawkStructure: "",
        coprvcsquawkPricing: "",
        coprvcsquawkRound: "",
        coprvcsquawkLeadInvestor: "",
        coprvcsquawkInvestor: "",
        coprvcsquawkCounsel: "",
        coprvcsquawk3PDD: "",
        coprvcsquawkPreMoney: "",
        coprvcsquawkPostMoney: "",
        coprvcsquawkDilution: "",
        // coprvcsquawkStatus: "",
        // coprvcsquawkMarket: "",
        // coprvcsquawkTicker: "",
        // coprvcsquawkExchange: "",
    });

    const {
        coprvcsquawkDate,
        coprvcsquawkProduct,
        coprvcsquawkCompany,
        coprvcsquawkCompanyUrl,
        coprvcsquawkPRUrl,
        coprvcsquawkSector,
        coprvcsquawkSubSector,
        coprvcsquawkSize,
        coprvcsquawkStructure,
        coprvcsquawkPricing,
        coprvcsquawkRound,
        coprvcsquawkLeadInvestor,
        coprvcsquawkInvestor,
        coprvcsquawkCounsel,
        coprvcsquawk3PDD,
        coprvcsquawkPreMoney,
        coprvcsquawkPostMoney,
        coprvcsquawkDilution,
        // coprvcsquawkStatus,
        // coprvcsquawkMarket,
        // coprvcsquawkTicker,
        // coprvcsquawkExchange,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprvcsquawkSize) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprvcsquawkSize)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprvcsquawkSize) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Add Transaction</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        addCOPRVCSquawk(formData);
                        navigate(`/venturecapital`);
                        NavToPageTop();
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Transaction Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkDate"
                                value={coprvcsquawkDate}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprvcsquawkCompany"
                                value={coprvcsquawkCompany}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkCompanyUrl"
                                value={coprvcsquawkCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Deal Category</small>
                            <select
                                name="coprvcsquawkProduct"
                                value={coprvcsquawkProduct}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Investment">Investment</option>
                                <option value="Exit">Exit</option>
                                <option value="Failed">Failed</option>
                                <option value="Straw">Straw Man</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sector</small>
                            <select
                                name="coprvcsquawkSector"
                                value={coprvcsquawkSector}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Communication Services">
                                    Communication Services
                                </option>
                                <option value="Consumer Discretionary">
                                    Consumer Discretionary
                                </option>
                                <option value="Consumer Staples">
                                    Consumer Staples
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Financials">Financials</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Industrials">Industrials</option>
                                <option value="Materials">Materials</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Technology">Technology</option>
                                <option value="Utilities">Utilities</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sub-sector</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkSubSector"
                                value={coprvcsquawkSubSector}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Round Size ($)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkSize"
                                value={coprvcsquawkSize}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Lead Investor (or 1 co-lead)</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprvcsquawkLeadInvestor"
                                value={coprvcsquawkLeadInvestor}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Funding Round (ex. Seed)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkRound"
                                value={coprvcsquawkRound}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Additional Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Transaction</strong>
                </span>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprvcsquawkPRUrl"
                                value={coprvcsquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Structure (e.g. minority interest)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprvcsquawkStructure"
                                value={coprvcsquawkStructure}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Pre-Money Valuation ($)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprvcsquawkPreMoney"
                                value={coprvcsquawkPreMoney}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Post-Money Valuation ($)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprvcsquawkPostMoney"
                                value={coprvcsquawkPostMoney}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Dilution</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprvcsquawkDilution"
                                value={coprvcsquawkDilution}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Detail</small>
                    <textarea
                        name="coprvcsquawkPricing"
                        cols="30"
                        rows="5"
                        placeholder="Structure, pricing, etc...."
                        value={coprvcsquawkPricing}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <hr className="hide-sm" />
                <p />
                <span className="hide-sm">
                    <strong>Participants</strong>
                </span>
                <div className="form-group hide-sm">
                    <small>Investor(s) (or "NA")</small>
                    <textarea
                        name="coprvcsquawkInvestor"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprvcsquawkInvestor}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <div className="form-group hide-sm">
                    <small>Legal Counsel</small>
                    <textarea
                        name="coprvcsquawkCounsel"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprvcsquawkCounsel}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <div className="form-group hide-sm">
                    <small>3rd Party Diligence (or "NA")</small>
                    <textarea
                        name="coprvcsquawk3PDD"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprvcsquawk3PDD}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
                <Link className="btn btn-light my-1" to="/venturecapital">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

COPRVCSquawkForm.propTypes = {
    addCOPRVCSquawk: PropTypes.func.isRequired,
};

export default connect(null, { addCOPRVCSquawk })(COPRVCSquawkForm);
