import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRMNASquawkItem from "./DetailedCOPRMNASquawkItem";
import COPRMNASquawkCommentForm from "./COPRMNASquawkCommentForm";
import COPRMNASquawkCommentItem from "./COPRMNASquawkCommentItem";
import { getCOPRMNASquawk } from "../../../actions/coprmnasquawk";

const COPRMNASquawk = ({
    getCOPRMNASquawk,
    coprmnasquawk: { coprmnasquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRMNASquawk(id);
    }, [getCOPRMNASquawk, id]);

    return loading || coprmnasquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/mergers&acquisitions" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRMNASquawkItem coprmnasquawk={coprmnasquawk} />
            <p />
            <hr />
            <br />
            <COPRMNASquawkCommentForm coprmnasquawkId={coprmnasquawk._id} />
            <br />
            <div className="comments">
                {coprmnasquawk.comments.map((comment) => (
                    <COPRMNASquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprmnasquawkId={coprmnasquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRMNASquawk.propTypes = {
    getCOPRMNASquawk: PropTypes.func.isRequired,
    coprmnasquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprmnasquawk: state.coprmnasquawk,
});

export default connect(mapStateToProps, { getCOPRMNASquawk })(COPRMNASquawk);
