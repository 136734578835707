import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
} from "reactstrap";
import { connect } from "react-redux";
// import formatDate from "../../../utils/formatDate";
import {
    addLike,
    removeLike,
    deleteCOPRSquawk,
    getCOPRSquawk,
} from "../../../actions/coprsquawk";

// user,
// username,
// coprsquawkMarket,
// coprsquawkTicker,
// coprsquawkExchange,
// coprsquawkDebtStructure,

const COPRSquawkItem = ({
    addLike,
    removeLike,
    deleteCOPRSquawk,
    getCOPRSquawk,
    auth,
    coprsquawk: {
        _id,
        user,
        coprsquawkTransactionDate,
        coprsquawkBorrower,
        coprsquawkSector,
        coprsquawkSubSector,
        coprsquawkLender,
        coprsquawkSponsor,
        coprsquawkDebtAmount,
        coprsquawkDebtStructure,
        coprsquawkTransactionDesc,
        // coprsquawkPricing,
        coprsquawkBorrowerUrl,
        // coprsquawkPRUrl,
        likes,
        comments,
        date,
    },
}) => (
    <div>
        <Row>
            <Col xs={12}>
                <Card className="mb-2 mr-2 cardanimation">
                    <CardBody className="text-center">
                        <CardTitle>
                            {/* // Revisit external URL link security for all squawks */}
                            {/* {coprsquawkPRUrl === "" ? ( */}
                            <strong>{coprsquawkBorrower}</strong>
                            {/* ) : (
                            <a href={coprsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                                <strong>{coprsquawkBorrower}</strong>
                            </a>
                        )} */}
                        </CardTitle>
                        <CardSubtitle>
                            <strong>
                                $
                                {coprsquawkDebtAmount
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                        </CardSubtitle>
                        <p />
                        <CardSubtitle>
                            <small>{coprsquawkDebtStructure}</small>
                        </CardSubtitle>
                        <p />
                        <CardSubtitle>
                            <small>{coprsquawkTransactionDesc}</small>
                        </CardSubtitle>
                        <p />
                        <CardSubtitle>
                            <small>{coprsquawkSponsor}</small>
                        </CardSubtitle>
                        <br />
                        <CardSubtitle>
                            <small>{coprsquawkSector}</small>
                        </CardSubtitle>
                        <CardSubtitle>
                            <small>{coprsquawkSubSector}</small>
                        </CardSubtitle>
                        <p />
                        <CardText>
                            <strong>{coprsquawkLender}</strong>
                        </CardText>
                        <CardText>
                            <small>
                                {new Date(
                                    coprsquawkTransactionDate
                                ).toLocaleDateString("en-US", {
                                    month: "long",
                                    year: "numeric",
                                })}
                            </small>
                        </CardText>
                    </CardBody>
                    {/* <p className="my-1 text-center">
                    <small>SRP:&nbsp;{coprsquawkPricing}bps</small>
                </p> */}
                    <p className="my-1 text-center">
                        <small>
                            {/* <a href={coprsquawkBorrowerUrl} target="_blank" rel="noopener noreferrer"></a> */}
                            {coprsquawkBorrowerUrl}
                        </small>
                    </p>
                    <p />
                    {/* <p className="post-date text-center">
                    <small>Squawk Date: {formatDate(date)}</small>
                </p> */}
                    <div className="text-center mb-2">
                        <button
                            onClick={(e) => addLike(_id)}
                            type="button"
                            title="Lift :: Buy"
                            className="btn btn-secondary"
                        >
                            <i>Lift</i>{" "}
                            <span>
                                {likes.length > 0 && (
                                    <span>{likes.length}</span>
                                )}
                            </span>
                        </button>
                        <button
                            onClick={(e) => removeLike(_id)}
                            type="button"
                            title="Hit :: Sell"
                            className="btn btn-secondary"
                        >
                            <i>Hit</i>
                        </button>
                    </div>
                    <Link
                        to={`/privatecredit/${_id}`}
                        className="btn btn-primary btn-block"
                        title="Details & Squawks"
                    >
                        Dealwire{" "}
                        {comments.length > 0 && (
                            <span className="comment-count">
                                {comments.length}
                            </span>
                        )}
                    </Link>
                </Card>
                <div className="text-center inline-block">
                    {!auth.loading && user === auth.user._id && (
                        <div className="crudButton">
                            <button
                                onClick={(e) => deleteCOPRSquawk(_id)}
                                type="button"
                                title="Delete"
                                className="btn btn-danger btn-block"
                            >
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    )}
                    &nbsp;&nbsp;
                    {!auth.loading && user === auth.user._id && (
                        <Link
                            to={`/privatecredit/edit/${_id}`}
                            className="crudButton"
                        >
                            <button
                                onClick={(e) => getCOPRSquawk(_id)}
                                type="button"
                                title="Edit"
                                className="btn btn-light btn-block"
                            >
                                <i>Edit</i>
                            </button>
                        </Link>
                    )}
                    <p />
                </div>
            </Col>
        </Row>
    </div>
);

COPRSquawkItem.propTypes = {
    coprsquawk: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    addLike: PropTypes.func.isRequired,
    removeLike: PropTypes.func.isRequired,
    deleteCOPRSquawk: PropTypes.func.isRequired,
    getCOPRSquawk: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    addLike,
    removeLike,
    deleteCOPRSquawk,
    getCOPRSquawk,
})(COPRSquawkItem);
