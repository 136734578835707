import React, { useState } from "react";

const WarrantValuation = () => {
    // warrant valuation
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        s: "",
        t: "",
        v: "",
        r: "",
        x: "",
        warrants: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        warrantValuation: "",
        impliedStrikePremium: "",
        totalWarrantValuation: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const { s, t, v, r, x, warrants } = userValues;
        let actualError = "";
        // Validate if there are values
        if (!s || !t || !v || !r || !x || !warrants) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(s) ||
            isNaN(t) ||
            isNaN(v) ||
            isNaN(r) ||
            isNaN(x) ||
            isNaN(warrants)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(s) <= 0 ||
            Number(t) <= 0 ||
            Number(v) <= 0 ||
            Number(r) <= 0 ||
            Number(x) <= 0 ||
            Number(warrants) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({ s, t, v, r, x, warrants }) => {
        // Apply Black-Scholes Call valuation
        const V = Number(v) / 100;
        const R = Number(r) / 100;
        const d1 =
            (Math.log(Number(s) / Number(x)) + (R + (V * V) / 2) * Number(t)) /
            (V * Math.sqrt(Number(t)));
        const d2 = d1 - V * Math.sqrt(Number(t));
        const warrantValuation =
            Number(s) * CND(d1) -
            Number(x) * Math.exp(-R * Number(t)) * CND(d2);

        // Compute cumulative normal distribution
        function CND(x) {
            if (x < 0) {
                return 1 - CND(-x);
            } else {
                const k = 1 / (1 + 0.2316419 * x);
                return (
                    1 -
                    (Math.exp((-x * x) / 2) / Math.sqrt(2 * Math.PI)) *
                        k *
                        (0.31938153 +
                            k *
                                (-0.356563782 +
                                    k *
                                        (1.781477937 +
                                            k *
                                                (-1.821255978 +
                                                    k * 1.330274429))))
                );
            }
        }

        // Compute implied premium to issue price s
        const impliedStrikePremium = (Number(x) / Number(s) - 1) * 100;
        // Compute # of warrants * valuation
        const totalWarrantValuation = Number(warrants) * warrantValuation;

        if (isFinite(warrantValuation)) {
            const computedWarrantValuation = warrantValuation.toFixed(2);
            const computedImpliedStrikePremium = impliedStrikePremium.toFixed(
                2
            );
            const computedTotalWarrantValuation = totalWarrantValuation.toFixed(
                2
            );

            // Set up results to the state to be displayed to the user
            setResults({
                warrantValuation: computedWarrantValuation,
                impliedStrikePremium: computedImpliedStrikePremium,
                totalWarrantValuation: computedTotalWarrantValuation,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            s: "",
            t: "",
            v: "",
            r: "",
            x: "",
            warrants: "",
        });

        setResults({
            warrantValuation: "",
            impliedStrikePremium: "",
            totalWarrantValuation: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Warrant Valuation</strong>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*# of Warrants:</small>
                                <input
                                    type="text"
                                    name="warrants"
                                    placeholder=""
                                    value={userValues.warrants}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Issue Price:</small>
                                <input
                                    type="text"
                                    name="s"
                                    placeholder=""
                                    value={userValues.s}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Strike Price:</small>
                                <input
                                    type="text"
                                    name="x"
                                    placeholder=""
                                    value={userValues.x}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Years:</small>
                                <input
                                    type="text"
                                    name="t"
                                    placeholder=""
                                    value={userValues.t}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Rate %:</small>
                                <input
                                    type="text"
                                    name="r"
                                    placeholder=""
                                    value={userValues.r}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Implied Volatility %:</small>
                                <input
                                    type="text"
                                    name="v"
                                    placeholder=""
                                    value={userValues.v}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            Implied Strike Premium: &nbsp;
                            {results.impliedStrikePremium}%
                            <br /># of Warrants: &nbsp;
                            {userValues.warrants
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;&nbsp;Total Warrant Valuation: &nbsp; $
                            {results.totalWarrantValuation
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </h6>
                        <p />
                        <div>
                            <label id="label">Warrant Value:</label>
                            <input
                                type="text"
                                value={results.warrantValuation
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default WarrantValuation;
