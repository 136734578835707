import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { addCOPRCollSquawk } from "../../../actions/coprcollsquawk";

const COPRCollSquawkForm = ({ addCOPRCollSquawk }) => {
    const navigate = useNavigate();
    const routePath = useLocation();
    const NavToPageTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        NavToPageTop();
    }, [routePath]);

    const [formData, setFormData] = useState({
        coprcollsquawkDate: "",
        coprcollsquawkSector: "",
        coprcollsquawkSubSector: "",
        coprcollsquawkCompany: "",
        coprcollsquawkCompanyUrl: "",
        coprcollsquawkPRUrl: "",
        coprcollsquawkType: "",
        coprcollsquawkAssetType: "",
        coprcollsquawkDetails: "",
        coprcollsquawkValuation: "",
        coprcollsquawkRecoveryPercent: "",
        coprcollsquawkNotes: "",
    });

    const {
        coprcollsquawkDate,
        coprcollsquawkSector,
        coprcollsquawkSubSector,
        coprcollsquawkCompany,
        coprcollsquawkCompanyUrl,
        coprcollsquawkPRUrl,
        coprcollsquawkType,
        coprcollsquawkAssetType,
        coprcollsquawkDetails,
        coprcollsquawkValuation,
        coprcollsquawkRecoveryPercent,
        coprcollsquawkNotes,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprcollsquawkValuation) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprcollsquawkValuation)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprcollsquawkValuation) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Add Transaction</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        addCOPRCollSquawk(formData);
                        navigate(`/collateral`);
                        NavToPageTop();
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Deal Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                placeholder=""
                                name="coprcollsquawkDate"
                                value={coprcollsquawkDate}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprcollsquawkCompany"
                                value={coprcollsquawkCompany}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprcollsquawkCompanyUrl"
                                value={coprcollsquawkCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sector</small>
                            <select
                                name="coprcollsquawkSector"
                                value={coprcollsquawkSector}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Communication Services">
                                    Communication Services
                                </option>
                                <option value="Consumer Discretionary">
                                    Consumer Discretionary
                                </option>
                                <option value="Consumer Staples">
                                    Consumer Staples
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Financials">Financials</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Industrials">Industrials</option>
                                <option value="Materials">Materials</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Technology">Technology</option>
                                <option value="Utilities">Utilities</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sub-sector</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprcollsquawkSubSector"
                                value={coprcollsquawkSubSector}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Collateral Transaction</small>
                            <select
                                name="coprcollsquawkType"
                                value={coprcollsquawkType}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Auction">Auction</option>
                                <option value="Private Sale">
                                    Private Sale
                                </option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                            <small>
                                * Asset Category (e.g. C&I, Retail, etc)
                            </small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprcollsquawkAssetType"
                                value={coprcollsquawkAssetType}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                            <small>* Asset Valuation $</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprcollsquawkValuation"
                                value={coprcollsquawkValuation}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprcollsquawkPRUrl"
                                value={coprcollsquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Additional Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Collateral Summary</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Asset Recovery %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                style={{ width: "30em" }}
                                name="coprcollsquawkRecoveryPercent"
                                value={coprcollsquawkRecoveryPercent}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Asset Description (e.g. rental equipment)</small>
                    <textarea
                        name="coprcollsquawkDetails"
                        cols="30"
                        rows="5"
                        placeholder="* Collateral details...."
                        value={coprcollsquawkDetails}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <div className="form-group hide-sm">
                    <small>Notes</small>
                    <textarea
                        name="coprcollsquawkNotes"
                        cols="30"
                        rows="5"
                        placeholder="* Collateral details...."
                        value={coprcollsquawkNotes}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
                <Link className="btn btn-light my-1" to="/collateral">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

COPRCollSquawkForm.propTypes = {
    addCOPRCollSquawk: PropTypes.func.isRequired,
};

export default connect(null, { addCOPRCollSquawk })(COPRCollSquawkForm);
