import React from "react";

const NotFound = () => {
    return (
        <section className="container">
            <div className="allButFooter">
                <h1 className="x-large text-primary">
                    <i className="fas fa-exclamation-triangle">
                        {" "}
                        &nbsp;Page Not Found
                    </i>
                </h1>
                <p className="large">Sorry, this page does not exist.</p>
            </div>
        </section>
    );
};

export default NotFound;
