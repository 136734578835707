import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const DealRepoTurret = () => {
    return (
        <Fragment>
            <h3 className="text-primary">Deal Repo & Stats</h3>
            <p>
                <small>
                    <strong>View your own deals / axes here. </strong>First
                    principle: <strong>no MNPI</strong>.&nbsp;Inquiries:
                    i@squawkify.com.
                </small>
            </p>
            <br />
            <p>
                <small>
                    <strong>MyDeal Repo Channels:</strong>
                </small>
            </p>
            <div className="dash-buttons">
                <Link to="/repository" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Repo / Stats
                </Link>
                <Link to="/privatecredit/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Private Credit
                </Link>
                <Link to="/privateequity/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Private Equity
                </Link>
                <Link to="/venturecapital/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Venture Capital
                </Link>
                <Link
                    to="/mergers&acquisitions/repo"
                    className="btn btn-dark mb-2"
                >
                    <i className="text-primary"></i>M&A
                </Link>
                <Link to="/restructuring/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Restructuring
                </Link>
                <Link to="/collateral/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Collateral
                </Link>
                <Link to="/funds/repo" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Fund
                </Link>
            </div>
            <br />
            <br />
            {/* Channels */}
        </Fragment>
    );
};

export default DealRepoTurret;
