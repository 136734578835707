import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRCollSquawkItem from "./COPRCollSquawkItem";
import SearchBox from "./COPRCollRepoSearchBox";
import { getCOPRCollSquawksRepo } from "../../../actions/coprcollsquawk";
import DealRepoTurret from "../DealRepoTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRCollSquawksRepo = ({
    getCOPRCollSquawksRepo,
    coprcollsquawk: { coprcollsquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRCollSquawksRepo(keyword);
    }, [getCOPRCollSquawksRepo, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealRepoTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Your asset /
                collateral squawk count:{" "}
                <strong>
                    {coprcollsquawks.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </strong>
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link
                        to="/add-coprcollsquawk"
                        className="btn btn-primary mb-2"
                    >
                        <i className="text-primary"></i> Add Deal
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            {/* {coprcollsquawks.length !== 0
                ? ""
                : coprcollsquawks.length === 0 && (
                      <div>
                          <strong>
                              No matching results...please revise your search!
                          </strong>
                      </div>
                  )} */}
            <Row md={4}>
                {coprcollsquawks.map((coprcollsquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRCollSquawkItem
                            key={coprcollsquawk._id}
                            coprcollsquawk={coprcollsquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRCollSquawksRepo.propTypes = {
    getCOPRCollSquawksRepo: PropTypes.func.isRequired,
    coprcollsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprcollsquawk: state.coprcollsquawk,
});

export default connect(mapStateToProps, { getCOPRCollSquawksRepo })(
    COPRCollSquawksRepo
);
