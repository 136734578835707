import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRPESquawkItem = ({
    coprpesquawk: {
        _id,
        user,
        username,
        coprpesquawkDate,
        coprpesquawkProduct,
        coprpesquawkCompany,
        coprpesquawkCompanyUrl,
        // coprpesquawkPRUrl,
        coprpesquawkSector,
        coprpesquawkSubSector,
        coprpesquawkSize,
        coprpesquawkStructure,
        coprpesquawkLenderPricing,
        coprpesquawkLender,
        coprpesquawkSponsor,
        coprpesquawkCounsel,
        coprpesquawk3PDD,
        coprpesquawkEnterpriseValue,
        coprpesquawkEVToSales,
        coprpesquawkEVToEBIT,
        coprpesquawkEVToEBITDA,
        // coprpesquawkMarket,
        // coprpesquawkTicker,
        // coprpesquawkExchange,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprpesquawkPRUrl === "" ? ( */}
                    <strong>{coprpesquawkCompany}</strong>
                    {/* ) : (
                        <a href={coprpesquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <strong>Company: {coprpesquawkCompany}</strong>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprpesquawkCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprpesquawkCompanyUrl}
                    </small>
                </Col>
                <Col>
                    <small>{coprpesquawkProduct}</small>
                </Col>
                <Col>
                    <small>
                        Date:{" "}
                        {new Date(coprpesquawkDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Amount: $
                        {coprpesquawkSize
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Structure: {coprpesquawkStructure}</small>
                </Col>
                <Col>
                    <small>Sector: {coprpesquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprpesquawkSubSector}</small>
                </Col>
            </Row>
            <br />
            <strong>Transaction</strong>
            <Row>
                <Col>
                    <small>
                        EV:{" "}
                        {coprpesquawkEnterpriseValue
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>EV / LTM Revenue(x): {coprpesquawkEVToSales}</small>
                </Col>
                <Col>
                    <small>EV / LTM EBIT(x): {coprpesquawkEVToEBIT}</small>
                </Col>
                <Col>
                    <small>EV / LTM EBITDA(x): {coprpesquawkEVToEBITDA}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>{coprpesquawkLenderPricing}</small>
                </Col>
            </Row>
            <br />
            <strong>Participants</strong>
            <Row>
                <Col>
                    <small>Sponsor(s): {coprpesquawkSponsor}</small>
                </Col>
                <Col>
                    <small>Lender(s): {coprpesquawkLender}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Legal Counsel: {coprpesquawkCounsel}</small>
                </Col>
                <Col>
                    <small>3PDD: {coprpesquawk3PDD}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRPESquawkItem.propTypes = {
    coprpesquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRPESquawkItem;
