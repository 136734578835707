import {
    GET_COLLSQUAWKS,
    GET_COLLSQUAWK,
    COLLSQUAWK_ERROR,
    UPDATE_COLLLIKES,
    DELETE_COLLSQUAWK,
    ADD_COLLSQUAWK,
    ADD_COLLSQUAWK_COMMENT,
    REMOVE_COLLSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    collsquawks: [],
    collsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COLLSQUAWKS:
            return {
                ...state,
                collsquawks: payload,
                loading: false,
            };
        case GET_COLLSQUAWK:
            return {
                ...state,
                collsquawk: payload,
                loading: false,
            };
        case ADD_COLLSQUAWK:
            return {
                ...state,
                collsquawks: [payload, ...state.collsquawks],
                loading: false,
            };
        case DELETE_COLLSQUAWK:
            return {
                ...state,
                collsquawks: state.collsquawks.filter(
                    (collsquawk) => collsquawk._id !== payload
                ),
                loading: false,
            };
        case COLLSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COLLLIKES:
            return {
                ...state,
                collsquawks: state.collsquawks.map((collsquawk) =>
                    collsquawk._id === payload.id
                        ? { ...collsquawk, likes: payload.likes }
                        : collsquawk
                ),
                loading: false,
            };
        case ADD_COLLSQUAWK_COMMENT:
            return {
                ...state,
                collsquawk: { ...state.collsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COLLSQUAWK_COMMENT:
            return {
                ...state,
                collsquawk: {
                    ...state.collsquawk,
                    comments: state.collsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
