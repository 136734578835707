import {
    GET_COPRFUNDSQUAWKS,
    GET_COPRFUNDSQUAWKS_REPO,
    GET_COPRFUNDSQUAWK,
    COPRFUNDSQUAWK_ERROR,
    UPDATE_COPRFUNDLIKES,
    DELETE_COPRFUNDSQUAWK,
    UPDATE_COPRFUNDSQUAWK,
    ADD_COPRFUNDSQUAWK,
    ADD_COPRFUNDSQUAWK_COMMENT,
    REMOVE_COPRFUNDSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprfundsquawks: [],
    coprfundsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRFUNDSQUAWKS:
        case GET_COPRFUNDSQUAWKS_REPO:
            return {
                ...state,
                coprfundsquawks: payload,
                loading: false,
            };
        case GET_COPRFUNDSQUAWK:
            return {
                ...state,
                coprfundsquawk: payload,
                loading: false,
            };
        case ADD_COPRFUNDSQUAWK:
            return {
                ...state,
                coprfundsquawks: [payload, ...state.coprfundsquawks],
                loading: false,
            };
        case UPDATE_COPRFUNDSQUAWK:
            return {
                ...state,
                coprfundsquawks: state.coprfundsquawks.map((coprfundsquawk) =>
                    coprfundsquawk._id === payload.id
                        ? [...payload, ...state.coprfundsquawks]
                        : coprfundsquawk
                ),
                loading: false,
            };
        case DELETE_COPRFUNDSQUAWK:
            return {
                ...state,
                coprfundsquawks: state.coprfundsquawks.filter(
                    (coprfundsquawk) => coprfundsquawk._id !== payload
                ),
                loading: false,
            };
        case COPRFUNDSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRFUNDLIKES:
            return {
                ...state,
                coprfundsquawks: state.coprfundsquawks.map((coprfundsquawk) =>
                    coprfundsquawk._id === payload.id
                        ? { ...coprfundsquawk, likes: payload.likes }
                        : coprfundsquawk
                ),
                loading: false,
            };
        case ADD_COPRFUNDSQUAWK_COMMENT:
            return {
                ...state,
                coprfundsquawk: { ...state.coprfundsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRFUNDSQUAWK_COMMENT:
            return {
                ...state,
                coprfundsquawk: {
                    ...state.coprfundsquawk,
                    comments: state.coprfundsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
