import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRSquawkItem from "./DetailedCOPRSquawkItem";
import COPRSquawkCommentForm from "./COPRSquawkCommentForm";
import COPRSquawkCommentItem from "./COPRSquawkCommentItem";
import { getCOPRSquawk } from "../../../actions/coprsquawk";
// React Router v6 update: useParams replaces match.params.id & section className replaces Fragment

const COPRSquawk = ({ getCOPRSquawk, coprsquawk: { coprsquawk, loading } }) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRSquawk(id);
    }, [getCOPRSquawk, id]);

    return loading || coprsquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/privatecredit" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRSquawkItem coprsquawk={coprsquawk} />
            <p />
            <hr />
            <br />
            <COPRSquawkCommentForm coprsquawkId={coprsquawk._id} />
            <br />
            <div className="comments">
                {coprsquawk.comments.map((comment) => (
                    <COPRSquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprsquawkId={coprsquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRSquawk.propTypes = {
    getCOPRSquawk: PropTypes.func.isRequired,
    coprsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprsquawk: state.coprsquawk,
});

export default connect(mapStateToProps, { getCOPRSquawk })(COPRSquawk);
