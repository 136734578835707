import {
    GET_SQUAWKS,
    GET_SQUAWK,
    SQUAWK_ERROR,
    UPDATE_LIKES,
    DELETE_SQUAWK,
    ADD_SQUAWK,
    ADD_SQUAWK_COMMENT,
    REMOVE_SQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    squawks: [],
    squawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SQUAWKS:
            return {
                ...state,
                squawks: payload,
                loading: false,
            };
        case GET_SQUAWK:
            return {
                ...state,
                squawk: payload,
                loading: false,
            };
        case ADD_SQUAWK:
            return {
                ...state,
                squawks: [payload, ...state.squawks],
                loading: false,
            };
        case DELETE_SQUAWK:
            return {
                ...state,
                squawks: state.squawks.filter(
                    (squawk) => squawk._id !== payload
                ),
                loading: false,
            };
        case SQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_LIKES:
            return {
                ...state,
                squawks: state.squawks.map((squawk) =>
                    squawk._id === payload.id
                        ? { ...squawk, likes: payload.likes }
                        : squawk
                ),
                loading: false,
            };
        case ADD_SQUAWK_COMMENT:
            return {
                ...state,
                squawk: { ...state.squawk, comments: payload },
                loading: false
            }
        case REMOVE_SQUAWK_COMMENT:
            return {
                ...state,
                squawk: { 
                    ...state.squawk, 
                    comments: state.squawk.comments.filter(comment => comment._id !== payload) 
                },
                loading: false
            }
        default:
            return state;
    }
}
