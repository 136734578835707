import {
    GET_MNGSQUAWKS,
    GET_MNGSQUAWK,
    MNGSQUAWK_ERROR,
    UPDATE_MNGLIKES,
    DELETE_MNGSQUAWK,
    ADD_MNGSQUAWK,
    ADD_MNGSQUAWK_COMMENT,
    REMOVE_MNGSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    mngsquawks: [],
    mngsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_MNGSQUAWKS:
            return {
                ...state,
                mngsquawks: payload,
                loading: false,
            };
        case GET_MNGSQUAWK:
            return {
                ...state,
                mngsquawk: payload,
                loading: false,
            };
        case ADD_MNGSQUAWK:
            return {
                ...state,
                mngsquawks: [payload, ...state.mngsquawks],
                loading: false,
            };
        case DELETE_MNGSQUAWK:
            return {
                ...state,
                mngsquawks: state.mngsquawks.filter(
                    (mngsquawk) => mngsquawk._id !== payload
                ),
                loading: false,
            };
        case MNGSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_MNGLIKES:
            return {
                ...state,
                mngsquawks: state.mngsquawks.map((mngsquawk) =>
                    mngsquawk._id === payload.id
                        ? { ...mngsquawk, likes: payload.likes }
                        : mngsquawk
                ),
                loading: false,
            };
        case ADD_MNGSQUAWK_COMMENT:
            return {
                ...state,
                mngsquawk: { ...state.mngsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_MNGSQUAWK_COMMENT:
            return {
                ...state,
                mngsquawk: {
                    ...state.mngsquawk,
                    comments: state.mngsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
