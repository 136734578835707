import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRFundSquawkItem from "./COPRFundSquawkItem";
import SearchBox from "./COPRFundSearchBox";
import { getCOPRFundSquawks } from "../../../actions/coprfundsquawk";
import DealWallTurret from "../DealWallTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRFundSquawks = ({
    getCOPRFundSquawks,
    coprfundsquawk: { coprfundsquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRFundSquawks(keyword);
    }, [getCOPRFundSquawks, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealWallTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Select fund activity:
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link
                        to="/add-coprfundsquawk"
                        className="btn btn-primary mb-2"
                    >
                        <i className="text-primary"></i> Add Fund
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            {coprfundsquawks.length === 0 && (
                <div>
                    <strong>
                        No matching results...please revise your search!
                    </strong>
                </div>
            )}
            <Row md={4}>
                {coprfundsquawks.map((coprfundsquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRFundSquawkItem
                            key={coprfundsquawk._id}
                            coprfundsquawk={coprfundsquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRFundSquawks.propTypes = {
    getCOPRFundSquawks: PropTypes.func.isRequired,
    coprfundsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprfundsquawk: state.coprfundsquawk,
});

export default connect(mapStateToProps, { getCOPRFundSquawks })(
    COPRFundSquawks
);
