import React from "react";
import vc from "../../img/vc.jpg";
import pe from "../../img/pe.jpg";
import mna from "../../img/mna.jpg";
import credit from "../../img/credit.jpg";
import warrant from "../../img/warrant.jpg";
import rrdcf from "../../img/rrdcf.jpg";

const About = () => {
    return (
        <section className="container">
            <div className="allButFooter">
                <h1 className="x-large text-center">Welcome to Squawkify!</h1>
                <p className="lead text-center">
                    <i>squawk (verb): shout out.</i>
                    <p />
                    <br />
                    <strong>
                        <h3>
                            Put your fingers on the pulse of private capital
                            markets. Join our community!
                        </h3>
                    </strong>
                    <p />
                    <ul className="about">
                        <li>
                            Add a tombstone to our Dealerboard channels (see
                            below).
                        </li>
                        <li>Add commentary to our Hoot channels.</li>
                        <li>Use our Quickcalcs / Mini's (see below).</li>
                        <li>
                            Or, simply bask in the ambience of private market
                            signals & deal flow.
                        </li>
                    </ul>
                </p>
                <br />
                <div className="text-center">
                    <i>
                        <strong>
                            Engage in quality dialogue, browse & squawk deals
                            (VC, PE, Credit, M&A, Restructuring, Collateral,
                            Funds):
                        </strong>
                    </i>
                </div>
                <br />
                <div className="text-center">
                    <i>Venture Capital</i>
                    <img src={vc} alt="Dealerboard" className="landing-about" />
                </div>
                <br />
                <div className="text-center">
                    <i>Private Equity</i>
                    <img src={pe} alt="Dealerboard" className="landing-about" />
                </div>
                <br />
                <div className="text-center">
                    <i>M&A</i>
                    <img
                        src={mna}
                        alt="Dealerboard"
                        className="landing-about"
                    />
                </div>
                <br />
                <div className="text-center">
                    <i>Private Credit</i>
                    <img
                        src={credit}
                        alt="Dealerboard"
                        className="landing-about"
                    />
                </div>
                <br />
                <br />
                <div className="text-center">
                    <i>
                        <strong>
                            Or, make use of our QuickCalcs / Mini's:
                        </strong>
                    </i>
                </div>
                <br />
                <div className="text-center">
                    <img
                        src={warrant}
                        alt="Dealerboard"
                        className="landing-about"
                    />
                </div>
                <br />
                <div className="text-center">
                    <img
                        src={rrdcf}
                        alt="Dealerboard"
                        className="landing-about"
                    />
                </div>
                <br />
            </div>
        </section>
    );
};

export default About;
