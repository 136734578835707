import {
    GET_COPRVCSQUAWKS,
    GET_COPRVCSQUAWKS_REPO,
    GET_COPRVCSQUAWK,
    COPRVCSQUAWK_ERROR,
    UPDATE_COPRVCLIKES,
    DELETE_COPRVCSQUAWK,
    UPDATE_COPRVCSQUAWK,
    ADD_COPRVCSQUAWK,
    ADD_COPRVCSQUAWK_COMMENT,
    REMOVE_COPRVCSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprvcsquawks: [],
    coprvcsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRVCSQUAWKS:
        case GET_COPRVCSQUAWKS_REPO:
            return {
                ...state,
                coprvcsquawks: payload,
                loading: false,
            };
        case GET_COPRVCSQUAWK:
            return {
                ...state,
                coprvcsquawk: payload,
                loading: false,
            };
        case ADD_COPRVCSQUAWK:
            return {
                ...state,
                coprvcsquawks: [payload, ...state.coprvcsquawks],
                loading: false,
            };
        case UPDATE_COPRVCSQUAWK:
            return {
                ...state,
                coprvcsquawks: state.coprvcsquawks.map((coprvcsquawk) =>
                    coprvcsquawk._id === payload.id
                        ? [...payload, ...state.coprvcsquawks]
                        : coprvcsquawk
                ),
                loading: false,
            };
        case DELETE_COPRVCSQUAWK:
            return {
                ...state,
                coprvcsquawks: state.coprvcsquawks.filter(
                    (coprvcsquawk) => coprvcsquawk._id !== payload
                ),
                loading: false,
            };
        case COPRVCSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRVCLIKES:
            return {
                ...state,
                coprvcsquawks: state.coprvcsquawks.map((coprvcsquawk) =>
                    coprvcsquawk._id === payload.id
                        ? { ...coprvcsquawk, likes: payload.likes }
                        : coprvcsquawk
                ),
                loading: false,
            };
        case ADD_COPRVCSQUAWK_COMMENT:
            return {
                ...state,
                coprvcsquawk: { ...state.coprvcsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRVCSQUAWK_COMMENT:
            return {
                ...state,
                coprvcsquawk: {
                    ...state.coprvcsquawk,
                    comments: state.coprvcsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
