import React, { useState } from "react";

const BorrowingBase = () => {
    // BB = Eligible Accounts Receivable * AR Advance Rate + Eligible Inventory (or NOLV) * Inv Advance Rate
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        revolver: "",
        grossAR: "",
        arIneligiblePercent: "",
        arAdvRate: "",
        grossInv: "",
        invIneligiblePercent: "",
        invAdvRate: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        eligibleAR: "",
        ineligibleAR: "",
        availableAR: "",
        percentTotalAvailableAR: "",
        arNetEffectiveAdvRate: "",
        eligibleINV: "",
        ineligibleINV: "",
        availableINV: "",
        percentTotalAvailableINV: "",
        invNetEffectiveAdvRate: "",
        totalBorrowingBase: "",
        excessAvailability: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            revolver,
            grossAR,
            arIneligiblePercent,
            arAdvRate,
            grossInv,
            invIneligiblePercent,
            invAdvRate,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !revolver ||
            !grossAR ||
            !arIneligiblePercent ||
            !arAdvRate ||
            !grossInv ||
            !invIneligiblePercent ||
            !invAdvRate
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(revolver) ||
            isNaN(grossAR) ||
            isNaN(arIneligiblePercent) ||
            isNaN(arAdvRate) ||
            isNaN(grossInv) ||
            isNaN(invIneligiblePercent) ||
            isNaN(invAdvRate)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(revolver) <= 0 ||
            Number(grossAR) <= 0 ||
            Number(arIneligiblePercent) <= 0 ||
            Number(arAdvRate) <= 0 ||
            Number(grossInv) <= 0 ||
            Number(invIneligiblePercent) <= 0 ||
            Number(invAdvRate) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        revolver,
        grossAR,
        arIneligiblePercent,
        arAdvRate,
        grossInv,
        invIneligiblePercent,
        invAdvRate,
    }) => {
        const ineligibleAR =
            Number(grossAR) * (Number(arIneligiblePercent) / 100);
        const ineligibleINV =
            Number(grossInv) * (Number(invIneligiblePercent) / 100);
        const eligibleAR = Number(grossAR) - Number(ineligibleAR);
        const eligibleINV = Number(grossInv) - Number(ineligibleINV);
        const availableAR = Number(eligibleAR) * (Number(arAdvRate) / 100);
        const availableINV = Number(eligibleINV) * (Number(invAdvRate) / 100);
        const totalBorrowingBase = availableAR + availableINV;
        const percentTotalAvailableAR =
            (availableAR / totalBorrowingBase) * 100;
        const arNetEffectiveAdvRate = (availableAR / grossAR) * 100;
        const percentTotalAvailableINV =
            (availableINV / totalBorrowingBase) * 100;
        const invNetEffectiveAdvRate = (availableINV / grossInv) * 100;
        const excessAvailability = totalBorrowingBase - revolver;

        if (isFinite(totalBorrowingBase)) {
            const computedeligibleAR = eligibleAR.toFixed(2);
            const computedineligibleAR = ineligibleAR.toFixed(2);
            const computedavailableAR = availableAR.toFixed(2);
            const computedpercentTotalAvailableAR =
                percentTotalAvailableAR.toFixed(2);
            const computedarNetEffectiveAdvRate =
                arNetEffectiveAdvRate.toFixed(2);
            const computedeligibleINV = eligibleINV.toFixed(2);
            const computedineligibleINV = ineligibleINV.toFixed(2);
            const computedavailableINV = availableINV.toFixed(2);
            const computedpercentTotalAvailableINV =
                percentTotalAvailableINV.toFixed(2);
            const computedinvNetEffectiveAdvRate =
                invNetEffectiveAdvRate.toFixed(2);
            const computedtotalBorrowingBase = totalBorrowingBase.toFixed(2);
            const computedexcessAvailability = excessAvailability.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                eligibleAR: computedeligibleAR,
                ineligibleAR: computedineligibleAR,
                availableAR: computedavailableAR,
                percentTotalAvailableAR: computedpercentTotalAvailableAR,
                arNetEffectiveAdvRate: computedarNetEffectiveAdvRate,
                eligibleINV: computedeligibleINV,
                ineligibleINV: computedineligibleINV,
                availableINV: computedavailableINV,
                percentTotalAvailableINV: computedpercentTotalAvailableINV,
                invNetEffectiveAdvRate: computedinvNetEffectiveAdvRate,
                totalBorrowingBase: computedtotalBorrowingBase,
                excessAvailability: computedexcessAvailability,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            revolver: "",
            grossAR: "",
            arIneligiblePercent: "",
            arAdvRate: "",
            grossInv: "",
            invIneligiblePercent: "",
            invAdvRate: "",
        });

        setResults({
            eligibleAR: "",
            ineligibleAR: "",
            availableAR: "",
            percentTotalAvailableAR: "",
            arNetEffectiveAdvRate: "",
            eligibleINV: "",
            ineligibleINV: "",
            availableINV: "",
            percentTotalAvailableINV: "",
            invNetEffectiveAdvRate: "",
            totalBorrowingBase: "",
            excessAvailability: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>ABL Borrowing Base</strong>
                <br />
                <small>(ABL = Asset Based Loan)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Funded Revolver:</small>
                                <input
                                    type="text"
                                    name="revolver"
                                    placeholder=""
                                    value={userValues.revolver}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Gross AR:</small>
                                <input
                                    type="text"
                                    name="grossAR"
                                    placeholder=""
                                    value={userValues.grossAR}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Ineligible AR %:</small>
                                <input
                                    type="text"
                                    name="arIneligiblePercent"
                                    placeholder=""
                                    value={userValues.arIneligiblePercent}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*AR Adv. Rate %:</small>
                                <input
                                    type="text"
                                    name="arAdvRate"
                                    placeholder=""
                                    value={userValues.arAdvRate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Gross Inv (or NOLV):</small>
                                <input
                                    type="text"
                                    name="grossInv"
                                    placeholder=""
                                    value={userValues.grossInv}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>
                                    *Ineligible Inv % (enter '0.0001' if NOLV):
                                </small>
                                <input
                                    type="text"
                                    name="invIneligiblePercent"
                                    placeholder=""
                                    value={userValues.invIneligiblePercent}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Inv Adv. Rate %:</small>
                                <input
                                    type="text"
                                    name="invAdvRate"
                                    placeholder=""
                                    value={userValues.invAdvRate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            Net Effective Advance Rates: AR (
                            {results.arNetEffectiveAdvRate}%), &nbsp;Inv (
                            {results.invNetEffectiveAdvRate}%) <p />% Total
                            Available:&nbsp;AR (
                            {results.percentTotalAvailableAR}%), &nbsp;Inv (
                            {results.percentTotalAvailableINV}%) <p />
                            Collateral Coverage:&nbsp;
                            {(
                                results.totalBorrowingBase / userValues.revolver
                            ).toFixed(2)}
                            x&nbsp;|&nbsp;LTV:&nbsp;
                            {(
                                userValues.revolver / results.totalBorrowingBase
                            ).toFixed(2)}
                            %
                            <br />
                        </h6>
                        <p />
                        <div>
                            <label id="label">Total Borrowing Base:</label>
                            <input
                                type="text"
                                value={results.totalBorrowingBase}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Excess Availability: </label>
                            <input
                                type="text"
                                value={
                                    results.excessAvailability < 0
                                        ? "No EA - collateral deficiency: " +
                                          -results.excessAvailability
                                        : results.excessAvailability
                                }
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default BorrowingBase;
