import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import DashboardActions from "./DashboardActions";
import Experience from "./Experience";
import Education from "./Education";
import Tombstone from "./Tombstone";
import { deleteAccount, getCurrentProfile } from "../../actions/profile";

const Dashboard = ({
    getCurrentProfile,
    deleteAccount,
    auth: { user },
    profile: { profile, loading },
}) => {
    useEffect(() => {
        getCurrentProfile();
    }, [getCurrentProfile]);

    return loading && profile === null ? (
        <Spinner />
    ) : (
        <section className="container">
            {/* <Link to="/privatecredit" className="btn btn-primary">
                &laquo; Deals
            </Link>
            <p /> */}
            <h3 className="text-primary">Profile Dashboard</h3>
            <p className="lead">
                <i className="fas fa-user"> Welcome {user && user.username}</i>
            </p>
            <br />
            {profile !== null ? (
                <>
                    <Link to="/privatecredit" className="btn btn-primary">
                        &laquo; Deals
                    </Link>
                    <p />
                    <DashboardActions />
                    <Experience experience={profile.experience} />
                    <Education education={profile.education} />
                    <Tombstone tombstone={profile.tombstone} />
                    <div className="my-2">
                        <button
                            className="btn btn-danger"
                            onClick={() => deleteAccount()}
                        >
                            <i className="fas fa-user-minus"></i>Delete My
                            Account
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        You have not yet set up a profile, please tell us about
                        yourself:
                    </p>
                    <Link to="/create-profile" className="btn btn-primary my-1">
                        Create Profile
                    </Link>
                </>
            )}
        </section>
    );
};

Dashboard.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile, deleteAccount })(
    Dashboard
);
