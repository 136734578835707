import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRSquawkItem = ({
    coprsquawk: {
        _id,
        user,
        username,
        coprsquawkTransactionDate,
        coprsquawkBorrower,
        coprsquawkSector,
        coprsquawkSubSector,
        coprsquawkLender,
        coprsquawkSponsor,
        coprsquawkDebtAmount,
        coprsquawkDebtStructure,
        coprsquawkTransactionDesc,
        coprsquawkPricing,
        coprsquawkARAdvRate,
        coprsquawkInvAdvRate,
        coprsquawkREAdvRate,
        coprsquawkMNEAdvRate,
        coprsquawkBorrowerUrl,
        // coprsquawkPRUrl,
        text,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprsquawkPRUrl === "" ? ( */}
                    <strong>{coprsquawkBorrower}</strong>
                    {/* ) : (
                        <a href={coprsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <strong>Borrower: {coprsquawkBorrower}</strong>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprsquawkBorrowerUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprsquawkBorrowerUrl}
                    </small>
                </Col>
                <Col>
                    <small>Sponsor: {coprsquawkSponsor}</small>
                </Col>
                <Col>
                    <small>
                        Transaction Date:{" "}
                        {new Date(coprsquawkTransactionDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Lender: {coprsquawkLender}</small>
                </Col>
                <Col>
                    <small>
                        Amount: $
                        {coprsquawkDebtAmount
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Structure: {coprsquawkDebtStructure}</small>
                </Col>
                <Col>
                    <small>Pricing: {coprsquawkPricing}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Desc.: {coprsquawkTransactionDesc}</small>
                </Col>
                <Col>
                    <small>Sector: {coprsquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprsquawkSubSector}</small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <small>ABL Advance Rates %</small>
            <Row>
                <Col>
                    <small>A/R: {coprsquawkARAdvRate}</small>
                </Col>
                <Col>
                    <small>Inventory: {coprsquawkInvAdvRate}</small>
                </Col>
                <Col>
                    <small>Real Estate: {coprsquawkREAdvRate}</small>
                </Col>
                <Col>
                    <small>M&E: {coprsquawkMNEAdvRate}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>{text}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRSquawkItem.propTypes = {
    coprsquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRSquawkItem;
