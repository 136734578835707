import { combineReducers } from "redux";
import alert from "./alert";
import authReducer from "./auth";
import profile from "./profile";
// Squawks
import squawk from "./squawk";
import bksquawk from "./bksquawk";
import collsquawk from "./collsquawk";
import creditsquawk from "./creditsquawk";
import fundsquawk from "./fundsquawk";
import jobsquawk from "./jobsquawk";
import legalsquawk from "./legalsquawk";
import mnasquawk from "./mnasquawk";
import mngsquawk from "./mngsquawk";
import pesquawk from "./pesquawk";
import vcsquawk from "./vcsquawk";
// Deal Wall
import coprsquawk from "./coprsquawk";
import coprbksquawk from "./coprbksquawk";
import coprpesquawk from "./coprpesquawk";
import coprvcsquawk from "./coprvcsquawk";
import coprmnasquawk from "./coprmnasquawk";
import coprfundsquawk from "./coprfundsquawk";
import coprcollsquawk from "./coprcollsquawk";

export default combineReducers({
    alert,
    auth: authReducer,
    profile,
    squawk,
    bksquawk,
    collsquawk,
    creditsquawk,
    fundsquawk,
    jobsquawk,
    legalsquawk,
    mnasquawk,
    mngsquawk,
    pesquawk,
    vcsquawk,
    coprsquawk,
    coprbksquawk,
    coprpesquawk,
    coprvcsquawk,
    coprmnasquawk,
    coprfundsquawk,
    coprcollsquawk,
});
