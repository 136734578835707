import React, { useState } from "react";

const OilGas = () => {
    // O&G credit and collateral analysis
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        revolver: "",
        fundedRevolver: "",
        termLoan: "",
        ltmEBITDA: "",
        capex: "",
        interest: "",
        production: "",
        pdp: "",
        pdpAdvRate: "",
        pdnp: "",
        pdnpAdvRate: "",
        pud: "",
        pudAdvRate: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        pdpBB: "",
        pdnpBB: "",
        pudBB: "",
        totalProvedBB: "",
        effectiveAdvRate: "",
        suppressedAvailability: "",
        excessAvailability: "",
        collateralCoverage: "",
        loanToValue: "",
        debtToPD: "",
        debtToProvedReserves: "",
        revDebtToEBITDA: "",
        tlDebtToEBITDA: "",
        ebitdaLessCapexToInterest: "",
        provedReserveLife: "",
        pdReserveLife: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            revolver,
            fundedRevolver,
            termLoan,
            ltmEBITDA,
            capex,
            interest,
            production,
            pdp,
            pdpAdvRate,
            pdnp,
            pdnpAdvRate,
            pud,
            pudAdvRate,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !revolver ||
            !fundedRevolver ||
            !termLoan ||
            !ltmEBITDA ||
            !capex ||
            !interest ||
            !production ||
            !pdp ||
            !pdpAdvRate ||
            !pdnp ||
            !pdnpAdvRate ||
            !pud ||
            !pudAdvRate
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(revolver) ||
            isNaN(fundedRevolver) ||
            isNaN(termLoan) ||
            isNaN(ltmEBITDA) ||
            isNaN(capex) ||
            isNaN(interest) ||
            isNaN(production) ||
            isNaN(pdp) ||
            isNaN(pdpAdvRate) ||
            isNaN(pdnp) ||
            isNaN(pdnpAdvRate) ||
            isNaN(pud) ||
            isNaN(pudAdvRate)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(revolver) <= 0 ||
            Number(fundedRevolver) <= 0 ||
            Number(termLoan) <= 0 ||
            Number(ltmEBITDA) <= 0 ||
            Number(capex) <= 0 ||
            Number(interest) <= 0 ||
            Number(production) <= 0 ||
            Number(pdp) <= 0 ||
            Number(pdpAdvRate) <= 0 ||
            Number(pdnp) <= 0 ||
            Number(pdnpAdvRate) <= 0 ||
            Number(pud) <= 0 ||
            Number(pudAdvRate) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        revolver,
        fundedRevolver,
        termLoan,
        ltmEBITDA,
        capex,
        interest,
        production,
        pdp,
        pdpAdvRate,
        pdnp,
        pdnpAdvRate,
        pud,
        pudAdvRate,
    }) => {
        // Apply advance rates to each reserve category
        // Proved Developed Producing
        const pdpBB = Number(pdp) * (Number(pdpAdvRate) / 100);
        // Proved Developed Not Producing
        const pdnpBB = Number(pdnp) * (Number(pdnpAdvRate) / 100);
        // Proved Undeveloped
        const pudBB = Number(pud) * (Number(pudAdvRate) / 100);
        // Sum to compute total proved reserves borrowing base
        const totalProvedBB = Number(pdpBB) + Number(pdnpBB) + Number(pudBB);
        // Net Effective Advance Rate on total Proved Reserves
        const effectiveAdvRate =
            (Number(totalProvedBB) /
                (Number(pdp) + Number(pdnp) + Number(pud))) *
            100;
        // Test for Suppressed Availability
        const suppressedAvailability =
            Number(totalProvedBB) - Number(revolver) < 0
                ? 0
                : Number(totalProvedBB) - Number(revolver);
        // Test for Excess Availability
        const excessAvailability = Number(revolver) - Number(fundedRevolver);
        // Compute asset coverage ratio
        const collateralCoverage = Number(totalProvedBB) / Number(revolver);
        // Compute loan-to-value on a fully funded basis
        const loanToValue = (Number(revolver) / Number(totalProvedBB)) * 100;
        // Credit statistics
        // Debt / Proved Developed Reserves
        const debtToPD =
            (Number(fundedRevolver) + Number(termLoan)) / Number(pdp);
        // Debt / Proved Reserves
        const debtToProvedReserves =
            (Number(fundedRevolver) + Number(termLoan)) /
            (Number(pdp) + Number(pdnp) + Number(pud));
        // Funded Revolver / LTM EBITDA
        const revDebtToEBITDA = Number(fundedRevolver) / Number(ltmEBITDA);
        // Funded Term Loan / LTM EBITDA
        const tlDebtToEBITDA = Number(termLoan) / Number(ltmEBITDA);
        // Coverage ratio: (LTM EBITDA - Capex) / Interest
        const ebitdaLessCapexToInterest =
            Number(ltmEBITDA) - Number(capex) < 0
                ? 0
                : (Number(ltmEBITDA) - Number(capex)) / Number(interest);
        // Reserve Life (yrs): Total Proved Reserves / Production
        const provedReserveLife =
            (Number(pdp) + Number(pdnp) + Number(pud)) / Number(production);
        // PD Reserve Life (yrs): Proved Developed Reserves / Production
        const pdReserveLife = Number(pdp) / Number(production);

        if (isFinite(totalProvedBB)) {
            const computedPDPBB = pdpBB.toFixed(2);
            const computedPDNPBB = pdnpBB.toFixed(2);
            const computedPUDBB = pudBB.toFixed(2);
            const computedTotalProvedBB = totalProvedBB.toFixed(2);
            const computedEffectiveAdvRate = effectiveAdvRate.toFixed(2);
            const computedSuppressedAvailability = suppressedAvailability.toFixed(
                2
            );
            const computedExcessAvailability = excessAvailability.toFixed(2);
            const computedCollateralCoverage = collateralCoverage.toFixed(2);
            const computedLoanToValue = loanToValue.toFixed(2);
            const computedDebtToPD = debtToPD.toFixed(2);
            const computedDebtToProvedReserves = debtToProvedReserves.toFixed(
                2
            );
            const computedRevDebtToEBITDA = revDebtToEBITDA.toFixed(2);
            const computedTLDebtToEBITDA = tlDebtToEBITDA.toFixed(2);
            const computedEBITDALessCapexToInterest = ebitdaLessCapexToInterest.toFixed(
                2
            );
            const computedProvedReserveLife = provedReserveLife.toFixed(2);
            const computedPDReserveLife = pdReserveLife.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                pdpBB: computedPDPBB,
                pdnpBB: computedPDNPBB,
                pudBB: computedPUDBB,
                totalProvedBB: computedTotalProvedBB,
                effectiveAdvRate: computedEffectiveAdvRate,
                suppressedAvailability: computedSuppressedAvailability,
                excessAvailability: computedExcessAvailability,
                collateralCoverage: computedCollateralCoverage,
                loanToValue: computedLoanToValue,
                debtToPD: computedDebtToPD,
                debtToProvedReserves: computedDebtToProvedReserves,
                revDebtToEBITDA: computedRevDebtToEBITDA,
                tlDebtToEBITDA: computedTLDebtToEBITDA,
                ebitdaLessCapexToInterest: computedEBITDALessCapexToInterest,
                provedReserveLife: computedProvedReserveLife,
                pdReserveLife: computedPDReserveLife,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            revolver: "",
            fundedRevolver: "",
            termLoan: "",
            ltmEBITDA: "",
            capex: "",
            interest: "",
            production: "",
            pdp: "",
            pdpAdvRate: "",
            pdnp: "",
            pdnpAdvRate: "",
            pud: "",
            pudAdvRate: "",
        });

        setResults({
            pdpBB: "",
            pdnpBB: "",
            pudBB: "",
            totalProvedBB: "",
            effectiveAdvRate: "",
            suppressedAvailability: "",
            excessAvailability: "",
            collateralCoverage: "",
            loanToValue: "",
            debtToPD: "",
            debtToProvedReserves: "",
            revDebtToEBITDA: "",
            tlDebtToEBITDA: "",
            ebitdaLessCapexToInterest: "",
            provedReserveLife: "",
            pdReserveLife: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Oil & Gas Lending Analysis</strong>
                <br />
                <small>(Basic RBL BB & Credit Analysis)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*LTM EBITDA:</small>
                                <input
                                    type="text"
                                    name="ltmEBITDA"
                                    placeholder=""
                                    value={userValues.ltmEBITDA}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Revolver:</small>
                                <input
                                    type="text"
                                    name="revolver"
                                    placeholder=""
                                    value={userValues.revolver}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Funded Revolver:</small>
                                <input
                                    type="text"
                                    name="fundedRevolver"
                                    placeholder=""
                                    value={userValues.fundedRevolver}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Term Loan:</small>
                                <input
                                    type="text"
                                    name="termLoan"
                                    placeholder=""
                                    value={userValues.termLoan}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Interest:</small>
                                <input
                                    type="text"
                                    name="interest"
                                    placeholder=""
                                    value={userValues.interest}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Capex:</small>
                                <input
                                    type="text"
                                    name="capex"
                                    placeholder=""
                                    value={userValues.capex}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Production:</small>
                                <input
                                    type="text"
                                    name="production"
                                    placeholder=""
                                    value={userValues.production}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <strong>Reserve Borrowing Base Analysis</strong>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*PDP</small>
                                <input
                                    type="text"
                                    name="pdp"
                                    placeholder=""
                                    value={userValues.pdp}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*PDNP:</small>
                                <input
                                    type="text"
                                    name="pdnp"
                                    placeholder=""
                                    value={userValues.pdnp}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*PUD:</small>
                                <input
                                    type="text"
                                    name="pud"
                                    placeholder=""
                                    value={userValues.pud}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*PDP Advance Rate:</small>
                                <input
                                    type="text"
                                    name="pdpAdvRate"
                                    placeholder=""
                                    value={userValues.pdpAdvRate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*PDNP Advance Rate:</small>
                                <input
                                    type="text"
                                    name="pdnpAdvRate"
                                    placeholder=""
                                    value={userValues.pdnpAdvRate}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*PUD Advance Rate:</small>
                                <input
                                    type="text"
                                    name="pudAdvRate"
                                    placeholder=""
                                    value={userValues.pudAdvRate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            (LTM EBITDA - Capex) / Interest: &nbsp;
                            {results.ebitdaLessCapexToInterest}x
                            <p />
                            Proved Developed Reserve Life: &nbsp;
                            {results.pdReserveLife}&nbsp;years&nbsp;&nbsp;
                            Proved Reserve Life: &nbsp;
                            {results.provedReserveLife}&nbsp;years
                            <br />
                            Debt / PD: &nbsp; ${results.debtToPD}&nbsp;&nbsp;
                            Debt / Total Proved: &nbsp; $
                            {results.debtToProvedReserves}
                            <p />
                            Funded Revolver / LTM EBITDA: &nbsp;
                            {results.revDebtToEBITDA}x&nbsp;&nbsp; Term Loan /
                            LTM EBITDA: &nbsp;
                            {results.tlDebtToEBITDA}x
                            <br />
                            Collateral Coverage(x): &nbsp;
                            {results.collateralCoverage}x&nbsp;&nbsp;
                            Loan-to-Value (LTV): &nbsp;
                            {results.loanToValue}%
                            <p />
                            <strong>Revolver Stats</strong>
                            <br />
                            Effective Advance Rate: &nbsp;
                            {results.effectiveAdvRate}%<br />
                            Suppressed Availability: &nbsp;
                            {results.suppressedAvailability
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;&nbsp; Excess Availability: &nbsp;
                            {results.excessAvailability
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                        </h6>
                        <p />
                        <div>
                            <label id="label">PDP Availability:</label>
                            <input
                                type="text"
                                value={results.pdpBB
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">PDNP Availability:</label>
                            <input
                                type="text"
                                value={results.pdnpBB
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">PUD Availability:</label>
                            <input
                                type="text"
                                value={results.pudBB
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Total Proved Availability:</label>
                            <input
                                type="text"
                                value={results.totalProvedBB
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default OilGas;
