import {
    GET_MNASQUAWKS,
    GET_MNASQUAWK,
    MNASQUAWK_ERROR,
    UPDATE_MNALIKES,
    DELETE_MNASQUAWK,
    ADD_MNASQUAWK,
    ADD_MNASQUAWK_COMMENT,
    REMOVE_MNASQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    mnasquawks: [],
    mnasquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_MNASQUAWKS:
            return {
                ...state,
                mnasquawks: payload,
                loading: false,
            };
        case GET_MNASQUAWK:
            return {
                ...state,
                mnasquawk: payload,
                loading: false,
            };
        case ADD_MNASQUAWK:
            return {
                ...state,
                mnasquawks: [payload, ...state.mnasquawks],
                loading: false,
            };
        case DELETE_MNASQUAWK:
            return {
                ...state,
                mnasquawks: state.mnasquawks.filter(
                    (mnasquawk) => mnasquawk._id !== payload
                ),
                loading: false,
            };
        case MNASQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_MNALIKES:
            return {
                ...state,
                mnasquawks: state.mnasquawks.map((mnasquawk) =>
                    mnasquawk._id === payload.id
                        ? { ...mnasquawk, likes: payload.likes }
                        : mnasquawk
                ),
                loading: false,
            };
        case ADD_MNASQUAWK_COMMENT:
            return {
                ...state,
                mnasquawk: { ...state.mnasquawk, comments: payload },
                loading: false,
            };
        case REMOVE_MNASQUAWK_COMMENT:
            return {
                ...state,
                mnasquawk: {
                    ...state.mnasquawk,
                    comments: state.mnasquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
