import React, { useState } from "react";

const ARPURevenue = () => {
    //
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        currentUserBase: "",
        currentARPU: "",
        yr1UserGrowth: "",
        yr2UserGrowth: "",
        yr3UserGrowth: "",
        yr1ARPUGrowth: "",
        yr2ARPUGrowth: "",
        yr3ARPUGrowth: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        currentRevenue: "",
        yr1UserBase: "",
        yr2UserBase: "",
        yr3UserBase: "",
        yr1ARPU: "",
        yr2ARPU: "",
        yr3ARPU: "",
        yr1Revenue: "",
        yr2Revenue: "",
        yr3Revenue: "",
        yr1RevenueGrowth: "",
        yr2RevenueGrowth: "",
        yr3RevenueGrowth: "",
        userBaseCAGR: "",
        arpuCAGR: "",
        revenueCAGR: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            currentUserBase,
            currentARPU,
            yr1UserGrowth,
            yr2UserGrowth,
            yr3UserGrowth,
            yr1ARPUGrowth,
            yr2ARPUGrowth,
            yr3ARPUGrowth,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !currentUserBase ||
            !currentARPU ||
            !yr1UserGrowth ||
            !yr2UserGrowth ||
            !yr3UserGrowth ||
            !yr1ARPUGrowth ||
            !yr2ARPUGrowth ||
            !yr3ARPUGrowth
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(currentUserBase) ||
            isNaN(currentARPU) ||
            isNaN(yr1UserGrowth) ||
            isNaN(yr2UserGrowth) ||
            isNaN(yr3UserGrowth) ||
            isNaN(yr1ARPUGrowth) ||
            isNaN(yr2ARPUGrowth) ||
            isNaN(yr3ARPUGrowth)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(currentUserBase) <= 0 ||
            Number(currentARPU) <= 0
            // Number() <= 0 ||
            // Number() <= 0 ||
            // Number() <= 0 ||
            // Number() <= 0 ||
            // Number() <= 0 ||
            // Number() <= 0 ||
            // Number() <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        currentUserBase,
        currentARPU,
        yr1UserGrowth,
        yr2UserGrowth,
        yr3UserGrowth,
        yr1ARPUGrowth,
        yr2ARPUGrowth,
        yr3ARPUGrowth,
    }) => {
        // CAGR years const
        const yrsCAGR = 4;
        const expCAGR = 1 / yrsCAGR;
        // Decimalize
        const yrOneUserGrowth = Number(yr1UserGrowth) / 100;
        const yrTwoUserGrowth = Number(yr2UserGrowth) / 100;
        const yrThreeUserGrowth = Number(yr3UserGrowth) / 100;
        const yrOneARPUGrowth = Number(yr1ARPUGrowth) / 100;
        const yrTwoARPUGrowth = Number(yr2ARPUGrowth) / 100;
        const yrThreeARPUGrowth = Number(yr3ARPUGrowth) / 100;
        // Compute Implied Actual Revenue
        const currentRevenue = Number(currentUserBase) * Number(currentARPU);
        // Derive User Base Projections Yr 1 - 3
        const yr1UserBase =
            Number(currentUserBase) * (1 + Number(yrOneUserGrowth));
        const yr2UserBase = yr1UserBase * (1 + Number(yrTwoUserGrowth));
        const yr3UserBase = yr2UserBase * (1 + Number(yrThreeUserGrowth));
        // Derive ARPU Projections Yr 1 - 3
        const yr1ARPU = Number(currentARPU) * (1 + Number(yrOneARPUGrowth));
        const yr2ARPU = yr1ARPU * (1 + Number(yrTwoARPUGrowth));
        const yr3ARPU = yr2ARPU * (1 + Number(yrThreeARPUGrowth));
        // Derive Revenue Projections Yr 1 - 3
        const yr1Revenue = yr1UserBase * yr1ARPU;
        const yr2Revenue = yr2UserBase * yr2ARPU;
        const yr3Revenue = yr3UserBase * yr3ARPU;
        // Compute Revenue Growth Rates Yr 1 - 3
        const yr1RevenueGrowthDecimal = yr1Revenue / currentRevenue - 1;
        const yr1RevenueGrowth = yr1RevenueGrowthDecimal * 100;
        const yr2RevenueGrowthDecimal = yr2Revenue / yr1Revenue - 1;
        const yr2RevenueGrowth = yr2RevenueGrowthDecimal * 100;
        const yr3RevenueGrowthDecimal = yr3Revenue / yr2Revenue - 1;
        const yr3RevenueGrowth = yr3RevenueGrowthDecimal * 100;
        // Compute CAGRs
        const expCAGRFactor = expCAGR;

        const userBaseCAGRNum = yr3UserBase / Number(currentUserBase);
        const userBaseCAGRDecimal = Math.pow(userBaseCAGRNum, expCAGRFactor);
        const userBaseCAGR = (userBaseCAGRDecimal - 1) * 100;

        const arpuCAGRNum = yr3ARPU / Number(currentARPU);
        const arpuCAGRDecimal = Math.pow(arpuCAGRNum, expCAGRFactor);
        const arpuCAGR = (arpuCAGRDecimal - 1) * 100;

        const revenueCAGRNum = yr3Revenue / currentRevenue;
        const revenueCAGRDecimal = Math.pow(revenueCAGRNum, expCAGRFactor);
        const revenueCAGR = (revenueCAGRDecimal - 1) * 100;

        if (isFinite(currentRevenue)) {
            const computedCurrentRevenue = currentRevenue.toFixed(2);
            const computedYr1UserBase = yr1UserBase.toFixed(2);
            const computedYr2UserBase = yr2UserBase.toFixed(2);
            const computedYr3UserBase = yr3UserBase.toFixed(2);
            const computedYr1ARPU = yr1ARPU.toFixed(2);
            const computedYr2ARPU = yr2ARPU.toFixed(2);
            const computedYr3ARPU = yr3ARPU.toFixed(2);
            const computedYr1Revenue = yr1Revenue.toFixed(2);
            const computedYr2Revenue = yr2Revenue.toFixed(2);
            const computedYr3Revenue = yr3Revenue.toFixed(2);
            const computedYr1RevenueGrowth = yr1RevenueGrowth.toFixed(2);
            const computedYr2RevenueGrowth = yr2RevenueGrowth.toFixed(2);
            const computedYr3RevenueGrowth = yr3RevenueGrowth.toFixed(2);
            const computedUserBaseCAGR = userBaseCAGR.toFixed(2);
            const computedArpuCAGR = arpuCAGR.toFixed(2);
            const computedRevenueCAGR = revenueCAGR.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                currentRevenue: computedCurrentRevenue,
                yr1UserBase: computedYr1UserBase,
                yr2UserBase: computedYr2UserBase,
                yr3UserBase: computedYr3UserBase,
                yr1ARPU: computedYr1ARPU,
                yr2ARPU: computedYr2ARPU,
                yr3ARPU: computedYr3ARPU,
                yr1Revenue: computedYr1Revenue,
                yr2Revenue: computedYr2Revenue,
                yr3Revenue: computedYr3Revenue,
                yr1RevenueGrowth: computedYr1RevenueGrowth,
                yr2RevenueGrowth: computedYr2RevenueGrowth,
                yr3RevenueGrowth: computedYr3RevenueGrowth,
                userBaseCAGR: computedUserBaseCAGR,
                arpuCAGR: computedArpuCAGR,
                revenueCAGR: computedRevenueCAGR,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            currentUserBase: "",
            currentARPU: "",
            yr1UserGrowth: "",
            yr2UserGrowth: "",
            yr3UserGrowth: "",
            yr1ARPUGrowth: "",
            yr2ARPUGrowth: "",
            yr3ARPUGrowth: "",
        });

        setResults({
            currentRevenue: "",
            yr1UserBase: "",
            yr2UserBase: "",
            yr3UserBase: "",
            yr1ARPU: "",
            yr2ARPU: "",
            yr3ARPU: "",
            yr1Revenue: "",
            yr2Revenue: "",
            yr3Revenue: "",
            yr1RevenueGrowth: "",
            yr2RevenueGrowth: "",
            yr3RevenueGrowth: "",
            userBaseCAGR: "",
            arpuCAGR: "",
            revenueCAGR: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>ARPU-based Revenue Projections</strong>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Current User Base:</small>
                                <input
                                    type="text"
                                    name="currentUserBase"
                                    placeholder=""
                                    value={userValues.currentUserBase}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Current ARPU:</small>
                                <input
                                    type="text"
                                    name="currentARPU"
                                    placeholder=""
                                    value={userValues.currentARPU}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 1 User Growth %:</small>
                                <input
                                    type="text"
                                    name="yr1UserGrowth"
                                    placeholder=""
                                    value={userValues.yr1UserGrowth}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 2 User Growth %:</small>
                                <input
                                    type="text"
                                    name="yr2UserGrowth"
                                    placeholder=""
                                    value={userValues.yr2UserGrowth}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 3 User Growth %:</small>
                                <input
                                    type="text"
                                    name="yr3UserGrowth"
                                    placeholder=""
                                    value={userValues.yr3UserGrowth}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 1 ARPU Growth %:</small>
                                <input
                                    type="text"
                                    name="yr1ARPUGrowth"
                                    placeholder=""
                                    value={userValues.yr1ARPUGrowth}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 2 ARPU Growth %:</small>
                                <input
                                    type="text"
                                    name="yr2ARPUGrowth"
                                    placeholder=""
                                    value={userValues.yr2ARPUGrowth}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 3 ARPU Growth %:</small>
                                <input
                                    type="text"
                                    name="yr3ARPUGrowth"
                                    placeholder=""
                                    value={userValues.yr3ARPUGrowth}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6 className="text-center">
                            <table className="calctable">
                                <tr>
                                    <td></td>
                                    <th scope="col">Actual</th>
                                    <th scope="col">Yr 1</th>
                                    <th scope="col">Yr 2</th>
                                    <th scope="col">Yr 3</th>
                                    <th scope="col">CAGR</th>
                                </tr>
                                <tr>
                                    <th scope="row">Users</th>
                                    <td>{userValues.currentUserBase}</td>
                                    <td>{results.yr1UserBase}</td>
                                    <td>{results.yr2UserBase}</td>
                                    <td>{results.yr3UserBase}</td>
                                    <td>
                                        <strong>{results.userBaseCAGR}%</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <small>% growth</small>
                                    </th>
                                    <td>--</td>
                                    <td>
                                        {(userValues.yr1UserGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>
                                        {(userValues.yr2UserGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>
                                        {(userValues.yr3UserGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <th scope="row">ARPU</th>
                                    <td>{userValues.currentARPU}</td>
                                    <td>{results.yr1ARPU}</td>
                                    <td>{results.yr2ARPU}</td>
                                    <td>{results.yr3ARPU}</td>
                                    <td>
                                        <strong>{results.arpuCAGR}%</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <small>% growth</small>
                                    </th>
                                    <td>--</td>
                                    <td>
                                        {(userValues.yr1ARPUGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>
                                        {(userValues.yr2ARPUGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>
                                        {(userValues.yr3ARPUGrowth * 1).toFixed(
                                            2
                                        )}
                                        %
                                    </td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <th scope="row">Revenue</th>
                                    <td>{results.currentRevenue}</td>
                                    <td>{results.yr1Revenue}</td>
                                    <td>{results.yr2Revenue}</td>
                                    <td>{results.yr3Revenue}</td>
                                    <td>
                                        <strong>{results.revenueCAGR}%</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <small>% growth</small>
                                    </th>
                                    <td>--</td>
                                    <td>{results.yr1RevenueGrowth}%</td>
                                    <td>{results.yr2RevenueGrowth}%</td>
                                    <td>{results.yr3RevenueGrowth}%</td>
                                    <td>--</td>
                                </tr>
                            </table>
                        </h6>
                        <p />
                        <h6>
                            <strong>Revenue Projections</strong>
                        </h6>
                        <div>
                            <label id="label">Year 1:</label>
                            <input
                                type="text"
                                value={(results.yr1Revenue * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Year 2:</label>
                            <input
                                type="text"
                                value={(results.yr2Revenue * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Year 3:</label>
                            <input
                                type="text"
                                value={(results.yr3Revenue * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default ARPURevenue;
