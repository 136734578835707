import {
    GET_PESQUAWKS,
    GET_PESQUAWK,
    PESQUAWK_ERROR,
    UPDATE_PELIKES,
    DELETE_PESQUAWK,
    ADD_PESQUAWK,
    ADD_PESQUAWK_COMMENT,
    REMOVE_PESQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    pesquawks: [],
    pesquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PESQUAWKS:
            return {
                ...state,
                pesquawks: payload,
                loading: false,
            };
        case GET_PESQUAWK:
            return {
                ...state,
                pesquawk: payload,
                loading: false,
            };
        case ADD_PESQUAWK:
            return {
                ...state,
                pesquawks: [payload, ...state.pesquawks],
                loading: false,
            };
        case DELETE_PESQUAWK:
            return {
                ...state,
                pesquawks: state.pesquawks.filter(
                    (pesquawk) => pesquawk._id !== payload
                ),
                loading: false,
            };
        case PESQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_PELIKES:
            return {
                ...state,
                pesquawks: state.pesquawks.map((pesquawk) =>
                    pesquawk._id === payload.id
                        ? { ...pesquawk, likes: payload.likes }
                        : pesquawk
                ),
                loading: false,
            };
        case ADD_PESQUAWK_COMMENT:
            return {
                ...state,
                pesquawk: { ...state.pesquawk, comments: payload },
                loading: false,
            };
        case REMOVE_PESQUAWK_COMMENT:
            return {
                ...state,
                pesquawk: {
                    ...state.pesquawk,
                    comments: state.pesquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
