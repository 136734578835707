import React, { useState } from "react";

const LBOIRR = () => {
    //
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        newEquity: "",
        exitEBITDAMultiple: "",
        yr1EBITDA: "",
        yr2EBITDA: "",
        yr3EBITDA: "",
        yr4EBITDA: "",
        yr1NetDebt: "",
        yr2NetDebt: "",
        yr3NetDebt: "",
        yr4NetDebt: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        yr1ImpliedEV: "",
        yr2ImpliedEV: "",
        yr3ImpliedEV: "",
        yr4ImpliedEV: "",
        yr1ImpliedEquityValue: "",
        yr2ImpliedEquityValue: "",
        yr3ImpliedEquityValue: "",
        yr4ImpliedEquityValue: "",
        yr1ImpliedIRR: "",
        yr2ImpliedIRR: "",
        yr3ImpliedIRR: "",
        yr4ImpliedIRR: "",
        yr1ImpliedMultiple: "",
        yr2ImpliedMultiple: "",
        yr3ImpliedMultiple: "",
        yr4ImpliedMultiple: "",
        yr1NetDebtToEBITDA: "",
        yr2NetDebtToEBITDA: "",
        yr3NetDebtToEBITDA: "",
        yr4NetDebtToEBITDA: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            newEquity,
            exitEBITDAMultiple,
            yr1EBITDA,
            yr2EBITDA,
            yr3EBITDA,
            yr4EBITDA,
            yr1NetDebt,
            yr2NetDebt,
            yr3NetDebt,
            yr4NetDebt,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !newEquity ||
            !exitEBITDAMultiple ||
            !yr1EBITDA ||
            !yr2EBITDA ||
            !yr3EBITDA ||
            !yr4EBITDA ||
            !yr1NetDebt ||
            !yr2NetDebt ||
            !yr3NetDebt ||
            !yr4NetDebt
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(newEquity) ||
            isNaN(exitEBITDAMultiple) ||
            isNaN(yr1EBITDA) ||
            isNaN(yr2EBITDA) ||
            isNaN(yr3EBITDA) ||
            isNaN(yr4EBITDA) ||
            isNaN(yr1NetDebt) ||
            isNaN(yr2NetDebt) ||
            isNaN(yr3NetDebt) ||
            isNaN(yr4NetDebt)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(newEquity) <= 0 ||
            Number(exitEBITDAMultiple) <= 0 ||
            Number(yr1EBITDA) <= 0 ||
            Number(yr2EBITDA) <= 0 ||
            Number(yr3EBITDA) <= 0 ||
            Number(yr4EBITDA) <= 0 ||
            Number(yr1NetDebt) <= 0 ||
            Number(yr2NetDebt) <= 0 ||
            Number(yr3NetDebt) <= 0 ||
            Number(yr4NetDebt) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        newEquity,
        exitEBITDAMultiple,
        yr1EBITDA,
        yr2EBITDA,
        yr3EBITDA,
        yr4EBITDA,
        yr1NetDebt,
        yr2NetDebt,
        yr3NetDebt,
        yr4NetDebt,
    }) => {
        // Years const
        const yrOne = 1;
        const yrTwo = 2;
        const yrThree = 3;
        const yrFour = 4;
        // Implied Enterprise Value
        const yr1ImpliedEV = Number(yr1EBITDA) * Number(exitEBITDAMultiple);
        const yr2ImpliedEV = Number(yr2EBITDA) * Number(exitEBITDAMultiple);
        const yr3ImpliedEV = Number(yr3EBITDA) * Number(exitEBITDAMultiple);
        const yr4ImpliedEV = Number(yr4EBITDA) * Number(exitEBITDAMultiple);
        // Compute Implied Equity Value
        const yr1ImpliedEquityValue = Number(yr1ImpliedEV) - Number(yr1NetDebt);
        const yr2ImpliedEquityValue = Number(yr2ImpliedEV) - Number(yr2NetDebt);
        const yr3ImpliedEquityValue = Number(yr3ImpliedEV) - Number(yr3NetDebt);
        const yr4ImpliedEquityValue = Number(yr4ImpliedEV) - Number(yr4NetDebt);
        // Compute Implied IRR %
        const yr1ImpliedIRRBase = yr1ImpliedEquityValue / Number(newEquity);
        const yr1ImpliedIRRExp = 1 / yrOne;
        const yr1ImpliedIRR =
            (Math.pow(yr1ImpliedIRRBase, yr1ImpliedIRRExp) - 1) * 100;

        const yr2ImpliedIRRBase = yr2ImpliedEquityValue / Number(newEquity);
        const yr2ImpliedIRRExp = 1 / yrTwo;
        const yr2ImpliedIRR =
            (Math.pow(yr2ImpliedIRRBase, yr2ImpliedIRRExp) - 1) * 100;

        const yr3ImpliedIRRBase = yr3ImpliedEquityValue / Number(newEquity);
        const yr3ImpliedIRRExp = 1 / yrThree;
        const yr3ImpliedIRR =
            (Math.pow(yr3ImpliedIRRBase, yr3ImpliedIRRExp) - 1) * 100;

        const yr4ImpliedIRRBase = yr4ImpliedEquityValue / Number(newEquity);
        const yr4ImpliedIRRExp = 1 / yrFour;
        const yr4ImpliedIRR =
            (Math.pow(yr4ImpliedIRRBase, yr4ImpliedIRRExp) - 1) * 100;
        // Compute Implied Multiple of Capital
        const yr1ImpliedMultiple = yr1ImpliedEquityValue / Number(newEquity);
        const yr2ImpliedMultiple = yr2ImpliedEquityValue / Number(newEquity);
        const yr3ImpliedMultiple = yr3ImpliedEquityValue / Number(newEquity);
        const yr4ImpliedMultiple = yr4ImpliedEquityValue / Number(newEquity);
        // Compute Net Debt / EBITDA Multiple
        const yr1NetDebtToEBITDA = Number(yr1NetDebt) / Number(yr1EBITDA);
        const yr2NetDebtToEBITDA = Number(yr2NetDebt) / Number(yr2EBITDA);
        const yr3NetDebtToEBITDA = Number(yr3NetDebt) / Number(yr3EBITDA);
        const yr4NetDebtToEBITDA = Number(yr4NetDebt) / Number(yr4EBITDA);
        // Placeholder

        if (isFinite(yr1ImpliedEV)) {
            const computedYr1ImpliedEV = yr1ImpliedEV.toFixed(0);
            const computedYr2ImpliedEV = yr2ImpliedEV.toFixed(0);
            const computedYr3ImpliedEV = yr3ImpliedEV.toFixed(0);
            const computedYr4ImpliedEV = yr4ImpliedEV.toFixed(0);
            const computedYr1ImpliedEquityValue =
                yr1ImpliedEquityValue.toFixed(0);
            const computedYr2ImpliedEquityValue =
                yr2ImpliedEquityValue.toFixed(0);
            const computedYr3ImpliedEquityValue =
                yr3ImpliedEquityValue.toFixed(0);
            const computedYr4ImpliedEquityValue =
                yr4ImpliedEquityValue.toFixed(0);
            const computedYr1ImpliedIRR = yr1ImpliedIRR.toFixed(2);
            const computedYr2ImpliedIRR = yr2ImpliedIRR.toFixed(2);
            const computedYr3ImpliedIRR = yr3ImpliedIRR.toFixed(2);
            const computedYr4ImpliedIRR = yr4ImpliedIRR.toFixed(2);
            const computedYr1ImpliedMultiple = yr1ImpliedMultiple.toFixed(2);
            const computedYr2ImpliedMultiple = yr2ImpliedMultiple.toFixed(2);
            const computedYr3ImpliedMultiple = yr3ImpliedMultiple.toFixed(2);
            const computedYr4ImpliedMultiple = yr4ImpliedMultiple.toFixed(2);
            const computedYr1NetDebtToEBITDA = yr1NetDebtToEBITDA.toFixed(2);
            const computedYr2NetDebtToEBITDA = yr2NetDebtToEBITDA.toFixed(2);
            const computedYr3NetDebtToEBITDA = yr3NetDebtToEBITDA.toFixed(2);
            const computedYr4NetDebtToEBITDA = yr4NetDebtToEBITDA.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                yr1ImpliedEV: computedYr1ImpliedEV,
                yr2ImpliedEV: computedYr2ImpliedEV,
                yr3ImpliedEV: computedYr3ImpliedEV,
                yr4ImpliedEV: computedYr4ImpliedEV,
                yr1ImpliedEquityValue: computedYr1ImpliedEquityValue,
                yr2ImpliedEquityValue: computedYr2ImpliedEquityValue,
                yr3ImpliedEquityValue: computedYr3ImpliedEquityValue,
                yr4ImpliedEquityValue: computedYr4ImpliedEquityValue,
                yr1ImpliedIRR: computedYr1ImpliedIRR,
                yr2ImpliedIRR: computedYr2ImpliedIRR,
                yr3ImpliedIRR: computedYr3ImpliedIRR,
                yr4ImpliedIRR: computedYr4ImpliedIRR,
                yr1ImpliedMultiple: computedYr1ImpliedMultiple,
                yr2ImpliedMultiple: computedYr2ImpliedMultiple,
                yr3ImpliedMultiple: computedYr3ImpliedMultiple,
                yr4ImpliedMultiple: computedYr4ImpliedMultiple,
                yr1NetDebtToEBITDA: computedYr1NetDebtToEBITDA,
                yr2NetDebtToEBITDA: computedYr2NetDebtToEBITDA,
                yr3NetDebtToEBITDA: computedYr3NetDebtToEBITDA,
                yr4NetDebtToEBITDA: computedYr4NetDebtToEBITDA,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            newEquity: "",
            exitEBITDAMultiple: "",
            yr1EBITDA: "",
            yr2EBITDA: "",
            yr3EBITDA: "",
            yr4EBITDA: "",
            yr1NetDebt: "",
            yr2NetDebt: "",
            yr3NetDebt: "",
            yr4NetDebt: "",
        });

        setResults({
            yr1ImpliedEV: "",
            yr2ImpliedEV: "",
            yr3ImpliedEV: "",
            yr4ImpliedEV: "",
            yr1ImpliedEquityValue: "",
            yr2ImpliedEquityValue: "",
            yr3ImpliedEquityValue: "",
            yr4ImpliedEquityValue: "",
            yr1ImpliedIRR: "",
            yr2ImpliedIRR: "",
            yr3ImpliedIRR: "",
            yr4ImpliedIRR: "",
            yr1ImpliedMultiple: "",
            yr2ImpliedMultiple: "",
            yr3ImpliedMultiple: "",
            yr4ImpliedMultiple: "",
            yr1NetDebtToEBITDA: "",
            yr2NetDebtToEBITDA: "",
            yr3NetDebtToEBITDA: "",
            yr4NetDebtToEBITDA: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Pro Forma LBO IRR Analysis</strong>
                <br />
                <small>(4yr PF Estimate)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*New Equity:</small>
                                <input
                                    type="text"
                                    name="newEquity"
                                    placeholder=""
                                    value={userValues.newEquity}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Exit EBITDA Multiple:</small>
                                <input
                                    type="text"
                                    name="exitEBITDAMultiple"
                                    placeholder=""
                                    value={userValues.exitEBITDAMultiple}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 1 EBITDA:</small>
                                <input
                                    type="text"
                                    name="yr1EBITDA"
                                    placeholder=""
                                    value={userValues.yr1EBITDA}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 2 EBITDA:</small>
                                <input
                                    type="text"
                                    name="yr2EBITDA"
                                    placeholder=""
                                    value={userValues.yr2EBITDA}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 3 EBITDA:</small>
                                <input
                                    type="text"
                                    name="yr3EBITDA"
                                    placeholder=""
                                    value={userValues.yr3EBITDA}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 4 EBITDA:</small>
                                <input
                                    type="text"
                                    name="yr4EBITDA"
                                    placeholder=""
                                    value={userValues.yr4EBITDA}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 1 Net Debt:</small>
                                <input
                                    type="text"
                                    name="yr1NetDebt"
                                    placeholder=""
                                    value={userValues.yr1NetDebt}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 2 Net Debt:</small>
                                <input
                                    type="text"
                                    name="yr2NetDebt"
                                    placeholder=""
                                    value={userValues.yr2NetDebt}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 3 Net Debt:</small>
                                <input
                                    type="text"
                                    name="yr3NetDebt"
                                    placeholder=""
                                    value={userValues.yr3NetDebt}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 4 Net Debt:</small>
                                <input
                                    type="text"
                                    name="yr4NetDebt"
                                    placeholder=""
                                    value={userValues.yr4NetDebt}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6 className="text-center">
                            <table className="calctable">
                                <tr>
                                    <td></td>
                                    <th scope="col">Yr 1</th>
                                    <th scope="col">Yr 2</th>
                                    <th scope="col">Yr 3</th>
                                    <th scope="col">Yr 4</th>
                                </tr>
                                <tr>
                                    <th scope="row">Implied EV</th>
                                    <td>
                                        {results.yr1ImpliedEV
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr2ImpliedEV
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr3ImpliedEV
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr4ImpliedEV
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <small>Less: Net Debt</small>
                                    </th>
                                    <td>
                                        (
                                        {userValues.yr1NetDebt
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        )
                                    </td>
                                    <td>
                                        (
                                        {userValues.yr2NetDebt
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        )
                                    </td>
                                    <td>
                                        (
                                        {userValues.yr3NetDebt
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        )
                                    </td>
                                    <td>
                                        (
                                        {userValues.yr4NetDebt
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        )
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Equity Value</th>
                                    <td>
                                        {results.yr1ImpliedEquityValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr2ImpliedEquityValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr3ImpliedEquityValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.yr4ImpliedEquityValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Implied IRR</th>
                                    <td>{results.yr1ImpliedIRR}%</td>
                                    <td>{results.yr2ImpliedIRR}%</td>
                                    <td>{results.yr3ImpliedIRR}%</td>
                                    <td>{results.yr4ImpliedIRR}%</td>
                                </tr>
                                <tr>
                                    <th scope="row">Implied Multiple</th>
                                    <td>{results.yr1ImpliedMultiple}x</td>
                                    <td>{results.yr2ImpliedMultiple}x</td>
                                    <td>{results.yr3ImpliedMultiple}x</td>
                                    <td>{results.yr4ImpliedMultiple}x</td>
                                </tr>
                            </table>
                        </h6>
                        <p />
                        <h6>
                            <strong>Net Debt / EBITDA(x)</strong>
                        </h6>
                        <div>
                            <label id="label">Year 1:</label>
                            <input
                                type="text"
                                value={
                                    (results.yr1NetDebtToEBITDA * 1).toFixed(
                                        1
                                    ) + "x"
                                }
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Year 2:</label>
                            <input
                                type="text"
                                value={
                                    (results.yr2NetDebtToEBITDA * 1).toFixed(
                                        1
                                    ) + "x"
                                }
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Year 3:</label>
                            <input
                                type="text"
                                value={
                                    (results.yr3NetDebtToEBITDA * 1).toFixed(
                                        1
                                    ) + "x"
                                }
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Year 4:</label>
                            <input
                                type="text"
                                value={
                                    (results.yr4NetDebtToEBITDA * 1).toFixed(
                                        1
                                    ) + "x"
                                }
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default LBOIRR;
