import React, { useState } from "react";
import { Finance } from "financejs";

const Yield = () => {
    // XIRR = 5yr Yield calcs
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        // issueDate: "",
        principal: "",
        closingFee: "",
        cashCoupon: "",
        PIKCoupon: "",
        yearOneAmort: "",
        yearTwoAmort: "",
        yearThreeAmort: "",
        yearFourAmort: "",
        yearFiveAmort: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        yieldOutput: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            // issueDate,
            principal,
            closingFee,
            cashCoupon,
            PIKCoupon,
            yearOneAmort,
            yearTwoAmort,
            yearThreeAmort,
            yearFourAmort,
            yearFiveAmort,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            // !issueDate ||
            !principal ||
            !closingFee ||
            !cashCoupon ||
            !PIKCoupon ||
            !yearOneAmort ||
            !yearTwoAmort ||
            !yearThreeAmort ||
            !yearFourAmort ||
            !yearFiveAmort
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            // isNaN(issueDate) ||
            isNaN(principal) ||
            isNaN(closingFee) ||
            isNaN(cashCoupon) ||
            isNaN(PIKCoupon) ||
            isNaN(yearOneAmort) ||
            isNaN(yearTwoAmort) ||
            isNaN(yearThreeAmort) ||
            isNaN(yearFourAmort) ||
            isNaN(yearFiveAmort)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            // Number(issueDate) <= 0 ||
            Number(principal) <= 0 ||
            Number(closingFee) <= 0 ||
            Number(cashCoupon) <= 0 ||
            Number(PIKCoupon) < 0 ||
            Number(yearOneAmort) < 0 ||
            Number(yearTwoAmort) < 0 ||
            Number(yearThreeAmort) < 0 ||
            Number(yearFourAmort) < 0 ||
            Number(yearFiveAmort) < 0
        ) {
            actualError = "All the values must be a positive number";
        }
        // Validate if the values are wacky
        if (
            Number(closingFee) > 30 ||
            Number(cashCoupon) > 30 ||
            Number(PIKCoupon) > 30 ||
            Number(yearOneAmort) > 20 ||
            Number(yearTwoAmort) > 20 ||
            Number(yearThreeAmort) > 20 ||
            Number(yearFourAmort) > 20 ||
            Number(yearFiveAmort) > 20
        ) {
            actualError =
                "All the values must be realistic - stop screwing around!";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        // issueDate,
        principal,
        closingFee,
        cashCoupon,
        PIKCoupon,
        yearOneAmort,
        yearTwoAmort,
        yearThreeAmort,
        yearFourAmort,
        yearFiveAmort,
    }) => {
        // Convert period to monthly increments
        const periods = 4;
        const monthlyIncrement = 12 / periods;
        // const issueDate = new Date();
        // Convert %'s
        const fees = Number(closingFee) / 100;
        const cashInterest = Number(cashCoupon) / 100;
        const quarterlyCashInterest = cashInterest / periods;
        const PIK = Number(PIKCoupon) / 100;
        const quarterlyPIK = PIK / periods;
        const yr0Amort = Number(yearOneAmort) / 100;
        const yr1Amort = Number(yearTwoAmort) / 100;
        const yr2Amort = Number(yearThreeAmort) / 100;
        const yr3Amort = Number(yearFourAmort) / 100;
        const yr4Amort = Number(yearFiveAmort) / 100;
        // Add months
        function addMonths(date, months) {
            const d = date.getDate();
            date.setMonth(date.getMonth() + months);
            if (date.getDate() !== d) {
                date.setDate(0);
            }
            return date;
        }
        // Compute dates
        const yr0q0 = Date.now();
        const yr0q1 = addMonths(new Date(yr0q0), monthlyIncrement);
        const yr0q2 = addMonths(new Date(yr0q1), monthlyIncrement);
        const yr0q3 = addMonths(new Date(yr0q2), monthlyIncrement);
        const yr1q0 = addMonths(new Date(yr0q3), monthlyIncrement);
        const yr1q1 = addMonths(new Date(yr1q0), monthlyIncrement);
        const yr1q2 = addMonths(new Date(yr1q1), monthlyIncrement);
        const yr1q3 = addMonths(new Date(yr1q2), monthlyIncrement);
        const yr2q0 = addMonths(new Date(yr1q3), monthlyIncrement);
        const yr2q1 = addMonths(new Date(yr2q0), monthlyIncrement);
        const yr2q2 = addMonths(new Date(yr2q1), monthlyIncrement);
        const yr2q3 = addMonths(new Date(yr2q2), monthlyIncrement);
        const yr3q0 = addMonths(new Date(yr2q3), monthlyIncrement);
        const yr3q1 = addMonths(new Date(yr3q0), monthlyIncrement);
        const yr3q2 = addMonths(new Date(yr3q1), monthlyIncrement);
        const yr3q3 = addMonths(new Date(yr3q2), monthlyIncrement);
        const yr4q0 = addMonths(new Date(yr3q3), monthlyIncrement);
        const yr4q1 = addMonths(new Date(yr4q0), monthlyIncrement);
        const yr4q2 = addMonths(new Date(yr4q1), monthlyIncrement);
        const yr4q3 = addMonths(new Date(yr4q2), monthlyIncrement);
        const yr5q0 = addMonths(new Date(yr4q3), monthlyIncrement);
        // Compute Quarterly Amortization
        const yr0QuarterlyAmort = yr0Amort / periods;
        const yr0QuarterlyAmortAmt = Number(principal) * yr0QuarterlyAmort;
        const yr1QuarterlyAmort = yr1Amort / periods;
        const yr1QuarterlyAmortAmt = Number(principal) * yr1QuarterlyAmort;
        const yr2QuarterlyAmort = yr2Amort / periods;
        const yr2QuarterlyAmortAmt = Number(principal) * yr2QuarterlyAmort;
        const yr3QuarterlyAmort = yr3Amort / periods;
        const yr3QuarterlyAmortAmt = Number(principal) * yr3QuarterlyAmort;
        const yr4QuarterlyAmort = yr4Amort / periods;
        const yr4QuarterlyAmortAmt = Number(principal) * yr4QuarterlyAmort;
        // What we need for each period:
        // Outstanding Amount Beginning of Quarter ($mm)
        // Amortization ($mm)
        // Outstanding Amount End of Period ($mm)
        // Interest Payment ($mm)
        // Cash flows ($mm)
        // Year 0 Qtr 0 Cash Flow
        const yr0q0CashFlow = -Number(principal) * (1 - fees);
        // Year 0 Qtr 1 Cash Flow
        const yr0q1BegAmt = Number(principal);
        const yr0q1EndAmt =
            yr0q1BegAmt * (1 + quarterlyPIK) - yr0QuarterlyAmortAmt;
        const yr0q1Interest = yr0q1BegAmt * quarterlyCashInterest;
        const yr0q1CashFlow = yr0QuarterlyAmortAmt + yr0q1Interest;
        // Year 0 Qtr 2 Cash Flow
        const yr0q2BegAmt = yr0q1EndAmt;
        const yr0q2EndAmt =
            yr0q2BegAmt * (1 + quarterlyPIK) - yr0QuarterlyAmortAmt;
        const yr0q2Interest = yr0q2BegAmt * quarterlyCashInterest;
        const yr0q2CashFlow = yr0QuarterlyAmortAmt + yr0q2Interest;
        // Year 0 Qtr 3 Cash Flow
        const yr0q3BegAmt = yr0q2EndAmt;
        const yr0q3EndAmt =
            yr0q3BegAmt * (1 + quarterlyPIK) - yr0QuarterlyAmortAmt;
        const yr0q3Interest = yr0q3BegAmt * quarterlyCashInterest;
        const yr0q3CashFlow = yr0QuarterlyAmortAmt + yr0q3Interest;
        // Year 1 Qtr 0 Cash Flow
        const yr1q0BegAmt = yr0q3EndAmt;
        const yr1q0EndAmt =
            yr1q0BegAmt * (1 + quarterlyPIK) - yr1QuarterlyAmortAmt;
        const yr1q0Interest = yr1q0BegAmt * quarterlyCashInterest;
        const yr1q0CashFlow = yr1QuarterlyAmortAmt + yr1q0Interest;
        // Year 1 Qtr 1 Cash Flow
        const yr1q1BegAmt = yr1q0EndAmt;
        const yr1q1EndAmt =
            yr1q1BegAmt * (1 + quarterlyPIK) - yr1QuarterlyAmortAmt;
        const yr1q1Interest = yr1q1BegAmt * quarterlyCashInterest;
        const yr1q1CashFlow = yr1QuarterlyAmortAmt + yr1q1Interest;
        // Year 1 Qtr 2 Cash Flow
        const yr1q2BegAmt = yr1q1EndAmt;
        const yr1q2EndAmt =
            yr1q2BegAmt * (1 + quarterlyPIK) - yr1QuarterlyAmortAmt;
        const yr1q2Interest = yr1q2BegAmt * quarterlyCashInterest;
        const yr1q2CashFlow = yr1QuarterlyAmortAmt + yr1q2Interest;
        // Year 1 Qtr 3 Cash Flow
        const yr1q3BegAmt = yr1q2EndAmt;
        const yr1q3EndAmt =
            yr1q3BegAmt * (1 + quarterlyPIK) - yr1QuarterlyAmortAmt;
        const yr1q3Interest = yr1q3BegAmt * quarterlyCashInterest;
        const yr1q3CashFlow = yr1QuarterlyAmortAmt + yr1q3Interest;
        // Year 2 Qtr 0 Cash Flow
        const yr2q0BegAmt = yr1q3EndAmt;
        const yr2q0EndAmt =
            yr2q0BegAmt * (1 + quarterlyPIK) - yr2QuarterlyAmortAmt;
        const yr2q0Interest = yr2q0BegAmt * quarterlyCashInterest;
        const yr2q0CashFlow = yr2QuarterlyAmortAmt + yr2q0Interest;
        // Year 2 Qtr 1 Cash Flow
        const yr2q1BegAmt = yr2q0EndAmt;
        const yr2q1EndAmt =
            yr2q1BegAmt * (1 + quarterlyPIK) - yr2QuarterlyAmortAmt;
        const yr2q1Interest = yr2q1BegAmt * quarterlyCashInterest;
        const yr2q1CashFlow = yr2QuarterlyAmortAmt + yr2q1Interest;
        // Year 2 Qtr 2 Cash Flow
        const yr2q2BegAmt = yr2q1EndAmt;
        const yr2q2EndAmt =
            yr2q2BegAmt * (1 + quarterlyPIK) - yr2QuarterlyAmortAmt;
        const yr2q2Interest = yr2q2BegAmt * quarterlyCashInterest;
        const yr2q2CashFlow = yr2QuarterlyAmortAmt + yr2q2Interest;
        // Year 2 Qtr 3 Cash Flow
        const yr2q3BegAmt = yr2q2EndAmt;
        const yr2q3EndAmt =
            yr2q3BegAmt * (1 + quarterlyPIK) - yr2QuarterlyAmortAmt;
        const yr2q3Interest = yr2q3BegAmt * quarterlyCashInterest;
        const yr2q3CashFlow = yr2QuarterlyAmortAmt + yr2q3Interest;
        // Year 3 Qtr 0 Cash Flow
        const yr3q0BegAmt = yr2q3EndAmt;
        const yr3q0EndAmt =
            yr3q0BegAmt * (1 + quarterlyPIK) - yr3QuarterlyAmortAmt;
        const yr3q0Interest = yr3q0BegAmt * quarterlyCashInterest;
        const yr3q0CashFlow = yr3QuarterlyAmortAmt + yr3q0Interest;
        // Year 3 Qtr 1 Cash Flow
        const yr3q1BegAmt = yr3q0EndAmt;
        const yr3q1EndAmt =
            yr3q1BegAmt * (1 + quarterlyPIK) - yr3QuarterlyAmortAmt;
        const yr3q1Interest = yr3q1BegAmt * quarterlyCashInterest;
        const yr3q1CashFlow = yr3QuarterlyAmortAmt + yr3q1Interest;
        // Year 3 Qtr 2 Cash Flow
        const yr3q2BegAmt = yr3q1EndAmt;
        const yr3q2EndAmt =
            yr3q2BegAmt * (1 + quarterlyPIK) - yr3QuarterlyAmortAmt;
        const yr3q2Interest = yr3q2BegAmt * quarterlyCashInterest;
        const yr3q2CashFlow = yr3QuarterlyAmortAmt + yr3q2Interest;
        // Year 3 Qtr 3 Cash Flow
        const yr3q3BegAmt = yr3q2EndAmt;
        const yr3q3EndAmt =
            yr3q3BegAmt * (1 + quarterlyPIK) - yr3QuarterlyAmortAmt;
        const yr3q3Interest = yr3q3BegAmt * quarterlyCashInterest;
        const yr3q3CashFlow = yr3QuarterlyAmortAmt + yr3q3Interest;
        // Year 4 Qtr 0 Cash Flow
        const yr4q0BegAmt = yr3q3EndAmt;
        const yr4q0EndAmt =
            yr4q0BegAmt * (1 + quarterlyPIK) - yr4QuarterlyAmortAmt;
        const yr4q0Interest = yr4q0BegAmt * quarterlyCashInterest;
        const yr4q0CashFlow = yr4QuarterlyAmortAmt + yr4q0Interest;
        // Year 4 Qtr 1 Cash Flow
        const yr4q1BegAmt = yr4q0EndAmt;
        const yr4q1EndAmt =
            yr4q1BegAmt * (1 + quarterlyPIK) - yr4QuarterlyAmortAmt;
        const yr4q1Interest = yr4q1BegAmt * quarterlyCashInterest;
        const yr4q1CashFlow = yr4QuarterlyAmortAmt + yr4q1Interest;
        // Year 4 Qtr 2 Cash Flow
        const yr4q2BegAmt = yr4q1EndAmt;
        const yr4q2EndAmt =
            yr4q2BegAmt * (1 + quarterlyPIK) - yr4QuarterlyAmortAmt;
        const yr4q2Interest = yr4q2BegAmt * quarterlyCashInterest;
        const yr4q2CashFlow = yr4QuarterlyAmortAmt + yr4q2Interest;
        // Year 4 Qtr 3 Cash Flow
        const yr4q3BegAmt = yr4q2EndAmt;
        const yr4q3EndAmt =
            yr4q3BegAmt * (1 + quarterlyPIK) - yr4QuarterlyAmortAmt;
        const yr4q3Interest = yr4q3BegAmt * quarterlyCashInterest;
        const yr4q3CashFlow = yr4QuarterlyAmortAmt + yr4q3Interest;
        // Year 5 Qtr 0 Cash Flow
        const yr5q0BegAmt = yr4q3EndAmt;
        const finalAmort = yr5q0BegAmt;
        // const yr5q0EndAmt = yr5q0BegAmt - finalAmort;
        const yr5q0Interest = yr5q0BegAmt * quarterlyCashInterest;
        const yr5q0CashFlow = finalAmort + yr5q0Interest;
        // Compute XIRR with financejs
        const finance = new Finance();
        const XIRR = finance.XIRR(
            [
                yr0q0CashFlow,
                yr0q1CashFlow,
                yr0q2CashFlow,
                yr0q3CashFlow,
                yr1q0CashFlow,
                yr1q1CashFlow,
                yr1q2CashFlow,
                yr1q3CashFlow,
                yr2q0CashFlow,
                yr2q1CashFlow,
                yr2q2CashFlow,
                yr2q3CashFlow,
                yr3q0CashFlow,
                yr3q1CashFlow,
                yr3q2CashFlow,
                yr3q3CashFlow,
                yr4q0CashFlow,
                yr4q1CashFlow,
                yr4q2CashFlow,
                yr4q3CashFlow,
                yr5q0CashFlow,
            ],
            [
                new Date(yr0q0),
                new Date(yr0q1),
                new Date(yr0q2),
                new Date(yr0q3),
                new Date(yr1q0),
                new Date(yr1q1),
                new Date(yr1q2),
                new Date(yr1q3),
                new Date(yr2q0),
                new Date(yr2q1),
                new Date(yr2q2),
                new Date(yr2q3),
                new Date(yr3q0),
                new Date(yr3q1),
                new Date(yr3q2),
                new Date(yr3q3),
                new Date(yr4q0),
                new Date(yr4q1),
                new Date(yr4q2),
                new Date(yr4q3),
                new Date(yr5q0),
            ],
            0.1
        );
        // Convert XIRR
        const decimalizedXIRR = XIRR / 100;
        const periodsFactor = 1 / periods;
        const baseXIRR = 1 + decimalizedXIRR;
        const convertedXIRR = Math.pow(baseXIRR, periodsFactor);
        // Compute Yield
        const yieldOutput = 4 * (convertedXIRR - 1) * 100;

        if (isFinite(yieldOutput)) {
            const computedYieldOutput = yieldOutput.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                yieldOutput: computedYieldOutput,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            // issueDate: "",
            principal: "",
            closingFee: "",
            cashCoupon: "",
            PIKCoupon: "",
            yearOneAmort: "",
            yearTwoAmort: "",
            yearThreeAmort: "",
            yearFourAmort: "",
            yearFiveAmort: "",
        });

        setResults({
            yieldOutput: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Yield Estimate</strong>
                <br />
                <small>(5yr, Quarterly)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            {/* <div>
                                <small>*Issue Date:</small>
                                <input
                                    type="date"
                                    name="issueDate"
                                    placeholder=""
                                    value={userValues.issueDate}
                                    onChange={handleInputChange}
                                />
                            </div> */}
                            <div>
                                <small>*Principal Amount:</small>
                                <input
                                    type="text"
                                    name="principal"
                                    placeholder=""
                                    value={userValues.principal}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Closing Fee(s) %:</small>
                                <input
                                    type="text"
                                    name="closingFee"
                                    placeholder=""
                                    value={userValues.closingFee}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Cash Coupon %:</small>
                                <input
                                    type="text"
                                    name="cashCoupon"
                                    placeholder=""
                                    value={userValues.cashCoupon}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*PIK Coupon %:</small>
                                <input
                                    type="text"
                                    name="PIKCoupon"
                                    placeholder=""
                                    value={userValues.PIKCoupon}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <strong>Amortization Schedule %</strong>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 1 %:</small>
                                <input
                                    type="text"
                                    name="yearOneAmort"
                                    placeholder=""
                                    value={userValues.yearOneAmort}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 2 %:</small>
                                <input
                                    type="text"
                                    name="yearTwoAmort"
                                    placeholder=""
                                    value={userValues.yearTwoAmort}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 3 %:</small>
                                <input
                                    type="text"
                                    name="yearThreeAmort"
                                    placeholder=""
                                    value={userValues.yearThreeAmort}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Yr 4 %:</small>
                                <input
                                    type="text"
                                    name="yearFourAmort"
                                    placeholder=""
                                    value={userValues.yearFourAmort}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Yr 5 %:</small>
                                <input
                                    type="text"
                                    name="yearFiveAmort"
                                    placeholder=""
                                    value={userValues.yearFiveAmort}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            {/* Issue Date: &nbsp;
                            {userValues.issueDate}
                            <br /> */}
                            Principal: &nbsp; $
                            {userValues.principal
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            Closing Fee(s): &nbsp;
                            {userValues.closingFee}% <br /> Cash Interest:
                            &nbsp;
                            {userValues.cashCoupon}% <br /> PIK Interest: &nbsp;
                            {userValues.PIKCoupon}%
                            <p />
                            <br />
                            Amortization Schedule - &nbsp;<strong>Yr 1</strong>
                            :&nbsp;
                            {userValues.yearOneAmort}% &nbsp;|&nbsp;
                            <strong>Yr 2</strong>:&nbsp;
                            {userValues.yearTwoAmort}% &nbsp;|&nbsp;
                            <strong>Yr 3</strong>:&nbsp;
                            {userValues.yearThreeAmort}% &nbsp;|&nbsp;
                            <strong>Yr 4</strong>:&nbsp;
                            {userValues.yearFourAmort}% &nbsp;|&nbsp;
                            <strong>Yr 5</strong>:&nbsp;
                            {userValues.yearFiveAmort}%
                            <p />
                            <br />
                            <strong>
                                Yield: &nbsp;
                                {results.yieldOutput}%
                            </strong>
                        </h6>
                        <p />
                        <div>
                            <label id="label">Yield Output:</label>
                            <input
                                type="text"
                                value={results.yieldOutput
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default Yield;
