import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const DealWallTurret = () => {
    return (
        <Fragment>
            <h3 className="text-primary">Dealerboard</h3>
            <p>
                <small>
                    <strong>Is your deal worthy of the wall? </strong>First
                    principle: <strong>no MNPI</strong>.&nbsp;Inquiries:
                    i@squawkify.com.
                </small>
            </p>
            <br />
            <p>
                <small>
                    <strong>Deal Channels:</strong>
                </small>
            </p>
            <div className="dash-buttons">
                <Link to="/privatecredit" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Private Credit
                </Link>
                <Link to="/privateequity" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Private Equity
                </Link>
                <Link to="/venturecapital" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Venture Capital
                </Link>
                <Link to="/mergers&acquisitions" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>M&A
                </Link>
                <Link to="/restructuring" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Restructuring
                </Link>
                <Link to="/collateral" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Collateral
                </Link>
                <Link to="/funds" className="btn btn-dark mb-2">
                    <i className="text-primary"></i>Fund
                </Link>
            </div>
            <br />
            <br />
            {/* Channels */}
        </Fragment>
    );
};

export default DealWallTurret;
