import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import {
    getCOPRPESquawk,
    updateCOPRPESquawk,
} from "../../../actions/coprpesquawk";

const initialState = {
    coprpesquawkDate: "",
    coprpesquawkProduct: "",
    coprpesquawkCompany: "",
    coprpesquawkCompanyUrl: "",
    coprpesquawkPRUrl: "",
    coprpesquawkSector: "",
    coprpesquawkSubSector: "",
    coprpesquawkSize: "",
    coprpesquawkStructure: "",
    coprpesquawkLeadInvestor: "",
    coprpesquawkLenderPricing: "",
    coprpesquawkLender: "",
    coprpesquawkSponsor: "",
    coprpesquawkCounsel: "",
    coprpesquawk3PDD: "",
    coprpesquawkEnterpriseValue: "",
    coprpesquawkEVToSales: "",
    coprpesquawkEVToEBIT: "",
    coprpesquawkEVToEBITDA: "",
};

const EditCOPRPESquawkForm = ({
    getCOPRPESquawk,
    updateCOPRPESquawk,
    coprpesquawk: { coprpesquawk },
}) => {
    const [formData, setFormData] = useState({ initialState });
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        coprpesquawkDate,
        coprpesquawkProduct,
        coprpesquawkCompany,
        coprpesquawkCompanyUrl,
        coprpesquawkPRUrl,
        coprpesquawkSector,
        coprpesquawkSubSector,
        coprpesquawkSize,
        coprpesquawkStructure,
        coprpesquawkLeadInvestor,
        coprpesquawkLenderPricing,
        coprpesquawkLender,
        coprpesquawkSponsor,
        coprpesquawkCounsel,
        coprpesquawk3PDD,
        coprpesquawkEnterpriseValue,
        coprpesquawkEVToSales,
        coprpesquawkEVToEBIT,
        coprpesquawkEVToEBITDA,
    } = formData;

    useEffect(() => {
        if (!coprpesquawk) getCOPRPESquawk(id);
        if (coprpesquawk) {
            const updatedFormData = { ...initialState };
            for (const key in coprpesquawk) {
                if (key in updatedFormData)
                    updatedFormData[key] = coprpesquawk[key];
            }
            setFormData(updatedFormData);
        }
    }, [coprpesquawk, getCOPRPESquawk, id]);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprpesquawkSize) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprpesquawkSize)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprpesquawkSize) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Edit Deal</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        updateCOPRPESquawk(coprpesquawk._id, formData);
                        navigate(`/repository`);
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Transaction Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                placeholder=""
                                name="coprpesquawkDate"
                                value={coprpesquawkDate}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprpesquawkCompany"
                                value={coprpesquawkCompany}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Company URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprpesquawkCompanyUrl"
                                value={coprpesquawkCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Deal Category</small>
                            <select
                                name="coprpesquawkProduct"
                                value={coprpesquawkProduct}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="M&A">M&A</option>
                                <option value="LBO">LBO</option>
                                <option value="Restructuring">
                                    Restructuring
                                </option>
                                <option value="Investment">Investment</option>
                                <option value="Exit">Exit</option>
                                <option value="Straw">Straw Man</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sector</small>
                            <select
                                name="coprpesquawkSector"
                                value={coprpesquawkSector}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Communication Services">
                                    Communication Services
                                </option>
                                <option value="Consumer Discretionary">
                                    Consumer Discretionary
                                </option>
                                <option value="Consumer Staples">
                                    Consumer Staples
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Financials">Financials</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Industrials">Industrials</option>
                                <option value="Materials">Materials</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Technology">Technology</option>
                                <option value="Utilities">Utilities</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Sub-sector</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprpesquawkSubSector"
                                value={coprpesquawkSubSector}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Size (e.g. 55000000)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprpesquawkSize"
                                value={coprpesquawkSize}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Lead Investor (or 1 co-lead)</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprpesquawkLeadInvestor"
                                value={coprpesquawkLeadInvestor}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>
                                * Structure (e.g. controlling interest)
                            </small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprpesquawkStructure"
                                value={coprpesquawkStructure}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprpesquawkPRUrl"
                                value={coprpesquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Additional Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Transaction</strong>
                </span>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Enterprise Value ($)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprpesquawkEnterpriseValue"
                                value={coprpesquawkEnterpriseValue}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>EV / LTM Revenue(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprpesquawkEVToSales"
                                value={coprpesquawkEVToSales}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>EV / LTM EBIT(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprpesquawkEVToEBIT"
                                value={coprpesquawkEVToEBIT}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>EV / LTM EBITDA(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprpesquawkEVToEBITDA"
                                value={coprpesquawkEVToEBITDA}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Detail</small>
                    <textarea
                        name="coprpesquawkLenderPricing"
                        cols="30"
                        rows="5"
                        placeholder="Structure, pricing, etc...."
                        value={coprpesquawkLenderPricing}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <hr className="hide-sm" />
                <p />
                <span className="hide-sm">
                    <strong>Participants</strong>
                </span>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Lender(s) (or "NA")</small>
                            <textarea
                                name="coprpesquawkLender"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprpesquawkLender}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Sponsor(s) (or "Non-sponsored")</small>
                            <textarea
                                name="coprpesquawkSponsor"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprpesquawkSponsor}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Legal Counsel</small>
                            <textarea
                                name="coprpesquawkCounsel"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprpesquawkCounsel}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>3rd Party Diligence (or "NA")</small>
                            <textarea
                                name="coprpesquawk3PDD"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprpesquawk3PDD}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Update"
                />
                <Link className="btn btn-light my-1" to="/privateequity">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

EditCOPRPESquawkForm.propTypes = {
    getCOPRPESquawk: PropTypes.func.isRequired,
    updateCOPRPESquawk: PropTypes.func.isRequired,
    coprpesquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprpesquawk: state.coprpesquawk,
});

export default connect(mapStateToProps, {
    getCOPRPESquawk,
    updateCOPRPESquawk,
})(EditCOPRPESquawkForm);
