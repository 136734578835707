import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
    Table,
} from "reactstrap";
import { getCOPRSquawks } from "../../actions/coprsquawk";
import { getCOPRPESquawks } from "../../actions/coprpesquawk";
import { getCOPRVCSquawks } from "../../actions/coprvcsquawk";
import { getCOPRMNASquawks } from "../../actions/coprmnasquawk";
import { getCOPRBKSquawks } from "../../actions/coprbksquawk";
import { getCOPRCollSquawks } from "../../actions/coprcollsquawk";
import { getCOPRFundSquawks } from "../../actions/coprfundsquawk";
import DealRepoTurret from "./DealRepoTurret";
import SquawkActivity from "../layout/SquawkActivity";

const COPRDealRepo = ({
    coprsquawk: { coprsquawks },
    coprpesquawk: { coprpesquawks },
    coprvcsquawk: { coprvcsquawks },
    coprmnasquawk: { coprmnasquawks },
    coprbksquawk: { coprbksquawks },
    coprcollsquawk: { coprcollsquawks },
    coprfundsquawk: { coprfundsquawks },
    getCOPRSquawks,
    getCOPRPESquawks,
    getCOPRVCSquawks,
    getCOPRMNASquawks,
    getCOPRBKSquawks,
    getCOPRCollSquawks,
    getCOPRFundSquawks,
    // getCOPRSquawksRepo,
    // getCOPRPESquawksRepo,
    // getCOPRVCSquawksRepo,
    // getCOPRMNASquawksRepo,
    // getCOPRBKSquawksRepo,
    // getCOPRCollSquawksRepo,
    // getCOPRFundSquawksRepo,
}) => {
    useEffect(() => {
        getCOPRSquawks();
        getCOPRPESquawks();
        getCOPRVCSquawks();
        getCOPRMNASquawks();
        getCOPRBKSquawks();
        getCOPRCollSquawks();
        getCOPRFundSquawks();
        // getCOPRSquawksRepo();
        // getCOPRPESquawksRepo();
        // getCOPRVCSquawksRepo();
        // getCOPRMNASquawksRepo();
        // getCOPRBKSquawksRepo();
        // getCOPRCollSquawksRepo();
        // getCOPRFundSquawksRepo();
    }, [
        getCOPRSquawks,
        getCOPRPESquawks,
        getCOPRVCSquawks,
        getCOPRMNASquawks,
        getCOPRBKSquawks,
        getCOPRCollSquawks,
        getCOPRFundSquawks,
        // getCOPRSquawksRepo,
        // getCOPRPESquawksRepo,
        // getCOPRVCSquawksRepo,
        // getCOPRMNASquawksRepo,
        // getCOPRBKSquawksRepo,
        // getCOPRCollSquawksRepo,
        // getCOPRFundSquawksRepo,
    ]);

    // Credit Squawks (11 S&P sectors)
    const getCreditCommSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditConDSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditConSSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditESectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditFinSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditHSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditIndSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditMatSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditRESectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditTechSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCreditUtilSectorCount = () => {
        return coprsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprsquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // PE Squawks (11 S&P sectors)
    const getPECommSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEConDSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEConSSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEESectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEFinSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEHSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEIndSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEMatSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPERESectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPETechSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getPEUtilSectorCount = () => {
        return coprpesquawks.reduce(
            (sectorCount, obj) =>
                obj.coprpesquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // VC Squawks (11 S&P sectors)
    const getVCCommSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCConDSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCConSSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCESectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCFinSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCHSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCIndSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCMatSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCRESectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCTechSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getVCUtilSectorCount = () => {
        return coprvcsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprvcsquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // M&A Squawks (11 S&P sectors)
    const getMNACommSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAConDSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAConSSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAESectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAFinSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAHSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAIndSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAMatSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNARESectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNATechSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getMNAUtilSectorCount = () => {
        return coprmnasquawks.reduce(
            (sectorCount, obj) =>
                obj.coprmnasquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // Restructuring Squawks (11 S&P sectors)
    const getBKCommSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKConDSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKConSSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKESectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKFinSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKHSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKIndSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKMatSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKRESectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKTechSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getBKUtilSectorCount = () => {
        return coprbksquawks.reduce(
            (sectorCount, obj) =>
                obj.coprbksquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // Collateral Squawks (11 S&P sectors)
    const getCollCommSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Communication Services"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollConDSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Consumer Discretionary"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollConSSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Consumer Staples"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollESectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Energy"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollFinSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Financials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollHSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Healthcare"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollIndSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Industrials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollMatSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Materials"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollRESectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Real Estate"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollTechSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Technology"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getCollUtilSectorCount = () => {
        return coprcollsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprcollsquawkSector === "Utilities"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    // Fund Squawks (7 Fund Strategies)
    const getFundCreditSectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Private Credit"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundPESectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Private Equity"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundVCSectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Venture Capital"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundFoFSectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Fund of Funds"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundSecSectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Secondaries"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundRASectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Real Assets"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };
    const getFundOtherSectorCount = () => {
        return coprfundsquawks.reduce(
            (sectorCount, obj) =>
                obj.coprfundsquawkStrategy === "Other"
                    ? (sectorCount += 1)
                    : sectorCount,
            0
        );
    };

    return (
        <section className="container">
            <DealRepoTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Summary Stats
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link to="/privatecredit" className="btn btn-primary mb-2">
                        <i className="text-primary"></i> Dealerboard
                    </Link>
                </Col>
                <Col></Col>
                <Col>
                    <strong>
                        All Squawks:{" "}
                        {(
                            Number(coprsquawks.length) +
                            Number(coprpesquawks.length) +
                            Number(coprvcsquawks.length) +
                            Number(coprmnasquawks.length) +
                            Number(coprbksquawks.length) +
                            Number(coprcollsquawks.length) +
                            Number(coprfundsquawks.length)
                        )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </strong>
                </Col>
            </Row>
            <p />
            <SquawkActivity />
            <hr />
            <p />
            <div className="text-center">
                <strong>Squawk Activity by Deal Category</strong>
            </div>
            <p />
            <Row md={4}>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Private Credit</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprsquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprsquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditCommSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditCommSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditConDSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditConDSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditConSSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditConSSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCreditESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditESectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditFinSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditFinSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCreditHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditHSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditIndSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditIndSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditMatSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditMatSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditRESectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditRESectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditTechSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditTechSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCreditUtilSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCreditUtilSectorCount()
                                                    ) /
                                                        coprsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/privatecredit`}
                            className="btn btn-primary btn-block"
                            title="Credit Wire"
                        >
                            View Credit Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Private Equity</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprpesquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    {" "}
                                    Total:{" "}
                                    {coprpesquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPECommSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPECommSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEConDSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEConDSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEConSSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEConSSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEESectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEFinSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEFinSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEHSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEIndSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEIndSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEMatSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEMatSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPERESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPERESectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPETechSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPETechSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getPEUtilSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getPEUtilSectorCount()
                                                    ) /
                                                        coprpesquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/privateequity`}
                            className="btn btn-primary btn-block"
                            title="PE Wire"
                        >
                            View PE Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Venture Capital</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprvcsquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprvcsquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCCommSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCCommSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCConDSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCConDSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCConSSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCConSSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCESectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCFinSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCFinSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCHSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCIndSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCIndSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCMatSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCMatSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCRESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCRESectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCTechSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCTechSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getVCUtilSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getVCUtilSectorCount()
                                                    ) /
                                                        coprvcsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/venturecapital`}
                            className="btn btn-primary btn-block"
                            title="VC Wire"
                        >
                            View VC Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>M&A</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprmnasquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprmnasquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNACommSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNACommSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAConDSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAConDSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAConSSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAConSSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAESectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAFinSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAFinSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAHSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAIndSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAIndSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAMatSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAMatSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNARESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNARESectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNATechSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNATechSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getMNAUtilSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getMNAUtilSectorCount()
                                                    ) /
                                                        coprmnasquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/mergers&acquisitions`}
                            className="btn btn-primary btn-block"
                            title="M&A Wire"
                        >
                            View M&A Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Restructuring</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprbksquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprbksquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKCommSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKCommSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKConDSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKConDSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKConSSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKConSSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKESectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKFinSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKFinSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKHSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKIndSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKIndSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKMatSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKMatSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKRESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKRESectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKTechSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKTechSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getBKUtilSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getBKUtilSectorCount()
                                                    ) /
                                                        coprbksquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/restructuring`}
                            className="btn btn-primary btn-block"
                            title="Restructuring Wire"
                        >
                            View Restructuring Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Collateral</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprcollsquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprcollsquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Sector</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Communication Services
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCollCommSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollCommSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>
                                                Consumer Discretionary
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCollConDSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollConDSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Consumer Staples</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCollConSSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollConSSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Energy</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollESectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Financials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollFinSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollFinSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Healthcare</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollHSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollHSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Industrials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollIndSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollIndSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Materials</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollMatSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollMatSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Estate</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getCollRESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollRESectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Technology</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCollTechSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollTechSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Utilities</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getCollUtilSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getCollUtilSectorCount()
                                                    ) /
                                                        coprcollsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/collateral`}
                            className="btn btn-primary btn-block"
                            title="Collateral Wire"
                        >
                            View Collateral Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>Funds</strong>&nbsp;&nbsp; (
                                {(
                                    (Number(coprfundsquawks.length) /
                                        (Number(coprsquawks.length) +
                                            Number(coprpesquawks.length) +
                                            Number(coprvcsquawks.length) +
                                            Number(coprmnasquawks.length) +
                                            Number(coprbksquawks.length) +
                                            Number(coprcollsquawks.length) +
                                            Number(coprfundsquawks.length))) *
                                    100
                                ).toFixed(2)}
                                %)
                            </CardTitle>
                            <CardSubtitle>
                                <strong>
                                    Total:{" "}
                                    {coprfundsquawks.length
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </CardSubtitle>
                            <p />
                            <Table size="sm" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Strategy</strong>
                                        </td>
                                        <td>
                                            <strong>#</strong>
                                        </td>
                                        <td>
                                            <strong>%</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Private Credit</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getFundCreditSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundCreditSectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Private Equity</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getFundPESectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundPESectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Venture Capital</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getFundVCSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundVCSectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Fund of Funds</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getFundFoFSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundFoFSectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Secondaries</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getFundSecSectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundSecSectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Real Assets</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(getFundRASectorCount())
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundRASectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <small>Other</small>
                                        </td>
                                        <td>
                                            <small>
                                                {Number(
                                                    getFundOtherSectorCount()
                                                )
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {(
                                                    (Number(
                                                        getFundOtherSectorCount()
                                                    ) /
                                                        coprfundsquawks.length) *
                                                    100
                                                )
                                                    .toFixed(1)
                                                    .toString()
                                                    .replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        ","
                                                    )}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <CardSubtitle></CardSubtitle>
                            <CardText>
                                <small></small>
                            </CardText>
                        </CardBody>
                        <p className="my-1 text-center">
                            <small></small>
                        </p>
                        <p />
                        <Link
                            to={`/funds`}
                            className="btn btn-primary btn-block"
                            title="Fund Wire"
                        >
                            View Fund Squawks{" "}
                            <span className="comment-count"></span>
                        </Link>
                    </Card>
                </Col>
            </Row>
        </section>
    );
};

COPRDealRepo.propTypes = {
    coprsquawk: PropTypes.object.isRequired,
    getCOPRSquawks: PropTypes.func.isRequired,
    coprpesquawk: PropTypes.object.isRequired,
    getCOPRPESquawks: PropTypes.func.isRequired,
    coprvcsquawk: PropTypes.object.isRequired,
    getCOPRVCSquawks: PropTypes.func.isRequired,
    coprmnasquawk: PropTypes.object.isRequired,
    getCOPRMNASquawks: PropTypes.func.isRequired,
    coprbksquawk: PropTypes.object.isRequired,
    getCOPRBKSquawks: PropTypes.func.isRequired,
    coprcollsquawk: PropTypes.object.isRequired,
    getCOPRCollSquawks: PropTypes.func.isRequired,
    coprfundsquawk: PropTypes.object.isRequired,
    getCOPRFundSquawks: PropTypes.func.isRequired,
    // getCOPRSquawksRepo: PropTypes.func.isRequired,
    // getCOPRPESquawksRepo: PropTypes.func.isRequired,
    // getCOPRVCSquawksRepo: PropTypes.func.isRequired,
    // getCOPRMNASquawksRepo: PropTypes.func.isRequired,
    // getCOPRBKSquawksRepo: PropTypes.func.isRequired,
    // getCOPRCollSquawksRepo: PropTypes.func.isRequired,
    // getCOPRFundSquawksRepo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    coprsquawk: state.coprsquawk,
    coprpesquawk: state.coprpesquawk,
    coprvcsquawk: state.coprvcsquawk,
    coprmnasquawk: state.coprmnasquawk,
    coprbksquawk: state.coprbksquawk,
    coprcollsquawk: state.coprcollsquawk,
    coprfundsquawk: state.coprfundsquawk,
});

export default connect(mapStateToProps, {
    getCOPRSquawks,
    getCOPRPESquawks,
    getCOPRVCSquawks,
    getCOPRMNASquawks,
    getCOPRBKSquawks,
    getCOPRCollSquawks,
    getCOPRFundSquawks,
    // getCOPRSquawksRepo,
    // getCOPRPESquawksRepo,
    // getCOPRVCSquawksRepo,
    // getCOPRMNASquawksRepo,
    // getCOPRBKSquawksRepo,
    // getCOPRCollSquawksRepo,
    // getCOPRFundSquawksRepo,
})(COPRDealRepo);
