import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { addCOPRMNASquawk } from "../../../actions/coprmnasquawk";

const COPRMNASquawkForm = ({ addCOPRMNASquawk }) => {
    const navigate = useNavigate();
    const routePath = useLocation();
    const NavToPageTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        NavToPageTop();
    }, [routePath]);

    const [formData, setFormData] = useState({
        coprmnasquawkDate: "",
        coprmnasquawkBuyer: "",
        coprmnasquawkBuyerCompanyUrl: "",
        coprmnasquawkAction: "",
        coprmnasquawkTarget: "",
        coprmnasquawkTargetCompanyUrl: "",
        coprmnasquawkPRUrl: "",
        coprmnasquawkSector: "",
        coprmnasquawkSubSector: "",
        coprmnasquawkSize: "",
        coprmnasquawkStructure: "",
        coprmnasquawkEV: "",
        coprmnasquawkAdvisor: "",
        coprmnasquawkLender: "",
        coprmnasquawkCounsel: "",
        coprmnasquawk3PDD: "",
        coprmnasquawkEVToLTMSales: "",
        coprmnasquawkEVToNTMSales: "",
        coprmnasquawkEVToLTMEBIT: "",
        coprmnasquawkEVToNTMEBIT: "",
        coprmnasquawkEVToLTMEBITDA: "",
        coprmnasquawkEVToNTMEBITDA: "",
        coprmnasquawkNotes: "",
        coprmnasquawkTxnType: "",
        // coprmnasquawkStatus: "",
        // coprmnasquawkMarket: "",
        // coprmnasquawkTicker: "",
        // coprmnasquawkExchange: "",
    });

    const {
        coprmnasquawkDate,
        coprmnasquawkBuyer,
        coprmnasquawkBuyerCompanyUrl,
        coprmnasquawkAction,
        coprmnasquawkTarget,
        coprmnasquawkTargetCompanyUrl,
        coprmnasquawkPRUrl,
        coprmnasquawkSector,
        coprmnasquawkSubSector,
        coprmnasquawkSize,
        coprmnasquawkStructure,
        coprmnasquawkEV,
        coprmnasquawkAdvisor,
        coprmnasquawkLender,
        coprmnasquawkCounsel,
        coprmnasquawk3PDD,
        coprmnasquawkEVToLTMSales,
        coprmnasquawkEVToNTMSales,
        coprmnasquawkEVToLTMEBIT,
        coprmnasquawkEVToNTMEBIT,
        coprmnasquawkEVToLTMEBITDA,
        coprmnasquawkEVToNTMEBITDA,
        coprmnasquawkNotes,
        coprmnasquawkTxnType,
        // coprmnasquawkStatus,
        // coprmnasquawkMarket,
        // coprmnasquawkTicker,
        // coprmnasquawkExchange,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprmnasquawkSize) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprmnasquawkSize)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprmnasquawkSize) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Add Transaction</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        addCOPRMNASquawk(formData);
                        navigate(`/mergers&acquisitions`);
                        NavToPageTop();
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Transaction Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                placeholder=""
                                name="coprmnasquawkDate"
                                value={coprmnasquawkDate}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Target</small>
                            <input
                                type="text"
                                maxLength="30"
                                placeholder=""
                                name="coprmnasquawkTarget"
                                value={coprmnasquawkTarget}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Target URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprmnasquawkTargetCompanyUrl"
                                value={coprmnasquawkTargetCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Action</small>
                            <select
                                name="coprmnasquawkAction"
                                value={coprmnasquawkAction}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="acquired by">acquired by</option>
                                <option value="merged with">merged with</option>
                            </select>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Buyer / Acquiror</small>
                            <input
                                type="text"
                                maxLength="32"
                                placeholder=""
                                name="coprmnasquawkBuyer"
                                value={coprmnasquawkBuyer}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Buyer URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprmnasquawkBuyerCompanyUrl"
                                value={coprmnasquawkBuyerCompanyUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group">
                            <small>* Sector</small>
                            <select
                                name="coprmnasquawkSector"
                                value={coprmnasquawkSector}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Communication Services">
                                    Communication Services
                                </option>
                                <option value="Consumer Discretionary">
                                    Consumer Discretionary
                                </option>
                                <option value="Consumer Staples">
                                    Consumer Staples
                                </option>
                                <option value="Energy">Energy</option>
                                <option value="Financials">Financials</option>
                                <option value="Healthcare">Healthcare</option>
                                <option value="Industrials">Industrials</option>
                                <option value="Materials">Materials</option>
                                <option value="Real Estate">Real Estate</option>
                                <option value="Technology">Technology</option>
                                <option value="Utilities">Utilities</option>
                            </select>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group">
                            <small>* Sub-sector</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprmnasquawkSubSector"
                                value={coprmnasquawkSubSector}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group">
                            <small>* Purchase Price / Equity Value $</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprmnasquawkSize"
                                value={coprmnasquawkSize}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprmnasquawkPRUrl"
                                value={coprmnasquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Additional Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Transaction</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Transaction Type</small>
                            <select
                                name="coprmnasquawkTxnType"
                                value={coprmnasquawkTxnType}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="na">Select</option>
                                <option value="Stock">Stock Purchase</option>
                                <option value="Asset">Asset Purchase</option>
                                <option value="Mixed">Mixed</option>
                                <option value="Straw">Straw Man</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Enterprise Value</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEV"
                                value={coprmnasquawkEV}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Transaction Structure</small>
                    <textarea
                        name="coprmnasquawkStructure"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprmnasquawkStructure}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / LTM Sales(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToLTMSales"
                                value={coprmnasquawkEVToLTMSales}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / NTM Sales(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToNTMSales"
                                value={coprmnasquawkEVToNTMSales}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / LTM EBIT(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToLTMEBIT"
                                value={coprmnasquawkEVToLTMEBIT}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / NTM EBIT(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToNTMEBIT"
                                value={coprmnasquawkEVToNTMEBIT}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / LTM EBITDA(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToLTMEBITDA"
                                value={coprmnasquawkEVToLTMEBITDA}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>EV / NTM EBITDA(x)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprmnasquawkEVToNTMEBITDA"
                                value={coprmnasquawkEVToNTMEBITDA}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Transaction Notes</small>
                    <textarea
                        name="coprmnasquawkNotes"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprmnasquawkNotes}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <hr className="hide-sm" />
                <p />
                <span className="hide-sm">
                    <strong>Participants</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Advisor(s) (or "NA")</small>
                            <textarea
                                name="coprmnasquawkAdvisor"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprmnasquawkAdvisor}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Lender(s)</small>
                            <textarea
                                name="coprmnasquawkLender"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprmnasquawkLender}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Legal Counsel</small>
                            <textarea
                                name="coprmnasquawkCounsel"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprmnasquawkCounsel}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>3rd Party Diligence (or "NA")</small>
                            <textarea
                                name="coprmnasquawk3PDD"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprmnasquawk3PDD}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
                <Link className="btn btn-light my-1" to="/mergers&acquisitions">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

COPRMNASquawkForm.propTypes = {
    addCOPRMNASquawk: PropTypes.func.isRequired,
};

export default connect(null, { addCOPRMNASquawk })(COPRMNASquawkForm);
