import {
    GET_JOBSQUAWKS,
    GET_JOBSQUAWK,
    JOBSQUAWK_ERROR,
    UPDATE_JOBLIKES,
    DELETE_JOBSQUAWK,
    ADD_JOBSQUAWK,
    ADD_JOBSQUAWK_COMMENT,
    REMOVE_JOBSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    jobsquawks: [],
    jobsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_JOBSQUAWKS:
            return {
                ...state,
                jobsquawks: payload,
                loading: false,
            };
        case GET_JOBSQUAWK:
            return {
                ...state,
                jobsquawk: payload,
                loading: false,
            };
        case ADD_JOBSQUAWK:
            return {
                ...state,
                jobsquawks: [payload, ...state.jobsquawks],
                loading: false,
            };
        case DELETE_JOBSQUAWK:
            return {
                ...state,
                jobsquawks: state.jobsquawks.filter(
                    (jobsquawk) => jobsquawk._id !== payload
                ),
                loading: false,
            };
        case JOBSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_JOBLIKES:
            return {
                ...state,
                jobsquawks: state.jobsquawks.map((jobsquawk) =>
                    jobsquawk._id === payload.id
                        ? { ...jobsquawk, likes: payload.likes }
                        : jobsquawk
                ),
                loading: false,
            };
        case ADD_JOBSQUAWK_COMMENT:
            return {
                ...state,
                jobsquawk: { ...state.jobsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_JOBSQUAWK_COMMENT:
            return {
                ...state,
                jobsquawk: {
                    ...state.jobsquawk,
                    comments: state.jobsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
