import React, { useState } from "react";

const AssetLiquidation = () => {
    // ABL collateral liquidation or exit scenario
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        grossAR: "",
        arRecoveryPercent: "",
        grossInv: "",
        invRecoveryPercent: "",
        grossOther: "",
        otherRecoveryPercent: "",
        liquidationCost: "",
        maxFacility: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        arRecoveryValuation: "",
        invRecoveryValuation: "",
        workingCapitalValuation: "",
        totalCollateralRecoveryValue: "",
        netCollateralRecovery: "",
        excessCollateral: "",
        collateralCoverage: "",
        loanToValue: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            grossAR,
            arRecoveryPercent,
            grossInv,
            invRecoveryPercent,
            grossOther,
            otherRecoveryPercent,
            liquidationCost,
            maxFacility,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !grossAR ||
            !arRecoveryPercent ||
            !grossInv ||
            !invRecoveryPercent ||
            !grossOther ||
            !otherRecoveryPercent ||
            !liquidationCost ||
            !maxFacility
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(grossAR) ||
            isNaN(arRecoveryPercent) ||
            isNaN(grossInv) ||
            isNaN(invRecoveryPercent) ||
            isNaN(grossOther) ||
            isNaN(otherRecoveryPercent) ||
            isNaN(liquidationCost) ||
            isNaN(maxFacility)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(grossAR) <= 0 ||
            Number(arRecoveryPercent) <= 0 ||
            Number(grossInv) <= 0 ||
            Number(invRecoveryPercent) <= 0 ||
            Number(grossOther) <= 0 ||
            Number(otherRecoveryPercent) <= 0 ||
            Number(liquidationCost) <= 0 ||
            Number(maxFacility) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        grossAR,
        arRecoveryPercent,
        grossInv,
        invRecoveryPercent,
        grossOther,
        otherRecoveryPercent,
        liquidationCost,
        maxFacility,
    }) => {
        // Apply recovery rates to A/R and Inventory
        const arRecoveryValuation =
            Number(grossAR) * (Number(arRecoveryPercent) / 100);
        const invRecoveryValuation =
            Number(grossInv) * (Number(invRecoveryPercent) / 100);
        // Add to compute total working capital value
        const workingCapitalValuation =
            Number(arRecoveryValuation) + Number(invRecoveryValuation);
        // Add any other assets adjusted by recovery rate
        const otherRecoveryValuation =
            Number(grossOther) * (Number(otherRecoveryPercent) / 100);
        // Sum WC + Other recovery values
        const totalCollateralRecoveryValue =
            Number(workingCapitalValuation) + Number(otherRecoveryValuation);
        // Subtract liquidation costs for net value
        const netCollateralRecovery =
            Number(totalCollateralRecoveryValue) - Number(liquidationCost);
        // Compare net collateral to the fully funded facility
        const excessCollateral =
            Number(netCollateralRecovery) - Number(maxFacility);
        // Compute asset coverage ratio
        const collateralCoverage =
            Number(netCollateralRecovery) / Number(maxFacility);
        // Compute loan-to-value on a fully funded basis
        const loanToValue =
            (Number(maxFacility) / Number(netCollateralRecovery)) * 100;

        if (isFinite(excessCollateral)) {
            const computedExcessCollateral = excessCollateral.toFixed(2);
            const computedCollateralCoverage = collateralCoverage.toFixed(2);
            const computedLoanToValue = loanToValue.toFixed(2);
            const computedARRecoveryValuation = arRecoveryValuation.toFixed(2);
            const computedInvRecoveryValuation = invRecoveryValuation.toFixed(
                2
            );
            const computedWorkingCapitalValuation = workingCapitalValuation.toFixed(
                2
            );
            const computedTotalCollateralRecoveryValue = totalCollateralRecoveryValue.toFixed(
                2
            );
            const computedNetCollateralRecovery = netCollateralRecovery.toFixed(
                2
            );

            // Set up results to the state to be displayed to the user
            setResults({
                excessCollateral: computedExcessCollateral,
                collateralCoverage: computedCollateralCoverage,
                loanToValue: computedLoanToValue,
                arRecoveryValuation: computedARRecoveryValuation,
                invRecoveryValuation: computedInvRecoveryValuation,
                workingCapitalValuation: computedWorkingCapitalValuation,
                totalCollateralRecoveryValue: computedTotalCollateralRecoveryValue,
                netCollateralRecovery: computedNetCollateralRecovery,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            grossAR: "",
            arRecoveryPercent: "",
            grossInv: "",
            invRecoveryPercent: "",
            grossOther: "",
            otherRecoveryPercent: "",
            liquidationCost: "",
            maxFacility: "",
        });

        setResults({
            arRecoveryValuation: "",
            invRecoveryValuation: "",
            workingCapitalValuation: "",
            totalCollateralRecoveryValue: "",
            netCollateralRecovery: "",
            excessCollateral: "",
            collateralCoverage: "",
            loanToValue: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Asset / Collateral Liquidation Analysis</strong>
                <br />
                <small>(Basic ABL Exit Strategy)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Credit Facility:</small>
                                <input
                                    type="text"
                                    name="maxFacility"
                                    placeholder=""
                                    value={userValues.maxFacility}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Liquidation Cost:</small>
                                <input
                                    type="text"
                                    name="liquidationCost"
                                    placeholder=""
                                    value={userValues.liquidationCost}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Gross AR:</small>
                                <input
                                    type="text"
                                    name="grossAR"
                                    placeholder=""
                                    value={userValues.grossAR}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*AR Recovery %:</small>
                                <input
                                    type="text"
                                    name="arRecoveryPercent"
                                    placeholder=""
                                    value={userValues.arRecoveryPercent}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Gross Inv:</small>
                                <input
                                    type="text"
                                    name="grossInv"
                                    placeholder=""
                                    value={userValues.grossInv}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Inv Recovery %:</small>
                                <input
                                    type="text"
                                    name="invRecoveryPercent"
                                    placeholder=""
                                    value={userValues.invRecoveryPercent}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Other Assets:</small>
                                <input
                                    type="text"
                                    name="grossOther"
                                    placeholder=""
                                    value={userValues.grossOther}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Other Recovery %:</small>
                                <input
                                    type="text"
                                    name="otherRecoveryPercent"
                                    placeholder=""
                                    value={userValues.otherRecoveryPercent}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            A/R recovery value: &nbsp;
                            {results.arRecoveryValuation
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            Inventory recovery value: &nbsp;
                            {results.invRecoveryValuation
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            Working capital valuation: &nbsp;
                            {results.workingCapitalValuation
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            Collateral Coverage(x): &nbsp;
                            {results.collateralCoverage}x&nbsp;&nbsp;
                            Loan-to-Value (LTV): &nbsp;
                            {results.loanToValue}%
                        </h6>
                        <p />
                        <div>
                            <label id="label">
                                Total Collateral Recovery Value:
                            </label>
                            <input
                                type="text"
                                value={results.totalCollateralRecoveryValue
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Net Collateral Value:</label>
                            <input
                                type="text"
                                value={results.netCollateralRecovery
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Excess Collateral:</label>
                            <input
                                type="text"
                                value={results.excessCollateral
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AssetLiquidation;
