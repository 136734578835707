import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
} from "reactstrap";
import formatDate from "../../utils/formatDate";
import { deleteTombstone } from "../../actions/profile";

const Tombstone = ({ tombstone, deleteTombstone }) => {
    const tombstones = tombstone.map((tombstone) => (
        <div key={tombstone._id}>
            <Row>
                <Col Col xs={12}>
                    <Card className="mb-2 mr-2">
                        <CardBody className="text-center">
                            <CardTitle>
                                <strong>{tombstone.client}</strong>
                            </CardTitle>
                            <CardSubtitle>{tombstone.amount}</CardSubtitle>
                            <CardText>{tombstone.category}</CardText>
                            <CardText className="hide-sm">
                                <small>{tombstone.firm}</small>
                            </CardText>
                            <CardText className="hide-sm">
                                <small>Title: {tombstone.role}</small>
                            </CardText>
                            <CardText>
                                <small>
                                    Closed: {formatDate(tombstone.when)}
                                </small>
                            </CardText>
                            {/* <p className="hide-sm">
                            <small>Cred: {tombstone.contribution}</small>
                        </p>
                        <p className="hide-sm">
                            <small>Notes: {tombstone.note}</small>
                        </p> */}
                            <div className="card-footer bg-transparent">
                                <button
                                    onClick={() =>
                                        deleteTombstone(tombstone._id)
                                    }
                                    className="btn btn-danger btn-block"
                                >
                                    Delete
                                </button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    ));

    return (
        <Fragment>
            <h2 className="my-2">Tombstones</h2>
            <div className="row row-cols-1 row-cols-md-3">{tombstones}</div>
        </Fragment>
    );
};

Tombstone.propTypes = {
    tombstone: PropTypes.array.isRequired,
    deleteTombstone: PropTypes.func.isRequired,
};

export default connect(null, { deleteTombstone })(Tombstone);
