import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { addCOPRFundSquawk } from "../../../actions/coprfundsquawk";

const COPRFundSquawkForm = ({ addCOPRFundSquawk }) => {
    const navigate = useNavigate();
    const routePath = useLocation();
    const NavToPageTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        NavToPageTop();
    }, [routePath]);

    const [formData, setFormData] = useState({
        coprfundsquawkFirmName: "",
        coprfundsquawkFirmUrl: "",
        coprfundsquawkPRUrl: "",
        coprfundsquawkFirmLocation: "",
        coprfundsquawkName: "",
        coprfundsquawkStrategy: "",
        coprfundsquawkSize: "",
        coprfundsquawkManagementFee: "",
        coprfundsquawkPerformanceFee: "",
        coprfundsquawkLp: "",
        coprfundsquawkGp: "",
        coprfundsquawkVintage: "",
        coprfundsquawkLife: "",
        coprfundsquawkLifeStage: "",
        coprfundsquawkCapitalBase: "",
        coprfundsquawkGrossIRR: "",
        coprfundsquawkNetIRR: "",
        coprfundsquawkContributions: "",
        coprfundsquawkDistributions: "",
        coprfundsquawkNetCashFlow: "",
        coprfundsquawkNotes: "",
        coprfundsquawkDate: "",
    });

    const {
        coprfundsquawkFirmName,
        coprfundsquawkFirmUrl,
        coprfundsquawkPRUrl,
        coprfundsquawkFirmLocation,
        coprfundsquawkName,
        coprfundsquawkStrategy,
        coprfundsquawkSize,
        coprfundsquawkManagementFee,
        coprfundsquawkPerformanceFee,
        coprfundsquawkLp,
        coprfundsquawkGp,
        coprfundsquawkVintage,
        coprfundsquawkLife,
        coprfundsquawkLifeStage,
        coprfundsquawkCapitalBase,
        coprfundsquawkGrossIRR,
        coprfundsquawkNetIRR,
        coprfundsquawkContributions,
        coprfundsquawkDistributions,
        coprfundsquawkNetCashFlow,
        coprfundsquawkNotes,
        coprfundsquawkDate,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprfundsquawkSize) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprfundsquawkSize)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprfundsquawkSize) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Add Fund</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        addCOPRFundSquawk(formData);
                        navigate(`/funds`);
                        NavToPageTop();
                    }
                }}
            >
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Firm Name</small>
                            <input
                                type="text"
                                maxLength="32"
                                placeholder=""
                                name="coprfundsquawkFirmName"
                                value={coprfundsquawkFirmName}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Firm URL (https://)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprfundsquawkFirmUrl"
                                value={coprfundsquawkFirmUrl}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Firm HQ</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprfundsquawkFirmLocation"
                                value={coprfundsquawkFirmLocation}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Fund Name (e.g. Fund IV)</small>
                            <input
                                type="text"
                                maxLength="36"
                                placeholder=""
                                name="coprfundsquawkName"
                                value={coprfundsquawkName}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Fund Size ($)</small>
                            <input
                                type="text"
                                maxLength="48"
                                placeholder=""
                                name="coprfundsquawkSize"
                                value={coprfundsquawkSize}
                                onChange={(e) => onChange(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group">
                            <small>* Fund Strategy</small>
                            <select
                                name="coprfundsquawkStrategy"
                                value={coprfundsquawkStrategy}
                                onChange={(e) => onChange(e)}
                                required
                            >
                                <option value="0">Select</option>
                                <option value="Venture Capital">
                                    Venture Capital
                                </option>
                                <option value="Private Equity">
                                    Private Equity
                                </option>
                                <option value="Private Credit">
                                    Private Credit
                                </option>
                                <option value="Real Assets">Real Assets</option>
                                <option value="Fund of Funds">
                                    Fund of Funds
                                </option>
                                <option value="Secondaries">Secondaries</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Press Release URL (https://)</small>
                            <input
                                type="text"
                                placeholder=""
                                name="coprfundsquawkPRUrl"
                                value={coprfundsquawkPRUrl}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund Close Date</small>
                            <input
                                type="date"
                                maxLength="48"
                                name="coprfundsquawkDate"
                                value={coprfundsquawkDate}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Additional Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Fund Overview</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund LP(s)</small>
                            <textarea
                                name="coprfundsquawkLp"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprfundsquawkLp}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund GP(s)</small>
                            <textarea
                                name="coprfundsquawkGp"
                                cols="30"
                                rows="5"
                                placeholder=""
                                value={coprfundsquawkGp}
                                onChange={(e) => onChange(e)}
                            ></textarea>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund Vintage (e.g. 2021)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkVintage"
                                value={coprfundsquawkVintage}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund Life (e.g. 10 years)</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkLife"
                                value={coprfundsquawkLife}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Management Fee %</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkManagementFee"
                                value={coprfundsquawkManagementFee}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className="form-group hide-sm">
                            <small>Carried Interest / Performance Fee %</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkPerformanceFee"
                                value={coprfundsquawkPerformanceFee}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Capital Base</small>
                            <select
                                name="coprfundsquawkCapitalBase"
                                value={coprfundsquawkCapitalBase}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="0">Select</option>
                                <option value="Committed">Committed</option>
                                <option value="Invested">Invested</option>
                            </select>
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Fund Stage</small>
                            <select
                                name="coprfundsquawkLifeStage"
                                value={coprfundsquawkLifeStage}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="0">Select</option>
                                <option value="Investment">Investment</option>
                                <option value="Realization">Realization</option>
                                <option value="Extension">Extension</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Gross IRR</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkGrossIRR"
                                value={coprfundsquawkGrossIRR}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Net IRR</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "30em" }}
                                placeholder=""
                                name="coprfundsquawkNetIRR"
                                value={coprfundsquawkNetIRR}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <Row md={4}>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Contributions</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprfundsquawkContributions"
                                value={coprfundsquawkContributions}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Distributions</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprfundsquawkDistributions"
                                value={coprfundsquawkDistributions}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <div className="form-group hide-sm">
                            <small>Net Cash Flow</small>
                            <br />
                            <input
                                type="number"
                                maxLength="48"
                                style={{ width: "20em" }}
                                placeholder=""
                                name="coprfundsquawkNetCashFlow"
                                value={coprfundsquawkNetCashFlow}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="form-group hide-sm">
                    <small>Fund Notes</small>
                    <textarea
                        name="coprfundsquawkNotes"
                        cols="30"
                        rows="5"
                        placeholder=""
                        value={coprfundsquawkNotes}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
                <Link className="btn btn-light my-1" to="/funds">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

COPRFundSquawkForm.propTypes = {
    addCOPRFundSquawk: PropTypes.func.isRequired,
};

export default connect(null, { addCOPRFundSquawk })(COPRFundSquawkForm);
