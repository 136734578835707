import React, { useState } from "react";
import { Row, Table } from "reactstrap";

const RR = () => {
    // Software RR harvest scenario
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        ltmRevenue: "",
        churn: "",
        costOfRevenuePercent: "",
        fixedExpensePercentOfRevenue: "",
        gaPercentOfRevenue: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        renewalRate: "",
        yrOneRevenue: "",
        yrTwoRevenue: "",
        yrThreeRevenue: "",
        yrFourRevenue: "",
        yrFiveRevenue: "",
        yrSixRevenue: "",
        baseYrCostOfRevenue: "",
        yrOneCostOfRevenue: "",
        yrTwoCostOfRevenue: "",
        yrThreeCostOfRevenue: "",
        yrFourCostOfRevenue: "",
        yrFiveCostOfRevenue: "",
        yrSixCostOfRevenue: "",
        // baseYrGrossMargin: "",
        // yrOneGrossMargin: "",
        // yrTwoGrossMargin: "",
        // yrThreeGrossMargin: "",
        // yrFourGrossMargin: "",
        // yrFiveGrossMargin: "",
        // yrSixGrossMargin: "",
        // baseYrOpIncome: "",
        // yrOneOpIncome: "",
        // yrTwoOpIncome: "",
        // yrThreeOpIncome: "",
        // yrFourOpIncome: "",
        // yrFiveOpIncome: "",
        // yrSixOpIncome: "",
        baseYrNetIncome: "",
        yrOneNetIncome: "",
        yrTwoNetIncome: "",
        yrThreeNetIncome: "",
        yrFourNetIncome: "",
        yrFiveNetIncome: "",
        yrSixNetIncome: "",
        baseYrMidpointOfCashFlow: "",
        yrOneMidpointOfCashFlow: "",
        yrTwoMidpointOfCashFlow: "",
        yrThreeMidpointOfCashFlow: "",
        yrFourMidpointOfCashFlow: "",
        yrFiveMidpointOfCashFlow: "",
        yrSixMidpointOfCashFlow: "",
        baseYrDiscountFactor: "",
        yrOneDiscountFactor: "",
        yrTwoDiscountFactor: "",
        yrThreeDiscountFactor: "",
        yrFourDiscountFactor: "",
        yrFiveDiscountFactor: "",
        yrSixDiscountFactor: "",
        netValueOfRecurringRevenue: "",
        // netValueOfRecurringRevenuePlus: "",
        // netValueOfRecurringRevenueMinus: "",
        // leverageMultiple: "",
        // leverageMultiplePlus: "",
        // leverageMultipleMinus: "",
        isResult: false,
    });

    // Multi-step Page
    const [page, setPage] = useState(1);

    const goNextPage = () => {
        if (page === 5) return;
        setPage((page) => page + 1);
    };

    const goBack = () => {
        setPage((page) => page - 1);
    };

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            ltmRevenue,
            churn,
            costOfRevenuePercent,
            fixedExpensePercentOfRevenue,
            gaPercentOfRevenue,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !ltmRevenue ||
            !churn ||
            !costOfRevenuePercent ||
            !fixedExpensePercentOfRevenue ||
            !gaPercentOfRevenue
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(ltmRevenue) ||
            isNaN(churn) ||
            isNaN(costOfRevenuePercent) ||
            isNaN(fixedExpensePercentOfRevenue) ||
            isNaN(gaPercentOfRevenue)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(ltmRevenue) <= 0 ||
            Number(churn) <= 0 ||
            Number(costOfRevenuePercent) <= 0 ||
            Number(fixedExpensePercentOfRevenue) <= 0 ||
            Number(gaPercentOfRevenue) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        ltmRevenue,
        churn,
        costOfRevenuePercent,
        fixedExpensePercentOfRevenue,
        gaPercentOfRevenue,
    }) => {
        const taxes = 20;
        const discountFactor = 20;
        const renewalRate = 1 - Number(churn) / 100;
        // Revenue projections
        const yrOneRevenue = Number(ltmRevenue) * renewalRate;
        const yrTwoRevenue = Number(yrOneRevenue) * renewalRate;
        const yrThreeRevenue = Number(yrTwoRevenue) * renewalRate;
        const yrFourRevenue = Number(yrThreeRevenue) * renewalRate;
        const yrFiveRevenue = Number(yrFourRevenue) * renewalRate;
        const yrSixRevenue = Number(yrFiveRevenue) * renewalRate;
        // Cost of revenue projections
        const costOfRevs = Number(costOfRevenuePercent) / 100;
        const baseYrCostOfRevenue = Number(ltmRevenue) * costOfRevs;
        const yrOneCostOfRevenue = Number(yrOneRevenue) * costOfRevs;
        const yrTwoCostOfRevenue = Number(yrTwoRevenue) * costOfRevs;
        const yrThreeCostOfRevenue = Number(yrThreeRevenue) * costOfRevs;
        const yrFourCostOfRevenue = Number(yrFourRevenue) * costOfRevs;
        const yrFiveCostOfRevenue = Number(yrFiveRevenue) * costOfRevs;
        const yrSixCostOfRevenue = Number(yrSixRevenue) * costOfRevs;
        // Net Income
        const FE = fixedExpensePercentOfRevenue / 100;
        const GA = gaPercentOfRevenue / 100;
        const tax = 1 - taxes / 100;
        const baseYrNetIncome =
            tax *
            (Number(ltmRevenue) -
                Number(baseYrCostOfRevenue) -
                Number(ltmRevenue) * FE -
                Number(ltmRevenue) * GA);
        const yrOneNetIncome =
            tax *
            (Number(yrOneRevenue) -
                Number(yrOneCostOfRevenue) -
                Number(yrOneRevenue) * FE -
                Number(yrOneRevenue) * GA);
        const yrTwoNetIncome =
            tax *
            (Number(yrTwoRevenue) -
                Number(yrTwoCostOfRevenue) -
                Number(yrTwoRevenue) * FE -
                Number(yrTwoRevenue) * GA);
        const yrThreeNetIncome =
            tax *
            (Number(yrThreeRevenue) -
                Number(yrThreeCostOfRevenue) -
                Number(yrThreeRevenue) * FE -
                Number(yrThreeRevenue) * GA);
        const yrFourNetIncome =
            tax *
            (Number(yrFourRevenue) -
                Number(yrFourCostOfRevenue) -
                Number(yrFourRevenue) * FE -
                Number(yrFourRevenue) * GA);
        const yrFiveNetIncome =
            tax *
            (Number(yrFiveRevenue) -
                Number(yrFiveCostOfRevenue) -
                Number(yrFiveRevenue) * FE -
                Number(yrFiveRevenue) * GA);
        const yrSixNetIncome =
            tax *
            (Number(yrSixRevenue) -
                Number(yrSixCostOfRevenue) -
                Number(yrSixRevenue) * FE -
                Number(yrSixRevenue) * GA);
        // Mid-Point of Cash Flow
        const partialPeriod = 1;
        const baseYrMidpointOfCashFlow = partialPeriod / 2;
        const yrOneMidpointOfCashFlow =
            baseYrMidpointOfCashFlow + partialPeriod;
        const yrTwoMidpointOfCashFlow = yrOneMidpointOfCashFlow + partialPeriod;
        const yrThreeMidpointOfCashFlow =
            yrTwoMidpointOfCashFlow + partialPeriod;
        const yrFourMidpointOfCashFlow =
            yrThreeMidpointOfCashFlow + partialPeriod;
        const yrFiveMidpointOfCashFlow =
            yrFourMidpointOfCashFlow + partialPeriod;
        const yrSixMidpointOfCashFlow =
            yrFiveMidpointOfCashFlow + partialPeriod;
        // Discount Factors
        const discount = 1 + discountFactor / 100;
        const baseYrDiscountFactor =
            1 / Math.pow(discount, baseYrMidpointOfCashFlow);
        const yrOneDiscountFactor =
            1 / Math.pow(discount, yrOneMidpointOfCashFlow);
        const yrTwoDiscountFactor =
            1 / Math.pow(discount, yrTwoMidpointOfCashFlow);
        const yrThreeDiscountFactor =
            1 / Math.pow(discount, yrThreeMidpointOfCashFlow);
        const yrFourDiscountFactor =
            1 / Math.pow(discount, yrFourMidpointOfCashFlow);
        const yrFiveDiscountFactor =
            1 / Math.pow(discount, yrFiveMidpointOfCashFlow);
        const yrSixDiscountFactor =
            1 / Math.pow(discount, yrSixMidpointOfCashFlow);
        // Calcs DCF value && debt / DCF value
        const netValueOfRecurringRevenue =
            baseYrNetIncome * baseYrDiscountFactor +
            yrOneNetIncome * yrOneDiscountFactor +
            yrTwoNetIncome * yrTwoDiscountFactor +
            yrThreeNetIncome * yrThreeDiscountFactor +
            yrFourNetIncome * yrFourDiscountFactor +
            yrFiveNetIncome * yrFiveDiscountFactor +
            yrSixNetIncome * yrSixDiscountFactor;

        if (isFinite(netValueOfRecurringRevenue)) {
            const computedNetValueOfRecurringRevenue =
                netValueOfRecurringRevenue.toFixed(2);
            const computedRenewalRate = (renewalRate * 100).toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                netValueOfRecurringRevenue: computedNetValueOfRecurringRevenue,
                renewalRate: computedRenewalRate,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            ltmRevenue: "",
            churn: "",
            costOfRevenuePercent: "",
            fixedExpensePercentOfRevenue: "",
            gaPercentOfRevenue: "",
        });

        setResults({
            renewalRate: "",
            yrOneRevenue: "",
            yrTwoRevenue: "",
            yrThreeRevenue: "",
            yrFourRevenue: "",
            yrFiveRevenue: "",
            yrSixRevenue: "",
            baseYrCostOfRevenue: "",
            yrOneCostOfRevenue: "",
            yrTwoCostOfRevenue: "",
            yrThreeCostOfRevenue: "",
            yrFourCostOfRevenue: "",
            yrFiveCostOfRevenue: "",
            yrSixCostOfRevenue: "",
            // baseYrGrossMargin: "",
            // yrOneGrossMargin: "",
            // yrTwoGrossMargin: "",
            // yrThreeGrossMargin: "",
            // yrFourGrossMargin: "",
            // yrFiveGrossMargin: "",
            // yrSixGrossMargin: "",
            // baseYrOpIncome: "",
            // yrOneOpIncome: "",
            // yrTwoOpIncome: "",
            // yrThreeOpIncome: "",
            // yrFourOpIncome: "",
            // yrFiveOpIncome: "",
            // yrSixOpIncome: "",
            baseYrNetIncome: "",
            yrOneNetIncome: "",
            yrTwoNetIncome: "",
            yrThreeNetIncome: "",
            yrFourNetIncome: "",
            yrFiveNetIncome: "",
            yrSixNetIncome: "",
            baseYrMidpointOfCashFlow: "",
            yrOneMidpointOfCashFlow: "",
            yrTwoMidpointOfCashFlow: "",
            yrThreeMidpointOfCashFlow: "",
            yrFourMidpointOfCashFlow: "",
            yrFiveMidpointOfCashFlow: "",
            yrSixMidpointOfCashFlow: "",
            baseYrDiscountFactor: "",
            yrOneDiscountFactor: "",
            yrTwoDiscountFactor: "",
            yrThreeDiscountFactor: "",
            yrFourDiscountFactor: "",
            yrFiveDiscountFactor: "",
            yrSixDiscountFactor: "",
            netValueOfRecurringRevenue: "",
            // netValueOfRecurringRevenuePlus: "",
            // netValueOfRecurringRevenueMinus: "",
            // leverageMultiplePlus: "",
            // leverageMultipleMinus: "",
            isResult: false,
        });
        setPage(1);
    };

    return (
        <div className="form text-center">
            <h6>Step {page} of 5</h6>
            <div>
                <progress max="5" value={page} />
            </div>
            <br />
            <h6 className="form-row d-flex justify-content-around">
                {page === 1
                    ? "Tell us more about your recurring revenue...."
                    : null}
            </h6>
            <br />
            {/* Display the error when it exists */}
            <small className="text-center">
                <strong>{error}</strong>
            </small>
            <form
                onSubmit={handleSubmitValues}
                className="d-flex justify-content-around"
            >
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div>
                        <Row>
                            {page === 1 && (
                                <div>
                                    <small>
                                        *What's your "Base Year" Recurring
                                        Revenue ($)?
                                    </small>
                                    <input
                                        type="text"
                                        name="ltmRevenue"
                                        placeholder=""
                                        value={userValues.ltmRevenue}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 2 && (
                                <div>
                                    <small>
                                        *What's the churn % (e.g. 3) on your
                                        recurring revenue?
                                    </small>
                                    <input
                                        type="text"
                                        name="churn"
                                        placeholder=""
                                        value={userValues.churn}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 3 && (
                                <div>
                                    <small>
                                        *Ok! What's your Cost of Revenue (as a %
                                        of revenue - e.g. 25)?
                                    </small>
                                    <input
                                        type="text"
                                        name="costOfRevenuePercent"
                                        placeholder=""
                                        value={userValues.costOfRevenuePercent}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 4 && (
                                <div>
                                    <small>
                                        *What about your Fixed Expenses (as a %
                                        of revenue)?
                                    </small>
                                    <input
                                        type="text"
                                        name="fixedExpensePercentOfRevenue"
                                        placeholder=""
                                        value={
                                            userValues.fixedExpensePercentOfRevenue
                                        }
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 5 && (
                                <div>
                                    <small>
                                        *Finally, what's your G & A (as a % of
                                        revenue)?
                                    </small>
                                    <input
                                        type="text"
                                        name="gaPercentOfRevenue"
                                        placeholder=""
                                        value={userValues.gaPercentOfRevenue}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Row>
                        <br />
                        <Row className="form-row d-flex justify-content-around">
                            {page !== 1 && (
                                <button
                                    className="btn btn-dark text-center"
                                    disabled={page < 2}
                                    onClick={goBack}
                                >
                                    Back
                                </button>
                            )}
                            {page !== 5 && (
                                <button
                                    className="btn btn-light text-center"
                                    onClick={goNextPage}
                                >
                                    Next
                                </button>
                            )}
                            {page === 5 && (
                                <input
                                    type="submit"
                                    value="View"
                                    className="btn btn-primary text-center"
                                />
                            )}
                        </Row>
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <p className="italic" style={{ textAlign: "left" }}>
                            Inputs:
                            <p />
                        </p>
                        <Table size="sm" borderless>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>
                                            Base LTM Recurring Revenue
                                        </strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        $
                                        {(userValues.ltmRevenue * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Churn</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {(userValues.churn * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Cost of Revenue</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {(userValues.costOfRevenuePercent * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Fixed Expenses</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {(
                                            userValues.fixedExpensePercentOfRevenue *
                                            1
                                        )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        %
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>G&A</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {(userValues.gaPercentOfRevenue * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                        %
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <hr />
                        <p />
                        <br />
                        <span>
                            <strong>
                                Squawkify SaaS iBankr - Preliminary View
                            </strong>
                            <br />
                            <small>
                                (results may vary slightly due to rounding)
                            </small>
                            <p />
                        </span>
                        <div style={{ textAlign: "left" }}>
                            Great! Your implied renewal rate is:
                            <p />
                            <p className="text-center">
                                <strong>{results.renewalRate}%</strong>
                            </p>
                            <p />
                            Your RR stream supports a minimum debt capacity of:
                            <p />
                            <p className="text-center">
                                <strong>
                                    ~$
                                    {(results.netValueOfRecurringRevenue * 1)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </p>
                            <p />
                            Lender diligence & collateral coverage commensurate
                            with risk.
                        </div>
                        <p />
                        {/* <div>
                            <label id="label">RR DCF Value:</label>
                            <input
                                type="text"
                                value={results.netValueOfRecurringRevenue
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div> */}
                        {/* <div>
                            <label id="label">Debt / RR DCF (x): </label>
                            <input
                                type="text"
                                value={results.leverageMultiple}
                                disabled
                            />
                        </div> */}
                        <p />
                        <br />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Start Over"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default RR;
