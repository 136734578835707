import React from "react";
import { Link } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
} from "reactstrap";

const Preview = () => {
    return (
        <section className="container">
            <div className="allButFooter">
                <h1 className="text-center">Squawkify Dealerboard & Tools</h1>
                <h6 className="text-center italic">
                    Your trusted source for private market signals & new deal
                    insights
                </h6>
                <br />
                <br />
                <p className="text-center">
                    <strong>Squawk Channels by Deal Category:</strong>
                </p>
                <div className="text-center dash-buttons">
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Private Credit
                    </Link>
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Private Equity
                    </Link>
                    <Link to="//register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Venture Capital
                    </Link>
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>M&A
                    </Link>
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Restructuring
                    </Link>
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Collateral
                    </Link>
                    <Link to="/register" className="btn btn-dark mb-2">
                        <i className="text-primary"></i>Fund
                    </Link>
                </div>
                <br />
                <br />
                <div>
                    <Row md={4}>
                        <Col sm={12} md={6} lg={4} xl={4}>
                            <h4 className="text-center">Private Credit</h4>
                            <Card className="mb-2 mr-2">
                                <CardBody className="text-center">
                                    <CardTitle>
                                        <strong>Gastro Health, LLC</strong>
                                    </CardTitle>
                                    <CardSubtitle>
                                        <strong>$100,000,000</strong>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>
                                            2L Credit Facility / Equity
                                        </small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Acquisition Financing</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>OMERS Private Equity</small>
                                    </CardSubtitle>
                                    <br />
                                    <CardSubtitle>
                                        <small>Healthcare</small>
                                    </CardSubtitle>
                                    <CardSubtitle>
                                        <small>Providers & Services</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardText>
                                        <strong>Penfund</strong>
                                    </CardText>
                                    <CardText>
                                        <small>July 2021</small>
                                    </CardText>
                                </CardBody>
                                <p className="my-1 text-center">
                                    <small>https://gastrohealth.com</small>
                                </p>
                                <p />
                                <div className="text-center mb-2">
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Lift :: Buy"
                                        className="btn btn-secondary"
                                    >
                                        <i>Lift</i> <span>101</span>
                                    </Link>
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Hit :: Sell"
                                        className="btn btn-secondary"
                                    >
                                        <i>Hit</i> <span>33</span>
                                    </Link>
                                </div>
                                <Link
                                    to={`/register`}
                                    className="btn btn-primary btn-block"
                                    title="Details & Squawks"
                                >
                                    Dealwire{" "}
                                    <span className="comment-count">154</span>
                                </Link>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={4}>
                            <h4 className="text-center">Venture Capital</h4>
                            <Card className="mb-2 mr-2">
                                <CardBody className="text-center">
                                    <CardTitle>
                                        <strong>Remote</strong>
                                    </CardTitle>
                                    <CardSubtitle>
                                        <strong>$150,000,000</strong>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Series B</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Investment</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>
                                            ~$1 billion Post-Money Valuation
                                        </small>
                                    </CardSubtitle>
                                    <br />
                                    <CardSubtitle>
                                        <small>Technology</small>
                                    </CardSubtitle>
                                    <CardSubtitle>
                                        <small>HR Tech</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardText>
                                        <strong>Accel</strong>
                                    </CardText>
                                    <CardText>
                                        <small>July 2021</small>
                                    </CardText>
                                </CardBody>
                                <p className="my-1 text-center">
                                    <small>https://remote.com</small>
                                </p>
                                <p />
                                <div className="text-center mb-2">
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Lift :: Buy"
                                        className="btn btn-secondary"
                                    >
                                        <i>Lift</i> <span>579</span>
                                    </Link>
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Hit :: Sell"
                                        className="btn btn-secondary"
                                    >
                                        <i>Hit</i> <span>113</span>
                                    </Link>
                                </div>
                                <Link
                                    to={`/register`}
                                    className="btn btn-primary btn-block"
                                    title="Details & Squawks"
                                >
                                    Dealwire{" "}
                                    <span className="comment-count">752</span>
                                </Link>
                            </Card>
                        </Col>
                        <Col sm={12} md={6} lg={4} xl={4}>
                            <h4 className="text-center">Private Equity</h4>
                            <Card className="mb-2 mr-2">
                                <CardBody className="text-center">
                                    <CardTitle>
                                        <strong>Entrata</strong>
                                    </CardTitle>
                                    <CardSubtitle>
                                        <strong>$507,000,000</strong>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Growth Investment</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Non-Control</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardSubtitle>
                                        <small>Club Deal</small>
                                    </CardSubtitle>
                                    <br />
                                    <CardSubtitle>
                                        <small>Real Estate</small>
                                    </CardSubtitle>
                                    <CardSubtitle>
                                        <small>Proptech</small>
                                    </CardSubtitle>
                                    <p />
                                    <CardText>
                                        <strong>Silver Lake</strong>
                                    </CardText>
                                    <CardText>
                                        <small>July 2021</small>
                                    </CardText>
                                </CardBody>
                                <p className="my-1 text-center">
                                    <small>https://www.entrata.com</small>
                                </p>
                                <p />
                                <div className="text-center mb-2">
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Lift :: Buy"
                                        className="btn btn-secondary"
                                    >
                                        <i>Lift</i> <span>214</span>
                                    </Link>
                                    <Link
                                        to={`/register`}
                                        type="button"
                                        title="Hit :: Sell"
                                        className="btn btn-secondary"
                                    >
                                        <i>Hit</i> <span>99</span>
                                    </Link>
                                </div>
                                <Link
                                    to={`/register`}
                                    className="btn btn-primary btn-block"
                                    title="Details & Squawks"
                                >
                                    Dealwire{" "}
                                    <span className="comment-count">622</span>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                <hr />
                <br />
                <br />
                <h1 className="text-center">Quick Calcs / Mini's</h1>
                <br />
                <br />
                <div className="form">
                    <h6 className="text-primary text-center">
                        <strong>M&A Accretion / Dilution</strong>
                        <br />
                        <small>(3 Acq. Scenarios: stock, debt, cash)</small>
                    </h6>
                    <small>* = required field</small>
                    <p />
                    <div>
                        <div className="box shadow p-3 mb-5 bg-white rounded">
                            <div className="form-row d-flex justify-content-around">
                                <div>
                                    <small>*Tax Rate %:</small>
                                    <br />
                                    <input placeholder="35" disabled />
                                </div>
                                <div>
                                    <small>*Cost of Debt %:</small>
                                    <br />
                                    <input placeholder="10" disabled />
                                </div>
                                <div>
                                    <small>*Interest on Cash %:</small>
                                    <br />
                                    <input placeholder="5" disabled />
                                </div>
                            </div>
                            <p />
                            <div className="form-row d-flex justify-content-around">
                                <div>
                                    <small>*Acquiror Share Price:</small>
                                    <br />
                                    <input placeholder="20" disabled />
                                </div>
                                <div>
                                    <small>
                                        *Acquiror # Shares Outstanding:
                                    </small>
                                    <br />
                                    <input placeholder="1,000,000" disabled />
                                </div>
                                <div>
                                    <small>*Acquiror P/E:</small>
                                    <br />
                                    <input placeholder="20" disabled />
                                </div>
                            </div>
                            <p />
                            <div className="form-row d-flex justify-content-around">
                                <div>
                                    <small>*Target Share Price:</small>
                                    <br />
                                    <input placeholder="15" disabled />
                                </div>
                                <div>
                                    <small>*Target # Shares Outstanding:</small>
                                    <br />
                                    <input placeholder="5,000,000" disabled />
                                </div>
                                <div>
                                    <small>*Target P/E:</small>
                                    <br />
                                    <input placeholder="15" disabled />
                                </div>
                            </div>
                            <p />
                            <div className="text-center mb-2">
                                <Link
                                    to={`/register`}
                                    type="button"
                                    title="Submit"
                                    className="btn btn-secondary"
                                >
                                    <span>Submit</span>
                                </Link>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <hr />
                <br />
                <br />
                <h1 className="text-center">iBankr</h1>
                <br />
                <h3 className="text-primary text-center">ABL</h3>
                <h6 className="text-primary text-center">Asset-Based Lender</h6>
                <p />
                <br />
                <div className="form text-center">
                    <h6>Step 1 of 4</h6>
                    <div>
                        <progress max="4" value="1" />
                    </div>
                    <br />
                    <h6 className="form-row d-flex justify-content-around">
                        Tell us more about your collateral base....
                    </h6>
                    <br />
                    <div className="text-center mb-2">
                        <small>*How much gross A/R on the balance sheet?</small>
                        <p />
                        <input placeholder="25" disabled />
                    </div>
                    <p />
                    <div className="text-center mb-2">
                        <Link
                            to={`/register`}
                            type="button"
                            title="Back"
                            className="btn btn-secondary"
                        >
                            <span>Next</span>
                        </Link>
                    </div>
                    <br />
                </div>
            </div>
        </section>
    );
};

export default Preview;
