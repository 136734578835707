import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRVCSquawkItem from "./DetailedCOPRVCSquawkItem";
import COPRVCSquawkCommentForm from "./COPRVCSquawkCommentForm";
import COPRVCSquawkCommentItem from "./COPRVCSquawkCommentItem";
import { getCOPRVCSquawk } from "../../../actions/coprvcsquawk";

const COPRVCSquawk = ({
    getCOPRVCSquawk,
    coprvcsquawk: { coprvcsquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRVCSquawk(id);
    }, [getCOPRVCSquawk, id]);

    return loading || coprvcsquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/venturecapital" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRVCSquawkItem coprvcsquawk={coprvcsquawk} />
            <p />
            <hr />
            <br />
            <COPRVCSquawkCommentForm coprvcsquawkId={coprvcsquawk._id} />
            <br />
            <div className="comments">
                {coprvcsquawk.comments.map((comment) => (
                    <COPRVCSquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprvcsquawkId={coprvcsquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRVCSquawk.propTypes = {
    getCOPRVCSquawk: PropTypes.func.isRequired,
    coprvcsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprvcsquawk: state.coprvcsquawk,
});

export default connect(mapStateToProps, { getCOPRVCSquawk })(COPRVCSquawk);
