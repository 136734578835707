import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRBKSquawkItem = ({
    coprbksquawk: {
        _id,
        user,
        username,
        coprbksquawkDate,
        // coprbksquawkMarket,
        // coprbksquawkTicker,
        // coprbksquawkExchange,
        coprbksquawkCompany,
        coprbksquawkCompanyUrl,
        // coprbksquawkPRUrl,
        coprbksquawkSector,
        coprbksquawkSubSector,
        coprbksquawkSize,
        coprbksquawkStructure,
        coprbksquawkPricing,
        coprbksquawkCategory,
        coprbksquawkProcess,
        coprbksquawkType,
        coprbksquawkCapStruc,
        coprbksquawkAssets,
        coprbksquawkLiquidity,
        coprbksquawkMaturityWall,
        coprbksquawkDebtTradingLevels,
        coprbksquawkCounsel,
        coprbksquawk3PDD,
        coprbksquawkAdvisor,
        coprbksquawkLender,
        coprbksquawkSponsor,
        // coprbksquawkNotes,
        // coprbksquawkStatus,
        coprbksquawkDocketNum,
        coprbksquawkCaseNum,
        coprbksquawkCaseJurisdiction,
        coprbksquawkCaseDetail,
        coprbksquawkPOR,
        coprbksquawkDIPTerms,
        coprbksquawkStalkingHorseBidder,
        coprbksquawkStalkingHorseBid,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprbksquawkPRUrl === "" ? ( */}
                    <strong>{coprbksquawkCompany}</strong>
                    {/* ) : (
                        <a href={coprbksquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <strong>Company: {coprbksquawkCompany}</strong>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprbksquawkCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprbksquawkCompanyUrl}
                    </small>
                </Col>
                <Col>
                    <small>{coprbksquawkType}</small>
                </Col>
                <Col>
                    <small>
                        Date:{" "}
                        {new Date(coprbksquawkDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Amount: $
                        {coprbksquawkSize
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Structure: {coprbksquawkStructure}</small>
                </Col>
                <Col>
                    <small>Sector: {coprbksquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprbksquawkSubSector}</small>
                </Col>
            </Row>
            <br />
            <strong>Transaction</strong>
            <Row>
                <Col>
                    <small>Category: {coprbksquawkCategory}</small>
                </Col>
                <Col>
                    <small>Process: {coprbksquawkProcess}</small>
                </Col>
                <Col>
                    <small>Pricing: {coprbksquawkPricing}</small>
                </Col>
                <Col>
                    <small>
                        Liquidity ($):&nbsp;
                        {coprbksquawkLiquidity
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Assets / Collateral: {coprbksquawkAssets}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Capital Structure: {coprbksquawkCapStruc}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Maturity Wall: {coprbksquawkMaturityWall}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Debt Trading Levels: {coprbksquawkDebtTradingLevels}
                    </small>
                </Col>
            </Row>
            <br />
            <strong>Participants</strong>
            <Row>
                <Col>
                    <small>Advisor: {coprbksquawkAdvisor}</small>
                </Col>
                <Col>
                    <small>Sponsor: {coprbksquawkSponsor}</small>
                </Col>
                <Col>
                    <small>Lender: {coprbksquawkLender}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Legal Counsel: {coprbksquawkCounsel}</small>
                </Col>
                <Col>
                    <small>3PDD: {coprbksquawk3PDD}</small>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <strong>Supplemental In-Court Detail</strong>
            <Row>
                <Col>
                    <small>Docket Number: {coprbksquawkDocketNum}</small>
                </Col>
                <Col>
                    <small>Case Number: {coprbksquawkCaseNum}</small>
                </Col>
                <Col>
                    <small>Jurisdiction: {coprbksquawkCaseJurisdiction}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Case Detail: {coprbksquawkCaseDetail}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>POR Detail: {coprbksquawkPOR}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>DIP Terms: {coprbksquawkDIPTerms}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Stalking Horse Bidder: {coprbksquawkStalkingHorseBidder}
                    </small>
                </Col>
                <Col>
                    <small>
                        Stalking Horse Bid ($):&nbsp;
                        {coprbksquawkStalkingHorseBid
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRBKSquawkItem.propTypes = {
    coprbksquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRBKSquawkItem;
