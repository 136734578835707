import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCOPRPESquawkComment } from "../../../actions/coprpesquawk";

const COPRPESquawkCommentForm = ({
    coprpesquawkId,
    addCOPRPESquawkComment,
}) => {
    const [text, setText] = useState("");

    return (
        <div className="post-form">
            <small>Revert...or share an axe</small>
            <form
                className="form my-1"
                onSubmit={(e) => {
                    e.preventDefault();
                    addCOPRPESquawkComment(coprpesquawkId, {
                        text,
                    });
                    setText("");
                }}
            >
                <textarea
                    name="text"
                    cols="30"
                    rows="5"
                    placeholder="* Get on the wire...."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
            </form>
        </div>
    );
};

COPRPESquawkCommentForm.propTypes = {
    addCOPRPESquawkComment: PropTypes.func.isRequired,
};

export default connect(null, { addCOPRPESquawkComment })(
    COPRPESquawkCommentForm
);
