import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRBKSquawkItem from "./COPRBKSquawkItem";
import SearchBox from "./COPRBKSearchBox";
import { getCOPRBKSquawks } from "../../../actions/coprbksquawk";
import DealWallTurret from "../DealWallTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRBKSquawks = ({
    getCOPRBKSquawks,
    coprbksquawk: { coprbksquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRBKSquawks(keyword);
    }, [getCOPRBKSquawks, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealWallTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Select restructuring
                deals:
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link
                        to="/add-coprbksquawk"
                        className="btn btn-primary mb-2"
                    >
                        <i className="text-primary"></i> Add Deal
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            {coprbksquawks.length === 0 && (
                <div>
                    <strong>
                        No matching results...please revise your search!
                    </strong>
                </div>
            )}
            <Row md={4}>
                {coprbksquawks.map((coprbksquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRBKSquawkItem
                            key={coprbksquawk._id}
                            coprbksquawk={coprbksquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRBKSquawks.propTypes = {
    getCOPRBKSquawks: PropTypes.func.isRequired,
    coprbksquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprbksquawk: state.coprbksquawk,
});

export default connect(mapStateToProps, { getCOPRBKSquawks })(COPRBKSquawks);
