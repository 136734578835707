import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRSquawkItem from "./COPRSquawkItem";
import SearchBox from "./COPRRepoSearchBox";
import { getCOPRSquawksRepo } from "../../../actions/coprsquawk";
import DealRepoTurret from "../DealRepoTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRSquawksRepo = ({
    getCOPRSquawksRepo,
    coprsquawk: { coprsquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRSquawksRepo(keyword);
    }, [getCOPRSquawksRepo, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealRepoTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Your private credit
                squawk count:{" "}
                <strong>
                    {coprsquawks.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </strong>
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link to="/add-coprsquawk" className="btn btn-primary mb-2">
                        <i className="text-primary"></i> Add Deal
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            <p />
            {/* {coprsquawks.length === 0 ? (
                <div>
                    <strong>
                        You currently have no squawks in this deal category....
                    </strong>
                </div>
            ) : (
                ""
            )} */}
            {/* {coprsquawks.length !== 0
                ? ""
                : coprsquawks.length === 0 && (
                      <div>
                          <strong>
                              No matching results...please revise your search!
                          </strong>
                      </div>
                  )} */}
            <Row md={4}>
                {coprsquawks.map((coprsquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRSquawkItem
                            key={coprsquawk._id}
                            coprsquawk={coprsquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRSquawksRepo.propTypes = {
    getCOPRSquawksRepo: PropTypes.func.isRequired,
    coprsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprsquawk: state.coprsquawk,
});

export default connect(mapStateToProps, { getCOPRSquawksRepo })(
    COPRSquawksRepo
);
