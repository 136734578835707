import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRBKSQUAWK,
    UPDATE_COPRBKSQUAWK,
    GET_COPRBKSQUAWKS,
    GET_COPRBKSQUAWKS_REPO,
    GET_COPRBKSQUAWK,
    COPRBKSQUAWK_ERROR,
    UPDATE_COPRBKLIKES,
    ADD_COPRBKSQUAWK,
    ADD_COPRBKSQUAWK_COMMENT,
    REMOVE_COPRBKSQUAWK_COMMENT,
} from "./types";

// Get COPR bksquawks
export const getCOPRBKSquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprbksquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRBKSQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRBKSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR bksquawks -- user repo
export const getCOPRBKSquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprbksquawks/repo?keyword=${keyword}`);

            dispatch({
                type: GET_COPRBKSQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRBKSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprbksquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRBKLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRBKSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprbksquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRBKLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRBKSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR bksquawk
export const deleteCOPRBKSquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprbksquawks/${id}`);

            dispatch({
                type: DELETE_COPRBKSQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRBKSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR bksquawk
export const addCOPRBKSquawk = (formdata) => async (dispatch) => {
    // const config = {
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    // };

    try {
        const res = await api.post("/coprbksquawks", formdata);

        dispatch({
            type: ADD_COPRBKSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRBKSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR bksquawk
export const updateCOPRBKSquawk = (id, updatedFormdata) => async (dispatch) => {
    try {
        const res = await api.put(`/coprbksquawks/${id}`, updatedFormdata);

        dispatch({
            type: UPDATE_COPRBKSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal Updated", "success"));
    } catch (err) {
        dispatch({
            type: COPRBKSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Get COPR bksquawk
export const getCOPRBKSquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprbksquawks/${id}`);

        dispatch({
            type: GET_COPRBKSQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRBKSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR bksquawk comment
export const addCOPRBKSquawkComment =
    (coprbksquawkId, formdata) => async (dispatch) => {
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        try {
            const res = await api.post(
                `/coprbksquawks/comment/${coprbksquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRBKSQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRBKSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR bksquawk comment
export const deleteCOPRBKSquawkComment =
    (coprbksquawkId, coprbkcommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprbksquawks/comment/${coprbksquawkId}/${coprbkcommentId}`
            );

            dispatch({
                type: REMOVE_COPRBKSQUAWK_COMMENT,
                payload: coprbkcommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRBKSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
