import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRPESQUAWK,
    UPDATE_COPRPESQUAWK,
    GET_COPRPESQUAWKS,
    GET_COPRPESQUAWKS_REPO,
    GET_COPRPESQUAWK,
    COPRPESQUAWK_ERROR,
    UPDATE_COPRPELIKES,
    ADD_COPRPESQUAWK,
    ADD_COPRPESQUAWK_COMMENT,
    REMOVE_COPRPESQUAWK_COMMENT,
} from "./types";

// Get COPR pesquawks
export const getCOPRPESquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprpesquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRPESQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRPESQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR pesquawks -- user repo
export const getCOPRPESquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprpesquawks/repo?keyword=${keyword}`);

            dispatch({
                type: GET_COPRPESQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRPESQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprpesquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRPELIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRPESQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprpesquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRPELIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRPESQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR pesquawk
export const deleteCOPRPESquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprpesquawks/${id}`);

            dispatch({
                type: DELETE_COPRPESQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRPESQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR pesquawk
export const addCOPRPESquawk = (formdata) => async (dispatch) => {
    try {
        const res = await api.post("/coprpesquawks", formdata);

        dispatch({
            type: ADD_COPRPESQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRPESQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR pesquawk
export const updateCOPRPESquawk = (id, updatedFormdata) => async (dispatch) => {
    try {
        const res = await api.put(`/coprpesquawks/${id}`, updatedFormdata);

        dispatch({
            type: UPDATE_COPRPESQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal Updated", "success"));
    } catch (err) {
        dispatch({
            type: COPRPESQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Get COPR pesquawk
export const getCOPRPESquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprpesquawks/${id}`);

        dispatch({
            type: GET_COPRPESQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRPESQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR pesquawk comment
export const addCOPRPESquawkComment =
    (coprpesquawkId, formdata) => async (dispatch) => {
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        try {
            const res = await api.post(
                `/coprpesquawks/comment/${coprpesquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRPESQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRPESQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR pesquawk comment
export const deleteCOPRPESquawkComment =
    (coprpesquawkId, coprpecommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprpesquawks/comment/${coprpesquawkId}/${coprpecommentId}`
            );

            dispatch({
                type: REMOVE_COPRPESQUAWK_COMMENT,
                payload: coprpecommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRPESQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
