import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRFundSquawkItem from "./COPRFundSquawkItem";
import SearchBox from "./COPRFundRepoSearchBox";
import { getCOPRFundSquawksRepo } from "../../../actions/coprfundsquawk";
import DealRepoTurret from "../DealRepoTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRFundSquawksRepo = ({
    getCOPRFundSquawksRepo,
    coprfundsquawk: { coprfundsquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRFundSquawksRepo(keyword);
    }, [getCOPRFundSquawksRepo, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealRepoTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Your fund squawk
                count:{" "}
                <strong>
                    {coprfundsquawks.length
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </strong>
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link
                        to="/add-coprfundsquawk"
                        className="btn btn-primary mb-2"
                    >
                        <i className="text-primary"></i> Add Fund
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            {/* {coprfundsquawks.length !== 0
                ? ""
                : coprfundsquawks.length === 0 && (
                      <div>
                          <strong>
                              No matching results...please revise your search!
                          </strong>
                      </div>
                  )} */}
            <Row md={4}>
                {coprfundsquawks.map((coprfundsquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRFundSquawkItem
                            key={coprfundsquawk._id}
                            coprfundsquawk={coprfundsquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRFundSquawksRepo.propTypes = {
    getCOPRFundSquawksRepo: PropTypes.func.isRequired,
    coprfundsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprfundsquawk: state.coprfundsquawk,
});

export default connect(mapStateToProps, { getCOPRFundSquawksRepo })(
    COPRFundSquawksRepo
);
