import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRFUNDSQUAWK,
    UPDATE_COPRFUNDSQUAWK,
    GET_COPRFUNDSQUAWKS,
    GET_COPRFUNDSQUAWKS_REPO,
    GET_COPRFUNDSQUAWK,
    COPRFUNDSQUAWK_ERROR,
    UPDATE_COPRFUNDLIKES,
    ADD_COPRFUNDSQUAWK,
    ADD_COPRFUNDSQUAWK_COMMENT,
    REMOVE_COPRFUNDSQUAWK_COMMENT,
} from "./types";

// Get COPR fundsquawks
export const getCOPRFundSquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprfundsquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRFUNDSQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR fundsquawks -- user repo
export const getCOPRFundSquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(
                `/coprfundsquawks/repo?keyword=${keyword}`
            );

            dispatch({
                type: GET_COPRFUNDSQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprfundsquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRFUNDLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRFUNDSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprfundsquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRFUNDLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRFUNDSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR fundsquawk
export const deleteCOPRFundSquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprfundsquawks/${id}`);

            dispatch({
                type: DELETE_COPRFUNDSQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR fundsquawk
export const addCOPRFundSquawk = (formdata) => async (dispatch) => {
    try {
        const res = await api.post("/coprfundsquawks", formdata);

        dispatch({
            type: ADD_COPRFUNDSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRFUNDSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR fundsquawk
export const updateCOPRFundSquawk =
    (id, updatedFormdata) => async (dispatch) => {
        try {
            const res = await api.put(
                `/coprfundsquawks/${id}`,
                updatedFormdata
            );

            dispatch({
                type: UPDATE_COPRFUNDSQUAWK,
                payload: res.data,
            });

            dispatch(setAlert("Deal Updated", "success"));
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR fundsquawk
export const getCOPRFundSquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprfundsquawks/${id}`);

        dispatch({
            type: GET_COPRFUNDSQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRFUNDSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR fundsquawk comment
export const addCOPRFundSquawkComment =
    (coprfundsquawkId, formdata) => async (dispatch) => {
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        try {
            const res = await api.post(
                `/coprfundsquawks/comment/${coprfundsquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRFUNDSQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR fundsquawk comment
export const deleteCOPRFundSquawkComment =
    (coprfundsquawkId, coprfundcommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprfundsquawks/comment/${coprfundsquawkId}/${coprfundcommentId}`
            );

            dispatch({
                type: REMOVE_COPRFUNDSQUAWK_COMMENT,
                payload: coprfundcommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRFUNDSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
