import React from "react";
import { Link } from "react-router-dom";
import BlendedPrice from "./BlendedPrice";
import StartupJobOffer from "./StartupJobOffer";
import AccretionDilution from "./AccretionDilution";
import ARPURevenue from "./ARPURevenue";
import LBOIRR from "./LBOIRR";
import Yield from "./Yield";
import WarrantValuation from "./WarrantValuation";
import BorrowingBase from "./abl/BorrowingBase";
import AssetLiquidation from "./abl/AssetLiquidation";
import OilGas from "./abl/OilGas";
import RecurringRevenueDCF from "./cashflow/RecurringRevenueDCF";
import CashFlowWaterfall from "./cashflow/CashFlowWaterfall";
import ScrollToTopButton from "../layout/ScrollToTopButton";

const QuickCalcs = () => {
    return (
        <section className="container">
            <Link to="/privatecredit" className="btn btn-primary">
                &laquo; Deals
            </Link>
            <p />
            <div className="allButFooter">
                <h3 className="text-primary">QuickCalcs / Mini's</h3>
                <small>
                    * Easy access back-of-the-envelope calculations. For
                    ballpark estimate purposes <strong>only</strong>! Requests?
                    Send Excel calcs: calcs@squawkify.com - no promises.
                </small>
                <br />
                <hr />
                <h6 className="text-center">
                    <strong>Note</strong>
                    <small>
                        : enter all percent estimates as is (e.g. '20' if 20%)
                    </small>
                </h6>
                <br />
                <StartupJobOffer />
                <ARPURevenue />
                <AccretionDilution />
                <WarrantValuation />
                <Yield />
                <LBOIRR />
                <BlendedPrice />
                <BorrowingBase />
                <AssetLiquidation />
                <RecurringRevenueDCF />
                <OilGas />
                <CashFlowWaterfall />
            </div>
            <ScrollToTopButton />
        </section>
    );
};

export default QuickCalcs;
