import {
    GET_VCSQUAWKS,
    GET_VCSQUAWK,
    VCSQUAWK_ERROR,
    UPDATE_VCLIKES,
    DELETE_VCSQUAWK,
    ADD_VCSQUAWK,
    ADD_VCSQUAWK_COMMENT,
    REMOVE_VCSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    vcsquawks: [],
    vcsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_VCSQUAWKS:
            return {
                ...state,
                vcsquawks: payload,
                loading: false,
            };
        case GET_VCSQUAWK:
            return {
                ...state,
                vcsquawk: payload,
                loading: false,
            };
        case ADD_VCSQUAWK:
            return {
                ...state,
                vcsquawks: [payload, ...state.vcsquawks],
                loading: false,
            };
        case DELETE_VCSQUAWK:
            return {
                ...state,
                vcsquawks: state.vcsquawks.filter(
                    (vcsquawk) => vcsquawk._id !== payload
                ),
                loading: false,
            };
        case VCSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_VCLIKES:
            return {
                ...state,
                vcsquawks: state.vcsquawks.map((vcsquawk) =>
                    vcsquawk._id === payload.id
                        ? { ...vcsquawk, likes: payload.likes }
                        : vcsquawk
                ),
                loading: false,
            };
        case ADD_VCSQUAWK_COMMENT:
            return {
                ...state,
                vcsquawk: { ...state.vcsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_VCSQUAWK_COMMENT:
            return {
                ...state,
                vcsquawk: {
                    ...state.vcsquawk,
                    comments: state.vcsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
