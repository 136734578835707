import React, { useState } from "react";
import { Col, Row, Table } from "reactstrap";

const ABL = () => {
    // BB = Eligible Accounts Receivable * AR Advance Rate + Eligible Inventory (or NOLV) * Inv Advance Rate
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        grossAR: "",
        grossInv: "",
        grossPPE: "",
        grossRE: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        eligibleAR: "",
        ineligibleAR: "",
        availableAR: "",
        percentTotalAvailableAR: "",
        arNetEffectiveAdvRate: "",
        eligibleINV: "",
        ineligibleINV: "",
        availableINV: "",
        percentTotalAvailableINV: "",
        invNetEffectiveAdvRate: "",
        totalBorrowingBase: "",
        excessAvailability: "",
        availablePPE: "",
        availableRE: "",
        isResult: false,
    });

    // Multi-step Page
    const [page, setPage] = useState(1);

    const goNextPage = () => {
        if (page === 4) return;
        setPage((page) => page + 1);
    };

    const goBack = () => {
        setPage((page) => page - 1);
    };

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const { grossAR, grossInv, grossPPE, grossRE } = userValues;
        let actualError = "";
        // Validate if there are values
        if (!grossAR || !grossInv || !grossPPE || !grossRE) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(grossAR) ||
            isNaN(grossInv) ||
            isNaN(grossPPE) ||
            isNaN(grossRE)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(grossAR) < 0 ||
            Number(grossInv) < 0 ||
            Number(grossPPE) < 0 ||
            Number(grossRE) < 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({ grossAR, grossInv, grossPPE, grossRE }) => {
        const ineligibleAR = Number(grossAR) * 0.125;
        const ineligibleINV = Number(grossInv) * 0.15;
        const ineligiblePPE = Number(grossPPE) * 0.33;
        const ineligibleRE = Number(grossRE) * 0.4;
        const eligibleAR = Number(grossAR) - Number(ineligibleAR);
        const eligibleINV = Number(grossInv) - Number(ineligibleINV);
        const eligiblePPE = Number(grossPPE) - Number(ineligiblePPE);
        const eligibleRE = Number(grossRE) - Number(ineligibleRE);
        const availableAR = Number(eligibleAR) * 0.85;
        const availableINV = Number(eligibleINV) * 0.85;
        const availablePPE = Number(eligiblePPE) * 0.75;
        const availableRE = Number(eligibleRE) * 0.75;
        const totalBorrowingBase = availableAR + availableINV;
        const percentTotalAvailableAR =
            (availableAR / totalBorrowingBase) * 100;
        const arNetEffectiveAdvRate = (availableAR / grossAR) * 100;
        const percentTotalAvailableINV =
            (availableINV / totalBorrowingBase) * 100;
        const invNetEffectiveAdvRate = (availableINV / grossInv) * 100;

        if (isFinite(totalBorrowingBase)) {
            const computedeligibleAR = eligibleAR.toFixed(2);
            const computedineligibleAR = ineligibleAR.toFixed(2);
            const computedavailableAR = availableAR.toFixed(2);
            const computedpercentTotalAvailableAR =
                percentTotalAvailableAR.toFixed(2);
            const computedarNetEffectiveAdvRate =
                arNetEffectiveAdvRate.toFixed(2);
            const computedeligibleINV = eligibleINV.toFixed(2);
            const computedineligibleINV = ineligibleINV.toFixed(2);
            const computedavailableINV = availableINV.toFixed(2);
            const computedavailablePPE = availablePPE.toFixed(2);
            const computedavailableRE = availableRE.toFixed(2);
            const computedpercentTotalAvailableINV =
                percentTotalAvailableINV.toFixed(2);
            const computedinvNetEffectiveAdvRate =
                invNetEffectiveAdvRate.toFixed(2);
            const computedtotalBorrowingBase = totalBorrowingBase.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                eligibleAR: computedeligibleAR,
                ineligibleAR: computedineligibleAR,
                availableAR: computedavailableAR,
                percentTotalAvailableAR: computedpercentTotalAvailableAR,
                arNetEffectiveAdvRate: computedarNetEffectiveAdvRate,
                eligibleINV: computedeligibleINV,
                ineligibleINV: computedineligibleINV,
                availableINV: computedavailableINV,
                percentTotalAvailableINV: computedpercentTotalAvailableINV,
                invNetEffectiveAdvRate: computedinvNetEffectiveAdvRate,
                availablePPE: computedavailablePPE,
                availableRE: computedavailableRE,
                totalBorrowingBase: computedtotalBorrowingBase,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            grossAR: "",
            grossInv: "",
            grossPPE: "",
            grossRE: "",
        });

        setResults({
            eligibleAR: "",
            ineligibleAR: "",
            availableAR: "",
            percentTotalAvailableAR: "",
            arNetEffectiveAdvRate: "",
            eligibleINV: "",
            ineligibleINV: "",
            availableINV: "",
            percentTotalAvailableINV: "",
            invNetEffectiveAdvRate: "",
            availablePPE: "",
            availableRE: "",
            totalBorrowingBase: "",
            isResult: false,
        });
        setPage(1);
    };

    return (
        <div className="form text-center">
            <h6>Step {page} of 4</h6>
            <div>
                <progress max="4" value={page} />
            </div>
            <br />
            <h6 className="form-row d-flex justify-content-around">
                {page === 1
                    ? "Tell us more about your collateral base...."
                    : null}
            </h6>
            <br />
            {/* Display the error when it exists */}
            <small className=" text-center">
                <strong>{error}</strong>
            </small>
            <form
                onSubmit={handleSubmitValues}
                className="d-flex justify-content-around"
            >
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div>
                        <Row>
                            {page === 1 && (
                                <div>
                                    <small>
                                        *How much gross A/R on the balance
                                        sheet?
                                    </small>
                                    <input
                                        type="text"
                                        name="grossAR"
                                        placeholder=""
                                        value={userValues.grossAR}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 2 && (
                                <div>
                                    <small>*How much gross inventory?</small>
                                    <input
                                        type="text"
                                        name="grossInv"
                                        placeholder=""
                                        value={userValues.grossInv}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 3 && (
                                <div>
                                    <small>
                                        *Excellent! Alright, what about
                                        machinery & equipment -- book value?
                                    </small>
                                    <input
                                        type="text"
                                        name="grossPPE"
                                        placeholder=""
                                        value={userValues.grossPPE}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                            {page === 4 && (
                                <div>
                                    <small>
                                        *Any real estate? What's the FMV?
                                    </small>
                                    <input
                                        type="text"
                                        name="grossRE"
                                        placeholder=""
                                        value={userValues.grossRE}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </Row>
                        <br />
                        <Row className="form-row d-flex justify-content-around">
                            {page !== 1 && (
                                <button
                                    className="btn btn-dark text-center"
                                    disabled={page < 2}
                                    onClick={goBack}
                                >
                                    Back
                                </button>
                            )}
                            {page !== 4 && (
                                <button
                                    className="btn btn-light text-center"
                                    onClick={goNextPage}
                                >
                                    Next
                                </button>
                            )}
                            {page === 4 && (
                                <input
                                    type="submit"
                                    value="View"
                                    className="btn btn-primary text-center"
                                />
                            )}
                        </Row>
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <p className="italic" style={{ textAlign: "left" }}>
                            Inputs:
                            <p />
                        </p>
                        <Table size="sm" borderless>
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Gross A/R</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        $
                                        {(userValues.grossAR * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Gross Inventory</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        $
                                        {(userValues.grossInv * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>M&E Book Value</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        $
                                        {(userValues.grossPPE * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Real Estate FMV</strong>
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        $
                                        {(userValues.grossRE * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <hr />
                        <p />
                        <br />
                        <span>
                            <strong>
                                Squawkify ABL iBankr - Preliminary View
                            </strong>
                            <br />
                            <small>
                                (results may vary slightly due to rounding)
                            </small>
                            <p />
                        </span>
                        <div style={{ textAlign: "left" }}>
                            Borrowing capacity -- conforming ABL -- should
                            approximate:
                            <p />
                            <p className="text-center">
                                <strong>
                                    ~$
                                    {(results.totalBorrowingBase * 1)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </p>
                            <Row className="text-center">
                                <Col>
                                    <small>
                                        A/R: $
                                        {(results.availableAR * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </small>
                                </Col>
                                <Col>
                                    <small>
                                        Inv: $
                                        {(results.availableINV * 1)
                                            .toFixed(0)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </small>
                                </Col>
                            </Row>
                            <p />
                            Unencumbered M&E could support incremental
                            availability (FLV):
                            <p />
                            <p className="text-center">
                                <strong>
                                    ~$
                                    {(results.availablePPE * 1)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </p>
                            <p />
                            RE FLV should provide incremental debt capacity in
                            the range:
                            <p />
                            <p className="text-center">
                                <strong>
                                    ~$
                                    {(results.availableRE * 1)
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </strong>
                            </p>
                            <p />
                            Of course, the above is all subject to your lenders
                            DD & u/w standards.
                            <br />
                        </div>
                        <p />
                        {/* <div>
                            <label id="label">Total Borrowing Base:</label>
                            <input
                                type="text"
                                value={results.totalBorrowingBase}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Excess Availability: </label>
                            <input
                                type="text"
                                value={
                                    results.excessAvailability < 0
                                        ? "No EA - collateral deficiency: " +
                                          -results.excessAvailability
                                        : results.excessAvailability
                                }
                                disabled
                            />
                        </div>
                         */}
                        <p />
                        <br />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block text-center"
                            value="Start Over"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default ABL;
