import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Spinner from "../../layout/Spinner";
import COPRMNASquawkItem from "./COPRMNASquawkItem";
import SearchBox from "./COPRMNASearchBox";
import { getCOPRMNASquawks } from "../../../actions/coprmnasquawk";
import DealWallTurret from "../DealWallTurret";
import ScrollToTopButton from "../../layout/ScrollToTopButton";

const COPRMNASquawks = ({
    getCOPRMNASquawks,
    coprmnasquawk: { coprmnasquawks, loading },
}) => {
    const { keyword } = useParams();

    useEffect(() => {
        getCOPRMNASquawks(keyword);
    }, [getCOPRMNASquawks, keyword]);

    return loading ? (
        <Spinner />
    ) : (
        <section className="container">
            <DealWallTurret />
            <p className="lead">
                <i className="fas fa-landmark"></i> &nbsp;Select M&A deals:
            </p>
            <Row className="dash-buttons">
                <Col>
                    <Link
                        to="/add-coprmnasquawk"
                        className="btn btn-primary mb-2"
                    >
                        <i className="text-primary"></i> Add Deal
                    </Link>
                </Col>
                <Col>
                    <SearchBox />
                </Col>
            </Row>
            <hr />
            {coprmnasquawks.length === 0 && (
                <div>
                    <strong>
                        No matching results...please revise your search!
                    </strong>
                </div>
            )}
            <Row md={4}>
                {coprmnasquawks.map((coprmnasquawk) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                        <COPRMNASquawkItem
                            key={coprmnasquawk._id}
                            coprmnasquawk={coprmnasquawk}
                        />
                    </Col>
                ))}
            </Row>
            <ScrollToTopButton />
        </section>
    );
};

COPRMNASquawks.propTypes = {
    getCOPRMNASquawks: PropTypes.func.isRequired,
    coprmnasquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprmnasquawk: state.coprmnasquawk,
});

export default connect(mapStateToProps, { getCOPRMNASquawks })(COPRMNASquawks);
