import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRFundSquawkItem from "./DetailedCOPRFundSquawkItem";
import COPRFundSquawkCommentForm from "./COPRFundSquawkCommentForm";
import COPRFundSquawkCommentItem from "./COPRFundSquawkCommentItem";
import { getCOPRFundSquawk } from "../../../actions/coprfundsquawk";

const COPRFundSquawk = ({
    getCOPRFundSquawk,
    coprfundsquawk: { coprfundsquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRFundSquawk(id);
    }, [getCOPRFundSquawk, id]);

    return loading || coprfundsquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/funds" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRFundSquawkItem coprfundsquawk={coprfundsquawk} />
            <p />
            <hr />
            <br />
            <COPRFundSquawkCommentForm coprfundsquawkId={coprfundsquawk._id} />
            <br />
            <div className="comments">
                {coprfundsquawk.comments.map((comment) => (
                    <COPRFundSquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprfundsquawkId={coprfundsquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRFundSquawk.propTypes = {
    getCOPRFundSquawk: PropTypes.func.isRequired,
    coprfundsquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprfundsquawk: state.coprfundsquawk,
});

export default connect(mapStateToProps, { getCOPRFundSquawk })(COPRFundSquawk);
