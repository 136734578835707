import {
    GET_LEGALSQUAWKS,
    GET_LEGALSQUAWK,
    LEGALSQUAWK_ERROR,
    UPDATE_LEGALLIKES,
    DELETE_LEGALSQUAWK,
    ADD_LEGALSQUAWK,
    ADD_LEGALSQUAWK_COMMENT,
    REMOVE_LEGALSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    legalsquawks: [],
    legalsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_LEGALSQUAWKS:
            return {
                ...state,
                legalsquawks: payload,
                loading: false,
            };
        case GET_LEGALSQUAWK:
            return {
                ...state,
                legalsquawk: payload,
                loading: false,
            };
        case ADD_LEGALSQUAWK:
            return {
                ...state,
                legalsquawks: [payload, ...state.legalsquawks],
                loading: false,
            };
        case DELETE_LEGALSQUAWK:
            return {
                ...state,
                legalsquawks: state.legalsquawks.filter(
                    (legalsquawk) => legalsquawk._id !== payload
                ),
                loading: false,
            };
        case LEGALSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_LEGALLIKES:
            return {
                ...state,
                legalsquawks: state.legalsquawks.map((legalsquawk) =>
                    legalsquawk._id === payload.id
                        ? { ...legalsquawk, likes: payload.likes }
                        : legalsquawk
                ),
                loading: false,
            };
        case ADD_LEGALSQUAWK_COMMENT:
            return {
                ...state,
                legalsquawk: { ...state.legalsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_LEGALSQUAWK_COMMENT:
            return {
                ...state,
                legalsquawk: {
                    ...state.legalsquawk,
                    comments: state.legalsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
