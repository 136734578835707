import {
    GET_FUNDSQUAWKS,
    GET_FUNDSQUAWK,
    FUNDSQUAWK_ERROR,
    UPDATE_FUNDLIKES,
    DELETE_FUNDSQUAWK,
    ADD_FUNDSQUAWK,
    ADD_FUNDSQUAWK_COMMENT,
    REMOVE_FUNDSQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    fundsquawks: [],
    fundsquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_FUNDSQUAWKS:
            return {
                ...state,
                fundsquawks: payload,
                loading: false,
            };
        case GET_FUNDSQUAWK:
            return {
                ...state,
                fundsquawk: payload,
                loading: false,
            };
        case ADD_FUNDSQUAWK:
            return {
                ...state,
                fundsquawks: [payload, ...state.fundsquawks],
                loading: false,
            };
        case DELETE_FUNDSQUAWK:
            return {
                ...state,
                fundsquawks: state.fundsquawks.filter(
                    (fundsquawk) => fundsquawk._id !== payload
                ),
                loading: false,
            };
        case FUNDSQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_FUNDLIKES:
            return {
                ...state,
                fundsquawks: state.fundsquawks.map((fundsquawk) =>
                    fundsquawk._id === payload.id
                        ? { ...fundsquawk, likes: payload.likes }
                        : fundsquawk
                ),
                loading: false,
            };
        case ADD_FUNDSQUAWK_COMMENT:
            return {
                ...state,
                fundsquawk: { ...state.fundsquawk, comments: payload },
                loading: false,
            };
        case REMOVE_FUNDSQUAWK_COMMENT:
            return {
                ...state,
                fundsquawk: {
                    ...state.fundsquawk,
                    comments: state.fundsquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
