import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addTombstone } from "../../actions/profile";

const AddTombstone = ({ addTombstone }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        client: "",
        amount: "",
        category: "",
        note: "",
        firm: "",
        role: "",
        when: "",
        contribution: "",
    });

    const { client, amount, category, note, firm, role, when, contribution } =
        formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    return (
        <section className="container">
            <h1 className="large text-primary">Add A Tombstone</h1>
            <p className="lead">
                <i className="fas fa-code-branch"></i> Add your closed
                transactions
            </p>
            <small>* = required field</small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    addTombstone(formData, navigate);
                }}
            >
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="* Client"
                        name="client"
                        value={client}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="* Amount (Ex. $100,000,000)"
                        name="amount"
                        value={amount}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Transaction Type: Refinancing, M&A, LBO, etc"
                        name="category"
                        value={category}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Firm"
                        name="firm"
                        value={firm}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Your Title"
                        name="role"
                        value={role}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <h4>Date</h4>
                    <input
                        type="date"
                        name="when"
                        value={when}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <textarea
                        name="contribution"
                        cols="30"
                        rows="5"
                        placeholder="Your Contribution"
                        value={contribution}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <div className="form-group">
                    <textarea
                        name="note"
                        cols="30"
                        rows="5"
                        placeholder="Notes"
                        value={note}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary my-1"
                />
                <Link className="btn btn-light my-1" to="/dashboard">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

AddTombstone.propTypes = {
    addTombstone: PropTypes.func.isRequired,
};

export default connect(null, { addTombstone })(AddTombstone);
