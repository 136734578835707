import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createProfile, getCurrentProfile } from "../../actions/profile";

const initialState = {
    company: "",
    website: "",
    location: "",
    bio: "",
    status: "",
    githubusername: "",
    skills: "",
    youtube: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    medium: "",
    reddit: "",
    angellist: "",
    github: "",
    substack: "",
    snapchat: "",
    tiktok: "",
    discord: "",
    other: "",
};

const CreateProfile = ({
    profile: { profile, loading },
    createProfile,
    getCurrentProfile,
}) => {
    const [formData, setFormData] = useState({ initialState });

    const [displaySocialInputs, toggleSocialInputs] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) getCurrentProfile();
        if (!loading && profile) {
            const profileData = { ...initialState };
            for (const key in profile) {
                if (key in profileData) profileData[key] = profile[key];
            }
            for (const key in profile.social) {
                if (key in profileData) profileData[key] = profile.social[key];
            }
            if (Array.isArray(profileData.skills))
                profileData.skills = profileData.skills.join(", ");
            setFormData(profileData);
        }
    }, [loading, getCurrentProfile, profile]);

    const {
        company,
        website,
        location,
        bio,
        status,
        githubusername,
        skills,
        youtube,
        facebook,
        twitter,
        instagram,
        linkedin,
        medium,
        reddit,
        angellist,
        github,
        substack,
        snapchat,
        tiktok,
        discord,
        other,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();
        createProfile(formData, navigate, profile ? true : false);
    };

    return (
        <section className="container">
            <h1 className="large text-primary">
                {profile ? "Edit Your Profile" : "Create Your Profile"}
            </h1>
            <p className="lead">
                <i className="fas fa-user"></i> Let's get some information to
                make your profile stand out
            </p>
            <small>* = required field</small>
            <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div className="form-group">
                    <select
                        name="status"
                        value={status}
                        onChange={(e) => onChange(e)}
                        required
                    >
                        <option value="0">* Select Professional Status</option>
                        <option value="Analyst">Analyst</option>
                        <option value="Associate">Associate</option>
                        <option value="Senior Associate">
                            Senior Associate
                        </option>
                        <option value="VP">VP</option>
                        <option value="Director">Director</option>
                        <option value="Managing Director">
                            Managing Director
                        </option>
                        <option value="Partner">Partner</option>
                        <option value="Managing Partner">
                            Managing Partner
                        </option>
                        <option value="Student or Learning">
                            Student or Learning
                        </option>
                        <option value="Instructor">
                            Instructor or Teacher
                        </option>
                        <option value="Intern">Intern</option>
                        <option value="General Muppet">General Muppet</option>
                        <option value="Factotum">Factotum</option>
                    </select>
                    <small className="form-text">
                        Give us an idea of where you are at in your career
                    </small>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="* Skills"
                        name="skills"
                        value={skills || ""}
                        onChange={(e) => onChange(e)}
                        required
                    />
                    <small className="form-text">
                        Please use comma separated values (eg. PE, LBO,
                        Financial Modeling, Direct Lending, M&A)
                    </small>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Company"
                        name="company"
                        value={company || ""}
                        onChange={(e) => onChange(e)}
                    />
                    <small className="form-text">
                        Could be your own company or your current employer
                    </small>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Website"
                        name="website"
                        value={website || ""}
                        onChange={(e) => onChange(e)}
                    />
                    <small className="form-text">
                        Could be your own or your employer's website
                    </small>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Location"
                        name="location"
                        value={location || ""}
                        onChange={(e) => onChange(e)}
                    />
                    <small className="form-text">
                        City & state suggested (eg. New York, NY)
                    </small>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Github Username"
                        name="githubusername"
                        value={githubusername || ""}
                        onChange={(e) => onChange(e)}
                    />
                    <small className="form-text">
                        Share your repos, include your Github username
                    </small>
                </div>
                <div className="form-group">
                    <textarea
                        placeholder="Lede (e.g. VC Investor)"
                        name="bio"
                        value={bio || ""}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                    <small className="form-text">
                        Tell us a little about yourself
                    </small>
                </div>

                <div className="my-2">
                    <button
                        onClick={() => toggleSocialInputs(!displaySocialInputs)}
                        type="button"
                        className="btn btn-light"
                    >
                        Add Social Network Links
                    </button>
                    <span>Optional</span>
                </div>

                {displaySocialInputs && (
                    <Fragment>
                        <div className="form-group social-input">
                            <i className="fab fa-twitter fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Twitter URL"
                                name="twitter"
                                value={twitter || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>

                        <div className="form-group social-input">
                            <i className="fab fa-facebook fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Facebook URL"
                                name="facebook"
                                value={facebook || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>

                        <div className="form-group social-input">
                            <i className="fab fa-snapchat fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Snapchat URL"
                                name="snapchat"
                                value={snapchat || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>

                        <div className="form-group social-input">
                            <i className="fab fa-linkedin fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Linkedin URL"
                                name="linkedin"
                                value={linkedin || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>

                        <div className="form-group social-input">
                            <i className="fab fa-instagram fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Instagram URL"
                                name="instagram"
                                value={instagram || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-medium fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Medium URL"
                                name="medium"
                                value={medium || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-reddit fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Reddit URL"
                                name="reddit"
                                value={reddit || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-angellist fa-2x"></i>
                            <input
                                type="text"
                                placeholder="AngelList URL"
                                name="angellist"
                                value={angellist || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i></i>
                            <input
                                type="text"
                                placeholder="Substack URL"
                                name="substack"
                                value={substack || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-tiktok fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Tiktok URL"
                                name="tiktok"
                                value={tiktok || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-discord fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Discord URL"
                                name="discord"
                                value={discord || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-youtube fa-2x"></i>
                            <input
                                type="text"
                                placeholder="YouTube URL"
                                name="youtube"
                                value={youtube || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i className="fab fa-github fa-2x"></i>
                            <input
                                type="text"
                                placeholder="Github URL"
                                name="github"
                                value={github || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                        <div className="form-group social-input">
                            <i></i>
                            <input
                                type="text"
                                placeholder="Other"
                                name="other"
                                value={other || ""}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Fragment>
                )}

                <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary my-1"
                />
                <Link className="btn btn-light my-1" to="/dashboard">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

CreateProfile.propTypes = {
    createProfile: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
    CreateProfile
);
