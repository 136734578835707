import React, { useState } from "react";

const AccretionDilution = () => {
    //
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        taxRate: "",
        costOfDebt: "",
        interestOnCash: "",
        acquirorPrice: "",
        acquirorShares: "",
        acquirorPE: "",
        targetPrice: "",
        targetShares: "",
        targetPE: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        acquirorEPS: "",
        acquirorMVE: "",
        acquirorNI: "",
        targetEPS: "",
        targetMVE: "",
        targetNI: "",
        stockPostAcqEPS: "",
        stockAccretionDilution: "",
        debtPostAcqEPS: "",
        debtAccretionDilution: "",
        cashPostAcqEPS: "",
        cashAccretionDilution: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            taxRate,
            costOfDebt,
            interestOnCash,
            acquirorPrice,
            acquirorShares,
            acquirorPE,
            targetPrice,
            targetShares,
            targetPE,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !taxRate ||
            !costOfDebt ||
            !interestOnCash ||
            !acquirorPrice ||
            !acquirorShares ||
            !acquirorPE ||
            !targetPrice ||
            !targetShares ||
            !targetPE
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(taxRate) ||
            isNaN(costOfDebt) ||
            isNaN(interestOnCash) ||
            isNaN(acquirorPrice) ||
            isNaN(acquirorShares) ||
            isNaN(acquirorPE) ||
            isNaN(targetPrice) ||
            isNaN(targetShares) ||
            isNaN(targetPE)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(taxRate) <= 0 ||
            Number(costOfDebt) <= 0 ||
            Number(interestOnCash) <= 0 ||
            Number(acquirorPrice) <= 0 ||
            Number(acquirorShares) <= 0 ||
            Number(acquirorPE) <= 0 ||
            Number(targetPrice) <= 0 ||
            Number(targetShares) <= 0 ||
            Number(targetPE) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        taxRate,
        costOfDebt,
        interestOnCash,
        acquirorPrice,
        acquirorShares,
        acquirorPE,
        targetPrice,
        targetShares,
        targetPE,
    }) => {
        // Decimalize
        const tax = Number(taxRate) / 100;
        const Rd = Number(costOfDebt) / 100;
        const cashInt = Number(interestOnCash) / 100;
        // MVE, NI, & EPS calcs
        const acquirorMVE = Number(acquirorPrice) * Number(acquirorShares);
        const acquirorNI = Number(acquirorMVE) / Number(acquirorPE);
        const acquirorEPS = Number(acquirorNI) / Number(acquirorShares);
        const targetMVE = Number(targetPrice) * Number(targetShares);
        const targetNI = Number(targetMVE) / Number(targetPE);
        const targetEPS = Number(targetNI) / Number(targetShares);
        const impliedStockIssued = targetMVE / acquirorPrice;
        // Dilution scenarios: all stock, all debt, and all cash
        const stockPostAcqEPS =
            (acquirorNI + targetNI) /
            (Number(acquirorShares) + impliedStockIssued);
        const stockAccretionDilution = stockPostAcqEPS - acquirorEPS;

        const debtPostAcqEPS =
            (acquirorNI + targetNI - targetMVE * Rd * (1 - tax)) /
            acquirorShares;
        const debtAccretionDilution = debtPostAcqEPS - acquirorEPS;

        const cashPostAcqEPS =
            (acquirorNI + targetNI - targetMVE * cashInt * (1 - tax)) /
            acquirorShares;
        const cashAccretionDilution = cashPostAcqEPS - acquirorEPS;

        if (isFinite(acquirorEPS)) {
            const computedAcquirorEPS = acquirorEPS.toFixed(2);
            const computedAcquirorMVE = acquirorMVE.toFixed(2);
            const computedAcquirorNI = acquirorNI.toFixed(2);
            const computedTargetEPS = targetEPS.toFixed(2);
            const computedTargetMVE = targetMVE.toFixed(2);
            const computedTargetNI = targetNI.toFixed(2);
            const computedStockPostAcqEPS = stockPostAcqEPS.toFixed(2);
            const computedStockAccretionDilution =
                stockAccretionDilution.toFixed(2);
            const computedDebtPostAcqEPS = debtPostAcqEPS.toFixed(2);
            const computedDebtAccretionDilution =
                debtAccretionDilution.toFixed(2);
            const computedCashPostAcqEPS = cashPostAcqEPS.toFixed(2);
            const computedCashAccretionDilution =
                cashAccretionDilution.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                acquirorEPS: computedAcquirorEPS,
                acquirorMVE: computedAcquirorMVE,
                acquirorNI: computedAcquirorNI,
                targetEPS: computedTargetEPS,
                targetMVE: computedTargetMVE,
                targetNI: computedTargetNI,
                stockPostAcqEPS: computedStockPostAcqEPS,
                stockAccretionDilution: computedStockAccretionDilution,
                debtPostAcqEPS: computedDebtPostAcqEPS,
                debtAccretionDilution: computedDebtAccretionDilution,
                cashPostAcqEPS: computedCashPostAcqEPS,
                cashAccretionDilution: computedCashAccretionDilution,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            taxRate: "",
            costOfDebt: "",
            interestOnCash: "",
            acquirorPrice: "",
            acquirorShares: "",
            acquirorPE: "",
            targetPrice: "",
            targetShares: "",
            targetPE: "",
        });

        setResults({
            acquirorEPS: "",
            acquirorMVE: "",
            acquirorNI: "",
            targetEPS: "",
            targetMVE: "",
            targetNI: "",
            stockPostAcqEPS: "",
            stockAccretionDilution: "",
            debtPostAcqEPS: "",
            debtAccretionDilution: "",
            cashPostAcqEPS: "",
            cashAccretionDilution: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>M&A Accretion / Dilution</strong>
                <br />
                <small>(3 Acq. Scenarios: stock, debt, cash)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Tax Rate %:</small>
                                <input
                                    type="text"
                                    name="taxRate"
                                    placeholder=""
                                    value={userValues.taxRate}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Cost of Debt %:</small>
                                <input
                                    type="text"
                                    name="costOfDebt"
                                    placeholder=""
                                    value={userValues.costOfDebt}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Interest on Cash %:</small>
                                <input
                                    type="text"
                                    name="interestOnCash"
                                    placeholder=""
                                    value={userValues.interestOnCash}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Acquiror Share Price:</small>
                                <input
                                    type="text"
                                    name="acquirorPrice"
                                    placeholder=""
                                    value={userValues.acquirorPrice}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Acquiror # Shares Outstanding:</small>
                                <input
                                    type="text"
                                    name="acquirorShares"
                                    placeholder=""
                                    value={userValues.acquirorShares}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Acquiror P/E:</small>
                                <input
                                    type="text"
                                    name="acquirorPE"
                                    placeholder=""
                                    value={userValues.acquirorPE}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Target Share Price:</small>
                                <input
                                    type="text"
                                    name="targetPrice"
                                    placeholder=""
                                    value={userValues.targetPrice}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Target # Shares Outstanding:</small>
                                <input
                                    type="text"
                                    name="targetShares"
                                    placeholder=""
                                    value={userValues.targetShares}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Target P/E:</small>
                                <input
                                    type="text"
                                    name="targetPE"
                                    placeholder=""
                                    value={userValues.targetPE}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            <strong>Inputs</strong>
                            <br />
                            Acquiror Price / Shares / PE:&nbsp;
                            {userValues.acquirorPrice}
                            &nbsp;/&nbsp;
                            {userValues.acquirorShares}
                            &nbsp;/&nbsp;
                            {userValues.acquirorPE}
                            <br />
                            Target Price / Shares / PE:&nbsp;
                            {userValues.targetPrice}
                            &nbsp;/&nbsp;
                            {userValues.targetShares}
                            &nbsp;/&nbsp;{userValues.targetPE}
                            <br />
                            Tax Rate / Rd / Cash Int.:&nbsp;
                            {userValues.taxRate}%&nbsp;/&nbsp;
                            {userValues.costOfDebt}%&nbsp;/&nbsp;
                            {userValues.interestOnCash}%<p />
                            <strong>
                                Implied Acquiror EPS:&nbsp;{results.acquirorEPS}
                            </strong>
                            <p />
                            <strong>Pro Forma Accretion / Dilution</strong>
                            <br />
                            All-stock:&nbsp;
                            {results.stockAccretionDilution < 0
                                ? (results.stockAccretionDilution * 1).toFixed(
                                      2
                                  ) + " dilutive"
                                : (results.stockAccretionDilution * 1).toFixed(
                                      2
                                  ) + " accretive"}
                            <p />
                            All-debt:&nbsp;
                            {results.debtAccretionDilution < 0
                                ? (results.debtAccretionDilution * 1).toFixed(
                                      2
                                  ) + " dilutive"
                                : (results.debtAccretionDilution * 1).toFixed(
                                      2
                                  ) + " accretive"}
                            <p />
                            All-cash:&nbsp;
                            {results.cashAccretionDilution < 0
                                ? (results.cashAccretionDilution * 1).toFixed(
                                      2
                                  ) + " dilutive"
                                : (results.cashAccretionDilution * 1).toFixed(
                                      2
                                  ) + " accretive"}
                            <p />
                        </h6>
                        <p />
                        <h6>
                            <strong>Pro Forma Post-Acquisition EPS</strong>
                        </h6>
                        <div>
                            <label id="label">All Stock:</label>
                            <input
                                type="text"
                                value={(results.stockPostAcqEPS * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">All Debt:</label>
                            <input
                                type="text"
                                value={(results.debtPostAcqEPS * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">All Cash:</label>
                            <input
                                type="text"
                                value={(results.cashPostAcqEPS * 1).toFixed(2)}
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default AccretionDilution;
