import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRCOLLSQUAWK,
    UPDATE_COPRCOLLSQUAWK,
    GET_COPRCOLLSQUAWKS,
    GET_COPRCOLLSQUAWKS_REPO,
    GET_COPRCOLLSQUAWK,
    COPRCOLLSQUAWK_ERROR,
    UPDATE_COPRCOLLLIKES,
    ADD_COPRCOLLSQUAWK,
    ADD_COPRCOLLSQUAWK_COMMENT,
    REMOVE_COPRCOLLSQUAWK_COMMENT,
} from "./types";

// Get COPR collsquawks
export const getCOPRCollSquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprcollsquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRCOLLSQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR collsquawks -- user repo
export const getCOPRCollSquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(
                `/coprcollsquawks/repo?keyword=${keyword}`
            );

            dispatch({
                type: GET_COPRCOLLSQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprcollsquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRCOLLLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRCOLLSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprcollsquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRCOLLLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRCOLLSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR collsquawk
export const deleteCOPRCollSquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprcollsquawks/${id}`);

            dispatch({
                type: DELETE_COPRCOLLSQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR collsquawk
export const addCOPRCollSquawk = (formdata) => async (dispatch) => {
    // const config = {
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    // };

    try {
        const res = await api.post("/coprcollsquawks", formdata);

        dispatch({
            type: ADD_COPRCOLLSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRCOLLSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR collsquawk
export const updateCOPRCollSquawk =
    (id, updatedFormdata) => async (dispatch) => {
        try {
            const res = await api.put(
                `/coprcollsquawks/${id}`,
                updatedFormdata
            );

            dispatch({
                type: UPDATE_COPRCOLLSQUAWK,
                payload: res.data,
            });

            dispatch(setAlert("Deal Updated", "success"));
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR collsquawk
export const getCOPRCollSquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprcollsquawks/${id}`);

        dispatch({
            type: GET_COPRCOLLSQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRCOLLSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR collsquawk comment
export const addCOPRCollSquawkComment =
    (coprcollsquawkId, formdata) => async (dispatch) => {
        // const config = {
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        // };

        try {
            const res = await api.post(
                `/coprcollsquawks/comment/${coprcollsquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRCOLLSQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR collsquawk comment
export const deleteCOPRCollSquawkComment =
    (coprcollsquawkId, coprcollcommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprcollsquawks/comment/${coprcollsquawkId}/${coprcollcommentId}`
            );

            dispatch({
                type: REMOVE_COPRCOLLSQUAWK_COMMENT,
                payload: coprcollcommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRCOLLSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
