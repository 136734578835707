import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Footer from "./components/layout/Footer";
// MAJOR React Router v6 updates below: move all routing to App.js
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Alert from "./components/layout/Alert";
import Dashboard from "./components/dashboard/Dashboard";
import CreateProfile from "./components/profile-forms/CreateProfile";
import EditProfile from "./components/profile-forms/EditProfile";
import AddExperience from "./components/profile-forms/AddExperience";
import AddEducation from "./components/profile-forms/AddEducation";
import AddTombstone from "./components/profile-forms/AddTombstone";
import Profile from "./components/profile/Profile";
// React Router v6: COPR routes
import COPRSquawks from "./components/squawks/copr/COPRSquawks";
import COPRSquawksRepo from "./components/squawks/copr/COPRSquawksRepo";
import COPRSquawkForm from "./components/squawks/copr/COPRSquawkForm";
import EditCOPRSquawkForm from "./components/squawks/copr/EditCOPRSquawkForm";
import COPRBKSquawks from "./components/squawks/coprbk/COPRBKSquawks";
import COPRBKSquawksRepo from "./components/squawks/coprbk/COPRBKSquawksRepo";
import COPRBKSquawkForm from "./components/squawks/coprbk/COPRBKSquawkForm";
import EditCOPRBKSquawkForm from "./components/squawks/coprbk/EditCOPRBKSquawkForm";
import COPRPESquawks from "./components/squawks/coprpe/COPRPESquawks";
import COPRPESquawksRepo from "./components/squawks/coprpe/COPRPESquawksRepo";
import COPRPESquawkForm from "./components/squawks/coprpe/COPRPESquawkForm";
import EditCOPRPESquawkForm from "./components/squawks/coprpe/EditCOPRPESquawkForm";
import COPRVCSquawks from "./components/squawks/coprvc/COPRVCSquawks";
import COPRVCSquawksRepo from "./components/squawks/coprvc/COPRVCSquawksRepo";
import COPRVCSquawkForm from "./components/squawks/coprvc/COPRVCSquawkForm";
import EditCOPRVCSquawkForm from "./components/squawks/coprvc/EditCOPRVCSquawkForm";
import COPRMNASquawks from "./components/squawks/coprmna/COPRMNASquawks";
import COPRMNASquawksRepo from "./components/squawks/coprmna/COPRMNASquawksRepo";
import COPRMNASquawkForm from "./components/squawks/coprmna/COPRMNASquawkForm";
import EditCOPRMNASquawkForm from "./components/squawks/coprmna/EditCOPRMNASquawkForm";
import COPRFundSquawks from "./components/squawks/coprfund/COPRFundSquawks";
import COPRFundSquawksRepo from "./components/squawks/coprfund/COPRFundSquawksRepo";
import COPRFundSquawkForm from "./components/squawks/coprfund/COPRFundSquawkForm";
import EditCOPRFundSquawkForm from "./components/squawks/coprfund/EditCOPRFundSquawkForm";
import COPRCollSquawks from "./components/squawks/coprcoll/COPRCollSquawks";
import COPRCollSquawksRepo from "./components/squawks/coprcoll/COPRCollSquawksRepo";
import COPRCollSquawkForm from "./components/squawks/coprcoll/COPRCollSquawkForm";
import EditCOPRCollSquawkForm from "./components/squawks/coprcoll/EditCOPRCollSquawkForm";
// React Router v6: single COPR display
import COPRSquawk from "./components/squawk/copr/COPRSquawk";
import COPRBKSquawk from "./components/squawk/coprbk/COPRBKSquawk";
import COPRPESquawk from "./components/squawk/coprpe/COPRPESquawk";
import COPRVCSquawk from "./components/squawk/coprvc/COPRVCSquawk";
import COPRMNASquawk from "./components/squawk/coprmna/COPRMNASquawk";
import COPRFundSquawk from "./components/squawk/coprfund/COPRFundSquawk";
import COPRCollSquawk from "./components/squawk/coprcoll/COPRCollSquawk";
// QuickCalcs
import QuickCalcs from "./components/calcs/QuickCalcs";
// iBankr
import IBankr from "./components/ai/IBankr";
// Quad
// import Quad from "./components/ai/IBankr";
// General
import About from "./components/layout/About";
import Preview from "./components/layout/Preview";
import NotFound from "./components/layout/NotFound";
// import ProfileNotFound from "./components/layout/ProfileNotFound";
import PrivateRoute from "./components/routing/PrivateRoute";
import COPRDealRepo from "./components/squawks/COPRDealRepo";
import { LOGOUT } from "./actions/types";

// NotFound workaround for Landing page => May 2022 REMOVED for React Router v6 changes
// import Routes from "./components/routing/Routes";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

import "./App.css";

const App = () => {
    useEffect(() => {
        // check for token in local storage
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        store.dispatch(loadUser());

        // log user out from all tabs if they logout in one tab
        window.addEventListener("storage", () => {
            if (!localStorage.token) store.dispatch({ type: LOGOUT });
        });
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Navbar />
                <Alert />
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="register" element={<Register />} />
                    <Route path="login" element={<Login />} />
                    <Route path="about" element={<About />} />
                    <Route path="preview" element={<Preview />} />
                    <Route
                        path="profile/:id"
                        element={<PrivateRoute component={Profile} />}
                    />
                    <Route
                        path="dashboard"
                        element={<PrivateRoute component={Dashboard} />}
                    />
                    <Route
                        path="create-profile"
                        element={<PrivateRoute component={CreateProfile} />}
                    />
                    <Route
                        path="edit-profile"
                        element={<PrivateRoute component={EditProfile} />}
                    />
                    <Route
                        path="add-experience"
                        element={<PrivateRoute component={AddExperience} />}
                    />
                    <Route
                        path="add-education"
                        element={<PrivateRoute component={AddEducation} />}
                    />
                    <Route
                        path="add-tombstone"
                        element={<PrivateRoute component={AddTombstone} />}
                    />
                    <Route
                        path="privatecredit"
                        element={<PrivateRoute component={COPRSquawks} />}
                    />
                    <Route
                        path="privatecredit/search/:keyword"
                        element={<PrivateRoute component={COPRSquawks} />}
                    />
                    <Route
                        path="privatecredit/repo"
                        element={<PrivateRoute component={COPRSquawksRepo} />}
                    />
                    <Route
                        path="privatecredit/repo/search/:keyword"
                        element={<PrivateRoute component={COPRSquawksRepo} />}
                    />
                    <Route
                        path="privatecredit/:id"
                        element={<PrivateRoute component={COPRSquawk} />}
                    />
                    <Route
                        path="add-coprsquawk"
                        element={<PrivateRoute component={COPRSquawkForm} />}
                    />
                    <Route
                        path="privatecredit/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRSquawkForm} />
                        }
                    />
                    <Route
                        path="restructuring"
                        element={<PrivateRoute component={COPRBKSquawks} />}
                    />
                    <Route
                        path="restructuring/search/:keyword"
                        element={<PrivateRoute component={COPRBKSquawks} />}
                    />
                    <Route
                        path="restructuring/repo"
                        element={<PrivateRoute component={COPRBKSquawksRepo} />}
                    />
                    <Route
                        path="restructuring/repo/search/:keyword"
                        element={<PrivateRoute component={COPRBKSquawksRepo} />}
                    />
                    <Route
                        path="restructuring/:id"
                        element={<PrivateRoute component={COPRBKSquawk} />}
                    />
                    <Route
                        path="add-coprbksquawk"
                        element={<PrivateRoute component={COPRBKSquawkForm} />}
                    />
                    <Route
                        path="restructuring/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRBKSquawkForm} />
                        }
                    />
                    <Route
                        path="privateequity"
                        element={<PrivateRoute component={COPRPESquawks} />}
                    />
                    <Route
                        path="privateequity/search/:keyword"
                        element={<PrivateRoute component={COPRPESquawks} />}
                    />
                    <Route
                        path="privateequity/repo"
                        element={<PrivateRoute component={COPRPESquawksRepo} />}
                    />
                    <Route
                        path="privateequity/repo/search/:keyword"
                        element={<PrivateRoute component={COPRPESquawksRepo} />}
                    />
                    <Route
                        path="privateequity/:id"
                        element={<PrivateRoute component={COPRPESquawk} />}
                    />
                    <Route
                        path="add-coprpesquawk"
                        element={<PrivateRoute component={COPRPESquawkForm} />}
                    />
                    <Route
                        path="privateequity/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRPESquawkForm} />
                        }
                    />
                    <Route
                        path="venturecapital"
                        element={<PrivateRoute component={COPRVCSquawks} />}
                    />
                    <Route
                        path="venturecapital/search/:keyword"
                        element={<PrivateRoute component={COPRVCSquawks} />}
                    />
                    <Route
                        path="venturecapital/repo"
                        element={<PrivateRoute component={COPRVCSquawksRepo} />}
                    />
                    <Route
                        path="venturecapital/repo/search/:keyword"
                        element={<PrivateRoute component={COPRVCSquawksRepo} />}
                    />
                    <Route
                        path="venturecapital/:id"
                        element={<PrivateRoute component={COPRVCSquawk} />}
                    />
                    <Route
                        path="add-coprvcsquawk"
                        element={<PrivateRoute component={COPRVCSquawkForm} />}
                    />
                    <Route
                        path="venturecapital/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRVCSquawkForm} />
                        }
                    />
                    <Route
                        path="mergers&acquisitions"
                        element={<PrivateRoute component={COPRMNASquawks} />}
                    />
                    <Route
                        path="mergers&acquisitions/search/:keyword"
                        element={<PrivateRoute component={COPRMNASquawks} />}
                    />
                    <Route
                        path="mergers&acquisitions/repo"
                        element={
                            <PrivateRoute component={COPRMNASquawksRepo} />
                        }
                    />
                    <Route
                        path="mergers&acquisitions/repo/search/:keyword"
                        element={
                            <PrivateRoute component={COPRMNASquawksRepo} />
                        }
                    />
                    <Route
                        path="mergers&acquisitions/:id"
                        element={<PrivateRoute component={COPRMNASquawk} />}
                    />
                    <Route
                        path="add-coprmnasquawk"
                        element={<PrivateRoute component={COPRMNASquawkForm} />}
                    />
                    <Route
                        path="mergers&acquisitions/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRMNASquawkForm} />
                        }
                    />
                    <Route
                        path="funds"
                        element={<PrivateRoute component={COPRFundSquawks} />}
                    />
                    <Route
                        path="funds/search/:keyword"
                        element={<PrivateRoute component={COPRFundSquawks} />}
                    />
                    <Route
                        path="funds/repo"
                        element={
                            <PrivateRoute component={COPRFundSquawksRepo} />
                        }
                    />
                    <Route
                        path="funds/repo/search/:keyword"
                        element={
                            <PrivateRoute component={COPRFundSquawksRepo} />
                        }
                    />
                    <Route
                        path="funds/:id"
                        element={<PrivateRoute component={COPRFundSquawk} />}
                    />
                    <Route
                        path="add-coprfundsquawk"
                        element={
                            <PrivateRoute component={COPRFundSquawkForm} />
                        }
                    />
                    <Route
                        path="funds/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRFundSquawkForm} />
                        }
                    />
                    <Route
                        path="collateral"
                        element={<PrivateRoute component={COPRCollSquawks} />}
                    />
                    <Route
                        path="collateral/search/:keyword"
                        element={<PrivateRoute component={COPRCollSquawks} />}
                    />
                    <Route
                        path="collateral/repo"
                        element={
                            <PrivateRoute component={COPRCollSquawksRepo} />
                        }
                    />
                    <Route
                        path="collateral/repo/search/:keyword"
                        element={
                            <PrivateRoute component={COPRCollSquawksRepo} />
                        }
                    />
                    <Route
                        path="collateral/:id"
                        element={<PrivateRoute component={COPRCollSquawk} />}
                    />
                    <Route
                        path="add-coprcollsquawk"
                        element={
                            <PrivateRoute component={COPRCollSquawkForm} />
                        }
                    />
                    <Route
                        path="collateral/edit/:id"
                        element={
                            <PrivateRoute component={EditCOPRCollSquawkForm} />
                        }
                    />
                    <Route
                        path="quickcalcs"
                        element={<PrivateRoute component={QuickCalcs} />}
                    />
                    <Route
                        path="ibankr"
                        element={<PrivateRoute component={IBankr} />}
                    />
                    {/* <Route path="quad" element={<PrivateRoute component={Quad} />} /> */}
                    <Route
                        path="repository"
                        element={<PrivateRoute component={COPRDealRepo} />}
                    />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
                <Footer />
            </Router>
        </Provider>
    );
};

export default App;
