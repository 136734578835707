import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRMNASquawkItem = ({
    coprmnasquawk: {
        _id,
        user,
        username,
        coprmnasquawkDate,
        coprmnasquawkBuyer,
        coprmnasquawkBuyerCompanyUrl,
        coprmnasquawkTarget,
        coprmnasquawkTargetCompanyUrl,
        // coprmnasquawkPRUrl,
        coprmnasquawkSector,
        coprmnasquawkSubSector,
        coprmnasquawkSize,
        coprmnasquawkStructure,
        coprmnasquawkEV,
        coprmnasquawkAdvisor,
        coprmnasquawkLender,
        coprmnasquawkCounsel,
        coprmnasquawk3PDD,
        coprmnasquawkEVToLTMSales,
        coprmnasquawkEVToNTMSales,
        coprmnasquawkEVToLTMEBIT,
        coprmnasquawkEVToNTMEBIT,
        coprmnasquawkEVToLTMEBITDA,
        coprmnasquawkEVToNTMEBITDA,
        coprmnasquawkNotes,
        coprmnasquawkTxnType,
        // coprmnasquawkStatus,
        // coprmnasquawkMarket,
        // coprmnasquawkTicker,
        // coprmnasquawkExchange,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <strong>Summary</strong>
            <Row>
                <Col>
                    <small>
                        Purchase Price / Equity Value: $
                        {coprmnasquawkSize
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    {/* {coprmnasquawkPRUrl === "" ? ( */}
                    <small>Transaction Type: {coprmnasquawkTxnType}</small>
                    {/* ) : (
                        <a href={coprmnasquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <small>
                                Transaction Type: {coprmnasquawkTxnType}
                            </small>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        Transaction Date:{" "}
                        {new Date(coprmnasquawkDate).toLocaleDateString(
                            "en-US",
                            {
                                month: "long",
                                year: "numeric",
                            }
                        )}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Target: {coprmnasquawkTarget}</small>
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprmnasquawkTargetCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprmnasquawkTargetCompanyUrl}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Buyer: {coprmnasquawkBuyer}</small>
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprmnasquawkBuyerCompanyUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprmnasquawkBuyerCompanyUrl}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Sector: {coprmnasquawkSector}</small>
                </Col>
                <Col>
                    <small>Sub-sector: {coprmnasquawkSubSector}</small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Enterprise Value:{" "}
                        {coprmnasquawkEV
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>{coprmnasquawkStructure}</small>
                </Col>
            </Row>
            <br />
            <strong>Transaction Metrics</strong>
            <Row>
                <Col>
                    <small>
                        EV / LTM Sales(x): {coprmnasquawkEVToLTMSales}
                    </small>
                </Col>
                <Col>
                    <small>
                        EV / NTM Sales(x): {coprmnasquawkEVToNTMSales}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>EV / LTM EBIT(x): {coprmnasquawkEVToLTMEBIT}</small>
                </Col>
                <Col>
                    <small>EV / NTM EBIT(x): {coprmnasquawkEVToNTMEBIT}</small>
                </Col>
                <Col></Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        EV / LTM EBITDA(x): {coprmnasquawkEVToLTMEBITDA}
                    </small>
                </Col>
                <Col>
                    <small>
                        EV / NTM EBITDA(x): {coprmnasquawkEVToNTMEBITDA}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <br />
            <strong>Participants</strong>
            <Row>
                <Col>
                    <small>Advisor(s): {coprmnasquawkAdvisor}</small>
                </Col>
                <Col>
                    <small>Lender(s): {coprmnasquawkLender}</small>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small>Legal Counsel: {coprmnasquawkCounsel}</small>
                </Col>
                <Col>
                    <small>3PDD: {coprmnasquawk3PDD}</small>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small>Notes: {coprmnasquawkNotes}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRMNASquawkItem.propTypes = {
    coprmnasquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRMNASquawkItem;
