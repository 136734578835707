import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { addCOPRSquawk } from "../../../actions/coprsquawk";

const COPRSquawkForm = ({ addCOPRSquawk }) => {
    const navigate = useNavigate();
    const routePath = useLocation();
    const NavToPageTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        NavToPageTop();
    }, [routePath]);

    const [formData, setFormData] = useState({
        coprsquawkTransactionDate: "",
        // coprsquawkMarket: "",
        // coprsquawkTicker: "",
        // coprsquawkExchange: "",
        coprsquawkBorrower: "",
        coprsquawkBorrowerUrl: "",
        coprsquawkPRUrl: "",
        coprsquawkSponsor: "",
        coprsquawkSector: "",
        coprsquawkSubSector: "",
        coprsquawkLender: "",
        coprsquawkDebtAmount: "",
        coprsquawkDebtStructure: "",
        coprsquawkPricing: "",
        coprsquawkARAdvRate: "",
        coprsquawkInvAdvRate: "",
        coprsquawkREAdvRate: "",
        coprsquawkMNEAdvRate: "",
        coprsquawkAllInYield: "",
        coprsquawkReferenceRate: "",
        coprsquawkSpreadBps: "",
        coprsquawkPricingFloor: "",
        coprsquawkClosingFees: "",
        coprsquawkLtmRevenue: "",
        coprsquawkLtmEBITDA: "",
        coprsquawkTransactionDesc: "",
        text: "",
    });

    const {
        coprsquawkTransactionDate,
        // coprsquawkMarket,
        // coprsquawkTicker,
        // coprsquawkExchange,
        coprsquawkBorrower,
        coprsquawkBorrowerUrl,
        coprsquawkPRUrl,
        coprsquawkSponsor,
        coprsquawkSector,
        coprsquawkSubSector,
        coprsquawkLender,
        coprsquawkDebtAmount,
        coprsquawkDebtStructure,
        coprsquawkPricing,
        coprsquawkARAdvRate,
        coprsquawkInvAdvRate,
        coprsquawkREAdvRate,
        coprsquawkMNEAdvRate,
        coprsquawkAllInYield,
        coprsquawkReferenceRate,
        coprsquawkSpreadBps,
        coprsquawkPricingFloor,
        coprsquawkClosingFees,
        coprsquawkLtmRevenue,
        coprsquawkLtmEBITDA,
        coprsquawkTransactionDesc,
        text,
    } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
    // state to storage error message
    const [error, setError] = useState("");
    // Manage validations and error messages
    const isValid = () => {
        let actualError = "";
        // Validate if there are values
        if (!coprsquawkDebtAmount) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (isNaN(coprsquawkDebtAmount)) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (Number(coprsquawkDebtAmount) <= 0) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    return (
        <section className="container">
            <h1 className="text-primary">Add Deal</h1>
            <small>* = required field (48 character limit)</small>
            <p />
            <small>
                <strong>{error}</strong>
            </small>
            <form
                className="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isValid()) {
                        setError("");
                        addCOPRSquawk(formData);
                        navigate(`/privatecredit`);
                        NavToPageTop();
                    }
                }}
            >
                <div className="form-group">
                    <small>* Deal Date</small>
                    <input
                        type="date"
                        maxLength="48"
                        name="coprsquawkTransactionDate"
                        value={coprsquawkTransactionDate}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>* Deal Size (e.g. 55000000)</small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkDebtAmount"
                        value={coprsquawkDebtAmount}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>
                        * Deal Type (e.g. Acquisition, Refinancing, LBO)
                    </small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkTransactionDesc"
                        value={coprsquawkTransactionDesc}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>
                        * Deal Structure (e.g. Revolver, Term Loan, Senior
                        Secured)
                    </small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkDebtStructure"
                        value={coprsquawkDebtStructure}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group hide-sm">
                    <small>Deal Pricing (e.g. L + 200, 10.50%, etc)</small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkPricing"
                        value={coprsquawkPricing}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <select
                        name="coprsquawkSector"
                        value={coprsquawkSector}
                        onChange={(e) => onChange(e)}
                        required
                    >
                        <option value="0">* Select the Sector</option>
                        <option value="Communication Services">
                            Communication Services
                        </option>
                        <option value="Consumer Discretionary">
                            Consumer Discretionary
                        </option>
                        <option value="Consumer Staples">
                            Consumer Staples
                        </option>
                        <option value="Energy">Energy</option>
                        <option value="Financials">Financials</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Industrials">Industrials</option>
                        <option value="Materials">Materials</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Technology">Technology</option>
                        <option value="Utilities">Utilities</option>
                    </select>
                </div>
                <div className="form-group">
                    <small>
                        * Sub-Sector (e.g. Software, Retail, Services)
                    </small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkSubSector"
                        value={coprsquawkSubSector}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>* Borrower</small>
                    <input
                        type="text"
                        maxLength="30"
                        placeholder=""
                        name="coprsquawkBorrower"
                        value={coprsquawkBorrower}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>* Borrower URL (https://)</small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkBorrowerUrl"
                        value={coprsquawkBorrowerUrl}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group hide-sm">
                    <small>Press Release URL (https://)</small>
                    <input
                        type="text"
                        placeholder=""
                        name="coprsquawkPRUrl"
                        value={coprsquawkPRUrl}
                        onChange={(e) => onChange(e)}
                    />
                </div>
                <div className="form-group">
                    <small>* Sponsor (or enter "Non-sponsored")</small>
                    <input
                        type="text"
                        maxLength="48"
                        placeholder=""
                        name="coprsquawkSponsor"
                        value={coprsquawkSponsor}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group">
                    <small>* Lender (lead only)</small>
                    <input
                        type="text"
                        maxLength="30"
                        placeholder=""
                        name="coprsquawkLender"
                        value={coprsquawkLender}
                        onChange={(e) => onChange(e)}
                        required
                    />
                </div>
                <div className="form-group hide-sm">
                    <textarea
                        name="text"
                        cols="30"
                        rows="5"
                        placeholder="Add Transaction Details..."
                        value={text}
                        onChange={(e) => onChange(e)}
                    ></textarea>
                </div>
                <hr className="hide-sm" />
                <p className="hide-sm">
                    <strong>Optional</strong> -- Details:
                </p>
                <br />
                <span className="hide-sm">
                    <strong>Financials</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>LTM Revenue</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkLtmRevenue"
                                value={coprsquawkLtmRevenue}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>LTM EBITDA</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkLtmEBITDA"
                                value={coprsquawkLtmEBITDA}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                </Row>
                <br />
                <span className="hide-sm">
                    <strong>Pricing</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Benchmark Reference Rate</small>
                            <select
                                name="coprsquawkReferenceRate"
                                value={coprsquawkReferenceRate}
                                onChange={(e) => onChange(e)}
                            >
                                <option value="0">Select</option>
                                <option value="SOFR">SOFR</option>
                                <option value="Prime">Prime</option>
                                <option value="Libor">Libor</option>
                                <option value="None">None</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Spread vs. Benchmark (bps)</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkSpreadBps"
                                value={coprsquawkSpreadBps}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Benchmark Floor (bps)</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkPricingFloor"
                                value={coprsquawkPricingFloor}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>All-in Yield %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkAllInYield"
                                value={coprsquawkAllInYield}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Closing Fees %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkClosingFees"
                                value={coprsquawkClosingFees}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <span className="hide-sm">
                    <strong>Borrowing Base</strong>
                </span>
                <Row>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>A/R Advance Rate %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkARAdvRate"
                                value={coprsquawkARAdvRate}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>Inventory Advance Rate %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkInvAdvRate"
                                value={coprsquawkInvAdvRate}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>RE Advance Rate %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkREAdvRate"
                                value={coprsquawkREAdvRate}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="form-group hide-sm">
                            <small>M&E Advance Rate %</small>
                            <br />
                            <input
                                type="number"
                                placeholder=""
                                name="coprsquawkMNEAdvRate"
                                value={coprsquawkMNEAdvRate}
                                onChange={(e) => onChange(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <input
                    type="submit"
                    className="btn btn-primary my-1"
                    value="Submit"
                />
                <Link className="btn btn-light my-1" to="/privatecredit">
                    Go Back
                </Link>
            </form>
        </section>
    );
};

COPRSquawkForm.propTypes = {
    addCOPRSquawk: PropTypes.func.isRequired,
    coprsquawk: PropTypes.object.isRequired,
};

export default connect(null, { addCOPRSquawk })(COPRSquawkForm);
