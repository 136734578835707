import React, { useState } from "react";
import { Row, Col } from "reactstrap";

const BlendedPrice = () => {
    // BP = First-Out Price * (First-Out Debt / Total Debt) + Last-Out Price * (Last-Out Debt / Total Debt)
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        ltmEBITDA: "",
        firstOutDebt: "",
        firstOutPrice: "",
        lastOutDebt: "",
        lastOutPrice: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        totalDebt: "",
        blendedPrice: "",
        totalLeverage: "",
        foLeverage: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            ltmEBITDA,
            firstOutDebt,
            firstOutPrice,
            lastOutDebt,
            lastOutPrice,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !ltmEBITDA ||
            !firstOutDebt ||
            !firstOutPrice ||
            !lastOutDebt ||
            !lastOutPrice
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(ltmEBITDA) ||
            isNaN(firstOutDebt) ||
            isNaN(firstOutPrice) ||
            isNaN(lastOutDebt) ||
            isNaN(lastOutPrice)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(ltmEBITDA) <= 0 ||
            Number(firstOutDebt) <= 0 ||
            Number(firstOutPrice) <= 0 ||
            Number(lastOutDebt) <= 0 ||
            Number(lastOutPrice) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        ltmEBITDA,
        firstOutDebt,
        firstOutPrice,
        lastOutDebt,
        lastOutPrice,
    }) => {
        const totalDebt = Number(firstOutDebt) + Number(lastOutDebt);
        const totalLeverage = Number(totalDebt) / Number(ltmEBITDA);
        const foLeverage = Number(firstOutDebt) / Number(ltmEBITDA);
        const firstOutDebtWeighting = Number(firstOutDebt) / totalDebt;
        const lastOutDebtWeighting = 1 - firstOutDebtWeighting;
        const blendedPrice =
            firstOutPrice * firstOutDebtWeighting +
            lastOutPrice * lastOutDebtWeighting;

        if (isFinite(blendedPrice)) {
            const totalUnitrancheDebt = totalDebt.toFixed(2);
            const blendedUnitranchePrice = blendedPrice.toFixed(2);
            const unitrancheTotalLeverage = totalLeverage.toFixed(2);
            const unitrancheFOLeverage = foLeverage.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                totalDebt: totalUnitrancheDebt,
                blendedPrice: blendedUnitranchePrice,
                totalLeverage: unitrancheTotalLeverage,
                foLeverage: unitrancheFOLeverage,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            ltmEBITDA: "",
            firstOutDebt: "",
            firstOutPrice: "",
            lastOutDebt: "",
            lastOutPrice: "",
        });

        setResults({
            totalDebt: "",
            blendedPrice: "",
            totalLeverage: "",
            foLeverage: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Uni-Tranche Blended Pricing</strong>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="container shadow p-3 mb-5 bg-white rounded">
                        <Row>
                            <Col>
                                <div>
                                    <small>*LTM EBITDA:</small>
                                    <input
                                        type="text"
                                        name="ltmEBITDA"
                                        placeholder=""
                                        value={userValues.ltmEBITDA}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                            <Col></Col>
                        </Row>
                        <p />
                        <Row>
                            <Col>
                                <div>
                                    <small>*First-Out Debt:</small>
                                    <input
                                        type="text"
                                        name="firstOutDebt"
                                        placeholder=""
                                        value={userValues.firstOutDebt}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <small>*First-Out Price:</small>
                                    <input
                                        type="text"
                                        name="firstOutPrice"
                                        placeholder=""
                                        value={userValues.firstOutPrice}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <p />
                        <Row>
                            <Col>
                                <div>
                                    <small>*Last-Out Debt:</small>
                                    <input
                                        type="text"
                                        name="lastOutDebt"
                                        placeholder=""
                                        value={userValues.lastOutDebt}
                                        // onChange method sets the values given by the user as input to the userValues state
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <small>*Last-Out Price:</small>
                                    <input
                                        type="text"
                                        name="lastOutPrice"
                                        placeholder=""
                                        value={userValues.lastOutPrice}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            FO Leverage(x): {results.foLeverage}x <br /> Total
                            Leverage(x): {results.totalLeverage}x
                        </h6>
                        <p />
                        {/* <h4>
                Loan amount: ${userValues.amount} <br /> Interest:{' '}
                {userValues.interest}% <br /> Years to repay: {userValues.years}
              </h4> */}
                        <div>
                            <label id="label">Total Debt:</label>
                            <input
                                type="text"
                                value={results.totalDebt}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Blended Price: </label>
                            <input
                                type="text"
                                value={results.blendedPrice}
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default BlendedPrice;
