import React, { useState } from "react";

const StartupJobOffer = () => {
    //
    // state to storage the values given by the user when filling the input fields
    const [userValues, setUserValues] = useState({
        salaryComp: "",
        vestingPeriod: "",
        startupCashSalary: "",
        startupOption: "",
        startupOptionStrike: "",
        startupPrefPrice: "",
        startupPostMoneyValue: "",
        startupExitValue: "",
    });

    // state to storage the results of the calculation
    const [results, setResults] = useState({
        costToExercise: "",
        optionValue: "",
        netOptionValue: "",
        impliedPercentOwnership: "",
        impliedFullyDilutedShsOut: "",
        twoXNetOptionValue: "",
        threeXNetOptionValue: "",
        fiveXNetOptionValue: "",
        tenXNetOptionValue: "",
        fiftyXNetOptionValue: "",
        hundredXNetOptionValue: "",
        twoXNetOptionValueAnnual: "",
        threeXNetOptionValueAnnual: "",
        fiveXNetOptionValueAnnual: "",
        tenXNetOptionValueAnnual: "",
        fiftyXNetOptionValueAnnual: "",
        hundredXNetOptionValueAnnual: "",
        twoXImpliedAnnualComp: "",
        threeXImpliedAnnualComp: "",
        fiveXImpliedAnnualComp: "",
        tenXImpliedAnnualComp: "",
        fiftyXImpliedAnnualComp: "",
        hundredXImpliedAnnualComp: "",
        twoXSalaryCompare: "",
        threeXSalaryCompare: "",
        fiveXSalaryCompare: "",
        tenXSalaryCompare: "",
        fiftyXSalaryCompare: "",
        hundredXSalaryCompare: "",
        isResult: false,
    });

    // state to storage error message
    const [error, setError] = useState("");

    // event handler to update state when the user enters values
    const handleInputChange = (e) =>
        setUserValues({ ...userValues, [e.target.name]: e.target.value });

    // Note:
    // You can optionally write:
    //   const handleAmountInputChange = (event) =>
    //     setUserValues({ ...userValues, amount: event.target.value });

    //   const handleInterestInputChange = (event) =>
    //     setUserValues({ ...userValues, interest: event.target.value });

    //   const handleYearsInputChange = (event) =>
    //     setUserValues({ ...userValues, years: event.target.value });

    // Manage validations and error messages
    const isValid = () => {
        const {
            salaryComp,
            vestingPeriod,
            startupCashSalary,
            startupOption,
            startupOptionStrike,
            startupPrefPrice,
            startupPostMoneyValue,
            startupExitValue,
        } = userValues;
        let actualError = "";
        // Validate if there are values
        if (
            !salaryComp ||
            !vestingPeriod ||
            !startupCashSalary ||
            !startupOption ||
            !startupOptionStrike ||
            !startupPrefPrice ||
            !startupPostMoneyValue ||
            !startupExitValue
        ) {
            actualError = "All the values are required";
        }
        // Validate if the values are numbers
        if (
            isNaN(salaryComp) ||
            isNaN(vestingPeriod) ||
            isNaN(startupCashSalary) ||
            isNaN(startupOption) ||
            isNaN(startupOptionStrike) ||
            isNaN(startupPrefPrice) ||
            isNaN(startupPostMoneyValue) ||
            isNaN(startupExitValue)
        ) {
            actualError = "All the values must be a valid number";
        }
        // Validate if the values are positive numbers
        if (
            Number(salaryComp) <= 0 ||
            Number(vestingPeriod) <= 0 ||
            Number(startupCashSalary) <= 0 ||
            Number(startupOption) <= 0 ||
            Number(startupOptionStrike) <= 0 ||
            Number(startupPrefPrice) <= 0 ||
            Number(startupPostMoneyValue) <= 0 ||
            Number(startupExitValue) <= 0
        ) {
            actualError = "All the values must be a positive number";
        }
        if (actualError) {
            setError(actualError);
            return false;
        }
        return true;
    };

    // Handle the data submited - validate inputs and send it as a parameter to the calculation
    const handleSubmitValues = (e) => {
        e.preventDefault();
        if (isValid()) {
            setError("");
            calculateResults(userValues);
        }
    };

    // Calculation
    const calculateResults = ({
        salaryComp,
        vestingPeriod,
        startupCashSalary,
        startupOption,
        startupOptionStrike,
        startupPrefPrice,
        startupPostMoneyValue,
        startupExitValue,
    }) => {
        // Start with the easy derivations
        const impliedFullyDilutedShsOut =
            Number(startupPostMoneyValue) / Number(startupPrefPrice);
        const costToExercise =
            Number(startupOptionStrike) * Number(startupOption);
        const impliedPercentOwnership =
            Number(startupOption) / impliedFullyDilutedShsOut;
        const optionValue = Number(startupExitValue) * impliedPercentOwnership;
        const netOptionValue = optionValue - costToExercise;
        // Salary Gain / (Loss) Analysis
        // Net Option Valuation
        const twoXNetOptionValue =
            2 * startupExitValue * impliedPercentOwnership - costToExercise;
        const threeXNetOptionValue =
            3 * startupExitValue * impliedPercentOwnership - costToExercise;
        const fiveXNetOptionValue =
            5 * startupExitValue * impliedPercentOwnership - costToExercise;
        const tenXNetOptionValue =
            10 * startupExitValue * impliedPercentOwnership - costToExercise;
        const fiftyXNetOptionValue =
            50 * startupExitValue * impliedPercentOwnership - costToExercise;
        const hundredXNetOptionValue =
            100 * startupExitValue * impliedPercentOwnership - costToExercise;
        // Option Value Annualized
        const twoXNetOptionValueAnnual =
            twoXNetOptionValue / Number(vestingPeriod);
        const threeXNetOptionValueAnnual =
            threeXNetOptionValue / Number(vestingPeriod);
        const fiveXNetOptionValueAnnual =
            fiveXNetOptionValue / Number(vestingPeriod);
        const tenXNetOptionValueAnnual =
            tenXNetOptionValue / Number(vestingPeriod);
        const fiftyXNetOptionValueAnnual =
            fiftyXNetOptionValue / Number(vestingPeriod);
        const hundredXNetOptionValueAnnual =
            hundredXNetOptionValue / Number(vestingPeriod);
        // Implied Annual Compensation
        const twoXImpliedAnnualComp =
            twoXNetOptionValueAnnual + Number(startupCashSalary);
        const threeXImpliedAnnualComp =
            threeXNetOptionValueAnnual + Number(startupCashSalary);
        const fiveXImpliedAnnualComp =
            fiveXNetOptionValueAnnual + Number(startupCashSalary);
        const tenXImpliedAnnualComp =
            tenXNetOptionValueAnnual + Number(startupCashSalary);
        const fiftyXImpliedAnnualComp =
            fiftyXNetOptionValueAnnual + Number(startupCashSalary);
        const hundredXImpliedAnnualComp =
            hundredXNetOptionValueAnnual + Number(startupCashSalary);
        // Salary "Give Up" / Upside
        const twoXSalaryCompare = twoXImpliedAnnualComp - Number(salaryComp);
        const threeXSalaryCompare =
            threeXImpliedAnnualComp - Number(salaryComp);
        const fiveXSalaryCompare = fiveXImpliedAnnualComp - Number(salaryComp);
        const tenXSalaryCompare = tenXImpliedAnnualComp - Number(salaryComp);
        const fiftyXSalaryCompare =
            fiftyXImpliedAnnualComp - Number(salaryComp);
        const hundredXSalaryCompare =
            hundredXImpliedAnnualComp - Number(salaryComp);

        if (isFinite(costToExercise)) {
            const computedCostToExercise = costToExercise.toFixed(2);
            const computedOptionValue = optionValue.toFixed(2);
            const computedNetOptionValue = netOptionValue.toFixed(2);
            const computedImpliedPercentOwnership =
                impliedPercentOwnership.toFixed(3);
            const computedImpliedFullyDilutedShsOut =
                impliedFullyDilutedShsOut.toFixed(2);
            const computedTwoXNetOptionValue = twoXNetOptionValue.toFixed(2);
            const computedThreeXNetOptionValue =
                threeXNetOptionValue.toFixed(2);
            const computedFiveXNetOptionValue = fiveXNetOptionValue.toFixed(2);
            const computedTenXNetOptionValue = tenXNetOptionValue.toFixed(2);
            const computedFiftyXNetOptionValue =
                fiftyXNetOptionValue.toFixed(2);
            const computedHundredXNetOptionValue =
                hundredXNetOptionValue.toFixed(2);
            const computedTwoXNetOptionValueAnnual =
                twoXNetOptionValueAnnual.toFixed(2);
            const computedThreeXNetOptionValueAnnual =
                threeXNetOptionValueAnnual.toFixed(2);
            const computedFiveXNetOptionValueAnnual =
                fiveXNetOptionValueAnnual.toFixed(2);
            const computedTenXNetOptionValueAnnual =
                tenXNetOptionValueAnnual.toFixed(2);
            const computedFiftyXNetOptionValueAnnual =
                fiftyXNetOptionValueAnnual.toFixed(2);
            const computedHundredXNetOptionValueAnnual =
                hundredXNetOptionValueAnnual.toFixed(2);
            const computedTwoXImpliedAnnualComp =
                twoXImpliedAnnualComp.toFixed(2);
            const computedThreeXImpliedAnnualComp =
                threeXImpliedAnnualComp.toFixed(2);
            const computedFiveXImpliedAnnualComp =
                fiveXImpliedAnnualComp.toFixed(2);
            const computedTenXImpliedAnnualComp =
                tenXImpliedAnnualComp.toFixed(2);
            const computedFiftyXImpliedAnnualComp =
                fiftyXImpliedAnnualComp.toFixed(2);
            const computedHundredXImpliedAnnualComp =
                hundredXImpliedAnnualComp.toFixed(2);
            const computedTwoXSalaryCompare = twoXSalaryCompare.toFixed(2);
            const computedThreeXSalaryCompare = threeXSalaryCompare.toFixed(2);
            const computedFiveXSalaryCompare = fiveXSalaryCompare.toFixed(2);
            const computedTenXSalaryCompare = tenXSalaryCompare.toFixed(2);
            const computedFiftyXSalaryCompare = fiftyXSalaryCompare.toFixed(2);
            const computedHundredXSalaryCompare =
                hundredXSalaryCompare.toFixed(2);

            // Set up results to the state to be displayed to the user
            setResults({
                costToExercise: computedCostToExercise,
                optionValue: computedOptionValue,
                netOptionValue: computedNetOptionValue,
                impliedPercentOwnership: computedImpliedPercentOwnership,
                impliedFullyDilutedShsOut: computedImpliedFullyDilutedShsOut,
                twoXNetOptionValue: computedTwoXNetOptionValue,
                threeXNetOptionValue: computedThreeXNetOptionValue,
                fiveXNetOptionValue: computedFiveXNetOptionValue,
                tenXNetOptionValue: computedTenXNetOptionValue,
                fiftyXNetOptionValue: computedFiftyXNetOptionValue,
                hundredXNetOptionValue: computedHundredXNetOptionValue,
                twoXNetOptionValueAnnual: computedTwoXNetOptionValueAnnual,
                threeXNetOptionValueAnnual: computedThreeXNetOptionValueAnnual,
                fiveXNetOptionValueAnnual: computedFiveXNetOptionValueAnnual,
                tenXNetOptionValueAnnual: computedTenXNetOptionValueAnnual,
                fiftyXNetOptionValueAnnual: computedFiftyXNetOptionValueAnnual,
                hundredXNetOptionValueAnnual:
                    computedHundredXNetOptionValueAnnual,
                twoXImpliedAnnualComp: computedTwoXImpliedAnnualComp,
                threeXImpliedAnnualComp: computedThreeXImpliedAnnualComp,
                fiveXImpliedAnnualComp: computedFiveXImpliedAnnualComp,
                tenXImpliedAnnualComp: computedTenXImpliedAnnualComp,
                fiftyXImpliedAnnualComp: computedFiftyXImpliedAnnualComp,
                hundredXImpliedAnnualComp: computedHundredXImpliedAnnualComp,
                twoXSalaryCompare: computedTwoXSalaryCompare,
                threeXSalaryCompare: computedThreeXSalaryCompare,
                fiveXSalaryCompare: computedFiveXSalaryCompare,
                tenXSalaryCompare: computedTenXSalaryCompare,
                fiftyXSalaryCompare: computedFiftyXSalaryCompare,
                hundredXSalaryCompare: computedHundredXSalaryCompare,
                isResult: true,
            });
        }
        return;
    };

    // Clear input fields
    const clearFields = () => {
        setUserValues({
            salaryComp: "",
            vestingPeriod: "",
            startupCashSalary: "",
            startupOption: "",
            startupOptionStrike: "",
            startupPrefPrice: "",
            startupPostMoneyValue: "",
            startupExitValue: "",
        });

        setResults({
            costToExercise: "",
            optionValue: "",
            netOptionValue: "",
            impliedPercentOwnership: "",
            impliedFullyDilutedShsOut: "",
            twoXNetOptionValue: "",
            threeXNetOptionValue: "",
            fiveXNetOptionValue: "",
            tenXNetOptionValue: "",
            fiftyXNetOptionValue: "",
            hundredXNetOptionValue: "",
            twoXNetOptionValueAnnual: "",
            threeXNetOptionValueAnnual: "",
            fiveXNetOptionValueAnnual: "",
            tenXNetOptionValueAnnual: "",
            fiftyXNetOptionValueAnnual: "",
            hundredXNetOptionValueAnnual: "",
            twoXImpliedAnnualComp: "",
            threeXImpliedAnnualComp: "",
            fiveXImpliedAnnualComp: "",
            tenXImpliedAnnualComp: "",
            fiftyXImpliedAnnualComp: "",
            hundredXImpliedAnnualComp: "",
            twoXSalaryCompare: "",
            threeXSalaryCompare: "",
            fiveXSalaryCompare: "",
            tenXSalaryCompare: "",
            fiftyXSalaryCompare: "",
            hundredXSalaryCompare: "",
            isResult: false,
        });
    };

    return (
        <div className="form">
            <h6 className="text-primary text-center">
                <strong>Startup Job Offer vs. Market Salary Comparison</strong>
                <br />
                <small>(All-in Salary Relative Value Analysis)</small>
            </h6>
            <small>* = required field</small>
            <p />
            {/* Display the error when it exists */}
            <small>
                <strong>{error}</strong>
            </small>
            <form onSubmit={handleSubmitValues}>
                {/* ternary operator manages when the calculator and results will be displayed to the user */}
                {!results.isResult ? (
                    //   Form to collect data from the user
                    <div className="box shadow p-3 mb-5 bg-white rounded">
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>
                                    *All-In Market Annual Compensation:
                                </small>
                                <input
                                    type="text"
                                    name="salaryComp"
                                    placeholder=""
                                    value={userValues.salaryComp}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Option Vesting Period (yrs):</small>
                                <input
                                    type="text"
                                    name="vestingPeriod"
                                    placeholder=""
                                    value={userValues.vestingPeriod}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Job Offer - Cash Compensation:</small>
                                <input
                                    type="text"
                                    name="startupCashSalary"
                                    placeholder=""
                                    value={userValues.startupCashSalary}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Job Offer - Equity Options #:</small>
                                <input
                                    type="text"
                                    name="startupOption"
                                    placeholder=""
                                    value={userValues.startupOption}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Job Offer - Option Strike Price:</small>
                                <input
                                    type="text"
                                    name="startupOptionStrike"
                                    placeholder=""
                                    value={userValues.startupOptionStrike}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <div className="form-row d-flex justify-content-around">
                            <div>
                                <small>*Startup - Preferred Price:</small>
                                <input
                                    type="text"
                                    name="startupPrefPrice"
                                    placeholder=""
                                    value={userValues.startupPrefPrice}
                                    // onChange method sets the values given by the user as input to the userValues state
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>
                                    *Post-Money Valuation (last round):
                                </small>
                                <input
                                    type="text"
                                    name="startupPostMoneyValue"
                                    placeholder=""
                                    value={userValues.startupPostMoneyValue}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <small>*Hypothetical Exit Valuation:</small>
                                <input
                                    type="text"
                                    name="startupExitValue"
                                    placeholder=""
                                    value={userValues.startupExitValue}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <p />
                        <input
                            type="submit"
                            value="Submit"
                            className="btn btn-secondary mx-auto d-block"
                        />
                        <br />
                    </div>
                ) : (
                    //   Form to display the results to the user
                    <div>
                        <h6>
                            <strong>Inputs</strong>
                            <br />
                            Comparative Market Compensation:&nbsp;
                            {userValues.salaryComp
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <br />
                            Startup Offer Cash / Options / Strike /
                            Vesting:&nbsp;
                            {userValues.startupCashSalary
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;/&nbsp;
                            {userValues.startupOption
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;/&nbsp;
                            {userValues.startupOptionStrike
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;/&nbsp;{userValues.vestingPeriod}yrs
                            <br />
                            Startup Preferred Price / Post-Money / Exit
                            Valuation:&nbsp;
                            {userValues.startupPrefPrice
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;/&nbsp;
                            {userValues.startupPostMoneyValue
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            &nbsp;/&nbsp;
                            {userValues.startupExitValue
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            <p />
                        </h6>
                        <p />
                        <h6 className="text-center">
                            <strong>
                                Startup Salary Gain/(Loss) Analysis -- Exit
                                Valuation Scenarios
                            </strong>
                            <p />
                            <table className="calctable">
                                <tr>
                                    <td></td>
                                    <th scope="col">2x</th>
                                    <th scope="col">3x</th>
                                    <th scope="col">5x</th>
                                    <th scope="col">10x</th>
                                    <th scope="col">50x</th>
                                    <th scope="col">100x</th>
                                </tr>
                                <tr>
                                    <th scope="row">Net Option Value</th>
                                    <td>
                                        {results.twoXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.threeXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.fiveXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.tenXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.fiftyXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.hundredXNetOptionValue
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Annualized Option Value</th>
                                    <td>
                                        {(results.twoXNetOptionValueAnnual * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {(
                                            results.threeXNetOptionValueAnnual *
                                            1
                                        )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {(results.fiveXNetOptionValueAnnual * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {(results.tenXNetOptionValueAnnual * 1)
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {(
                                            results.fiftyXNetOptionValueAnnual *
                                            1
                                        )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {(
                                            results.hundredXNetOptionValueAnnual *
                                            1
                                        )
                                            .toFixed(2)
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Implied Compensation</th>
                                    <td>
                                        {results.twoXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.threeXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.fiveXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.tenXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.fiftyXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                    <td>
                                        {results.hundredXImpliedAnnualComp
                                            .toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                            )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Salary Gain / (Loss)</th>
                                    <td>
                                        {results.twoXSalaryCompare < 0
                                            ? "(" +
                                              (results.twoXSalaryCompare * -1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (results.twoXSalaryCompare * 1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                    <td>
                                        {results.threeXSalaryCompare < 0
                                            ? "(" +
                                              (results.threeXSalaryCompare * -1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (results.threeXSalaryCompare * 1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                    <td>
                                        {results.fiveXSalaryCompare < 0
                                            ? "(" +
                                              (results.fiveXSalaryCompare * -1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (results.fiveXSalaryCompare * 1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                    <td>
                                        {results.tenXSalaryCompare < 0
                                            ? "(" +
                                              (results.tenXSalaryCompare * -1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (results.tenXSalaryCompare * 1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                    <td>
                                        {results.fiftyXSalaryCompare < 0
                                            ? "(" +
                                              (results.fiftyXSalaryCompare * -1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (results.fiftyXSalaryCompare * 1)
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                    <td>
                                        {results.hundredXSalaryCompare < 0
                                            ? "(" +
                                              (
                                                  results.hundredXSalaryCompare *
                                                  -1
                                              )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  ) +
                                              ")"
                                            : (
                                                  results.hundredXSalaryCompare *
                                                  1
                                              )
                                                  .toFixed(2)
                                                  .toString()
                                                  .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                  )}
                                    </td>
                                </tr>
                            </table>
                        </h6>
                        <br />
                        <h6>
                            <strong>Job Offer Option Analysis</strong>
                        </h6>
                        <div>
                            <label id="label">Cost to Exercise:</label>
                            <input
                                type="text"
                                value={(results.costToExercise * 1)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Option Value / Payout:</label>
                            <input
                                type="text"
                                value={(results.optionValue * 1)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Net Option Value:</label>
                            <input
                                type="text"
                                value={(results.netOptionValue * 1)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">Implied % Ownership:</label>
                            <input
                                type="text"
                                value={
                                    (
                                        (userValues.startupOption /
                                            results.impliedFullyDilutedShsOut) *
                                        100
                                    ).toFixed(4) + "%"
                                }
                                disabled
                            />
                        </div>
                        <div>
                            <label id="label">
                                Implied Fully-diluted Shares Outstanding:
                            </label>
                            <input
                                type="text"
                                value={(results.impliedFullyDilutedShsOut * 1)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled
                            />
                        </div>
                        <p />
                        {/* Button to clear fields */}
                        <input
                            className="btn btn-primary mx-auto d-block"
                            value="Calculate again"
                            type="button"
                            onClick={clearFields}
                        />
                        <br />
                    </div>
                )}
            </form>
        </div>
    );
};

export default StartupJobOffer;
