import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import formatDate from "../../../utils/formatDate";

const DetailedCOPRFundSquawkItem = ({
    coprfundsquawk: {
        _id,
        user,
        username,
        coprfundsquawkFirmName,
        coprfundsquawkFirmUrl,
        // coprfundsquawkPRUrl,
        coprfundsquawkFirmLocation,
        coprfundsquawkName,
        coprfundsquawkStrategy,
        coprfundsquawkSize,
        coprfundsquawkManagementFee,
        coprfundsquawkPerformanceFee,
        coprfundsquawkLp,
        coprfundsquawkGp,
        coprfundsquawkVintage,
        coprfundsquawkLife,
        coprfundsquawkLifeStage,
        coprfundsquawkCapitalBase,
        coprfundsquawkGrossIRR,
        coprfundsquawkNetIRR,
        coprfundsquawkContributions,
        coprfundsquawkDistributions,
        coprfundsquawkNetCashFlow,
        coprfundsquawkNotes,
        date,
    },
}) => (
    <div className="posts">
        <div>
            <Row>
                <Col>
                    {/* {coprfundsquawkPRUrl === "" ? ( */}
                    <strong>{coprfundsquawkFirmName}</strong>
                    {/* ) : (
                        <a href={coprfundsquawkPRUrl} target="_blank" rel="noopener noreferrer">
                            <strong>Firm: {coprfundsquawkFirmName}</strong>
                        </a>
                    )} */}
                </Col>
                <Col>
                    <small>
                        {/* <a href={coprfundsquawkFirmUrl} target="_blank" rel="noopener noreferrer"></a> */}
                        {coprfundsquawkFirmUrl}
                    </small>
                </Col>
                <Col>
                    <small>{coprfundsquawkFirmLocation}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        Size: $
                        {coprfundsquawkSize
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>Fund: {coprfundsquawkName}</small>
                </Col>
                <Col>
                    <small>Strategy: {coprfundsquawkStrategy}</small>
                </Col>
            </Row>
            <br />
            <strong>Fund Details</strong>
            <Row>
                <Col>
                    <small>Fund LP(s): {coprfundsquawkLp}</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <small>Fund GP(s): {coprfundsquawkGp}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>Vintage (year): {coprfundsquawkVintage}</small>
                </Col>
                <Col>
                    <small>Life (yrs): {coprfundsquawkLife}</small>
                </Col>
                <Col>
                    <small>Stage: {coprfundsquawkLifeStage}</small>
                </Col>
                <Col>
                    <small>Capital: {coprfundsquawkCapitalBase}</small>
                </Col>
            </Row>
            <br />
            <strong>Fund Metrics</strong>
            <Row>
                <Col>
                    <small>Management Fee: {coprfundsquawkManagementFee}</small>
                </Col>
                <Col>
                    <small>
                        Carried Interest / Performance Fee:{" "}
                        {coprfundsquawkPerformanceFee}
                    </small>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small>Gross IRR: {coprfundsquawkGrossIRR}</small>
                </Col>
                <Col>
                    <small>Net IRR: {coprfundsquawkNetIRR}</small>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <small>
                        Contributions:{" "}
                        {coprfundsquawkContributions
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>
                        Distributions:{" "}
                        {coprfundsquawkDistributions
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
                <Col>
                    <small>
                        Net Cash Flow:{" "}
                        {coprfundsquawkNetCashFlow
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </small>
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <small>{coprfundsquawkNotes}</small>
                </Col>
            </Row>
            <p />
            <Row>
                <Col>
                    <small>
                        by <Link to={`/profile/${user}`}>{username}</Link> on{" "}
                        {formatDate(date)}
                    </small>
                </Col>
            </Row>
        </div>
    </div>
);

DetailedCOPRFundSquawkItem.propTypes = {
    coprfundsquawk: PropTypes.object.isRequired,
};

export default DetailedCOPRFundSquawkItem;
