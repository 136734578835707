import {
    GET_COPRPESQUAWKS,
    GET_COPRPESQUAWKS_REPO,
    GET_COPRPESQUAWK,
    COPRPESQUAWK_ERROR,
    UPDATE_COPRPELIKES,
    DELETE_COPRPESQUAWK,
    UPDATE_COPRPESQUAWK,
    ADD_COPRPESQUAWK,
    ADD_COPRPESQUAWK_COMMENT,
    REMOVE_COPRPESQUAWK_COMMENT,
} from "../actions/types";

const initialState = {
    coprpesquawks: [],
    coprpesquawk: null,
    loading: true,
    error: {},
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COPRPESQUAWKS:
        case GET_COPRPESQUAWKS_REPO:
            return {
                ...state,
                coprpesquawks: payload,
                loading: false,
            };
        case GET_COPRPESQUAWK:
            return {
                ...state,
                coprpesquawk: payload,
                loading: false,
            };
        case ADD_COPRPESQUAWK:
            return {
                ...state,
                coprpesquawks: [payload, ...state.coprpesquawks],
                loading: false,
            };
        case UPDATE_COPRPESQUAWK:
            return {
                ...state,
                coprpesquawks: state.coprpesquawks.map((coprpesquawk) =>
                    coprpesquawk._id === payload.id
                        ? [...payload, ...state.coprpesquawks]
                        : coprpesquawk
                ),
                loading: false,
            };
        case DELETE_COPRPESQUAWK:
            return {
                ...state,
                coprpesquawks: state.coprpesquawks.filter(
                    (coprpesquawk) => coprpesquawk._id !== payload
                ),
                loading: false,
            };
        case COPRPESQUAWK_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        case UPDATE_COPRPELIKES:
            return {
                ...state,
                coprpesquawks: state.coprpesquawks.map((coprpesquawk) =>
                    coprpesquawk._id === payload.id
                        ? { ...coprpesquawk, likes: payload.likes }
                        : coprpesquawk
                ),
                loading: false,
            };
        case ADD_COPRPESQUAWK_COMMENT:
            return {
                ...state,
                coprpesquawk: { ...state.coprpesquawk, comments: payload },
                loading: false,
            };
        case REMOVE_COPRPESQUAWK_COMMENT:
            return {
                ...state,
                coprpesquawk: {
                    ...state.coprpesquawk,
                    comments: state.coprpesquawk.comments.filter(
                        (comment) => comment._id !== payload
                    ),
                },
                loading: false,
            };
        default:
            return state;
    }
}
