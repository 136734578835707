import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import formatDate from "../../../utils/formatDate";
import { deleteCOPRSquawkComment } from "../../../actions/coprsquawk";

const COPRSquawkCommentItem = ({
    coprsquawkId,
    comment: { _id, text, user, username, date },
    auth,
    deleteCOPRSquawkComment,
}) => (
    <div className="post bg-white p-1 my-1">
        <div>
            <Link to={`/profile/${user}`}>
                <img className="round-img" alt="" />
                <h4>{username}</h4>
            </Link>
        </div>
        <div>
            <p className="my-1">{text}</p>
            <p className="post-date">squawked on {formatDate(date)}</p>
            {!auth.loading && user === auth.user._id && (
                <button
                    onClick={(e) => deleteCOPRSquawkComment(coprsquawkId, _id)}
                    type="button"
                    className="btn btn-danger"
                >
                    <i className="fas fa-times"></i>
                </button>
            )}
        </div>
    </div>
);

COPRSquawkCommentItem.propTypes = {
    coprsquawkId: PropTypes.string.isRequired,
    comment: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    deleteCOPRSquawkComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { deleteCOPRSquawkComment })(
    COPRSquawkCommentItem
);
