import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import { Row, Col } from "reactstrap";

const SearchBox = () => {
    const [keyword, setKeyword] = useState("");
    const navigate = useNavigate();

    const submitHandler = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            navigate(
                `/venturecapital/repo/search/${encodeURIComponent(keyword)}`
            );
        } else {
            navigate(`/venturecapital/repo`);
        }
    };

    return (
        <Form onSubmit={submitHandler} inline className="dash-buttons search">
            <Row>
                <Col>
                    <FormControl
                        type="text"
                        name="q"
                        onChange={(e) => setKeyword(e.target.value)}
                        placeholder="Search..."
                        className="mr-sm-2 ml-sm-5 mb-2"
                    ></FormControl>
                    <Button
                        type="submit"
                        variant="outline-primary"
                        className="mb-2 dash-buttons"
                    >
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchBox;
