import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../layout/Spinner";
import DetailedCOPRBKSquawkItem from "./DetailedCOPRBKSquawkItem";
import COPRBKSquawkCommentForm from "./COPRBKSquawkCommentForm";
import COPRBKSquawkCommentItem from "./COPRBKSquawkCommentItem";
import { getCOPRBKSquawk } from "../../../actions/coprbksquawk";

const COPRBKSquawk = ({
    getCOPRBKSquawk,
    coprbksquawk: { coprbksquawk, loading },
}) => {
    const { id } = useParams();
    useEffect(() => {
        getCOPRBKSquawk(id);
    }, [getCOPRBKSquawk, id]);

    return loading || coprbksquawk === null ? (
        <Spinner />
    ) : (
        <section className="container">
            <Link to="/restructuring" className="btn">
                Back
            </Link>
            <h3 className="text-primary text-center">Transaction Details</h3>
            <br />
            <DetailedCOPRBKSquawkItem coprbksquawk={coprbksquawk} />
            <p />
            <hr />
            <br />
            <COPRBKSquawkCommentForm coprbksquawkId={coprbksquawk._id} />
            <br />
            <div className="comments">
                {coprbksquawk.comments.map((comment) => (
                    <COPRBKSquawkCommentItem
                        key={comment._id}
                        comment={comment}
                        coprbksquawkId={coprbksquawk._id}
                    />
                ))}
            </div>
        </section>
    );
};

COPRBKSquawk.propTypes = {
    getCOPRBKSquawk: PropTypes.func.isRequired,
    coprbksquawk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    coprbksquawk: state.coprbksquawk,
});

export default connect(mapStateToProps, { getCOPRBKSquawk })(COPRBKSquawk);
