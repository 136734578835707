import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Spinner from "../layout/Spinner";
// import ProfileNotFound from "../layout/ProfileNotFound";
import ProfileTop from "./ProfileTop";
import ProfileAbout from "./ProfileAbout";
import ProfileExperience from "./ProfileExperience";
import ProfileEducation from "./ProfileEducation";
import { getProfileById } from "../../actions/profile";
import ProfileTombstone from "./ProfileTombstone";

const Profile = ({ getProfileById, profile: { profile }, auth }) => {
    const { id } = useParams();
    useEffect(() => {
        getProfileById(id);
    }, [getProfileById, id]);

    return (
        <section className="container">
            {profile === null ? (
                <Spinner />
            ) : (
                <Fragment>
                    <div className="row">
                        <div className="col">
                            <Link
                                to="/privatecredit"
                                className="btn btn-primary"
                            >
                                &laquo; Deals
                            </Link>
                        </div>
                        <p />
                        {/* <Link to="/profiles" className="btn btn-light">
                        All Profiles
                    </Link> */}
                        <div className="col">
                            {auth.isAuthenticated &&
                                auth.loading === false &&
                                auth.user._id === profile.user._id && (
                                    <Link
                                        to="/create-profile"
                                        className="btn btn-outline-primary float-right"
                                    >
                                        Edit Bio
                                    </Link>
                                )}
                        </div>
                    </div>
                    <div className="profile-grid my-1">
                        <ProfileTop profile={profile} />
                        <ProfileAbout profile={profile} />
                        <div className="profile-exp bg-white p-2">
                            <h2 className="text-primary">Experience</h2>
                            {profile.experience.length > 0 ? (
                                <Fragment>
                                    {profile.experience.map((experience) => (
                                        <ProfileExperience
                                            key={experience._id}
                                            experience={experience}
                                        />
                                    ))}
                                </Fragment>
                            ) : (
                                <h4>No experience credentials</h4>
                            )}
                        </div>
                        <div className="profile-edu bg-white p-2">
                            <h2 className="text-primary">Education</h2>
                            {profile.education.length > 0 ? (
                                <Fragment>
                                    {profile.education.map((education) => (
                                        <ProfileEducation
                                            key={education._id}
                                            education={education}
                                        />
                                    ))}
                                </Fragment>
                            ) : (
                                <h4>No education credentials</h4>
                            )}
                        </div>
                    </div>
                    <h2 className="text-primary">Tombstones</h2>
                    <br />
                    {profile.tombstone.length > 0 ? (
                        <Fragment>
                            <Row md={4}>
                                {profile.tombstone.map((tombstone) => (
                                    <Col sm={12} md={6} lg={4} xl={4}>
                                        <ProfileTombstone
                                            key={tombstone._id}
                                            tombstone={tombstone}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Fragment>
                    ) : (
                        <h4>None listed</h4>
                    )}
                </Fragment>
            )}
        </section>
    );
};

Profile.propTypes = {
    getProfileById: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
    auth: state.auth,
});

export default connect(mapStateToProps, { getProfileById })(Profile);
