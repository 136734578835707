import api from "../utils/api";
import { setAlert } from "./alert";
import {
    DELETE_COPRSQUAWK,
    UPDATE_COPRSQUAWK,
    CLEAR_COPRSQUAWK,
    GET_COPRSQUAWKS,
    GET_COPRSQUAWKS_REPO,
    GET_COPRSQUAWK,
    COPRSQUAWK_ERROR,
    UPDATE_COPRLIKES,
    ADD_COPRSQUAWK,
    ADD_COPRSQUAWK_COMMENT,
    REMOVE_COPRSQUAWK_COMMENT,
} from "./types";

// Clear single COPR squawk (when navigating away e.g. "Back" button -- figure this out)
export const clearCOPRSquawk = () => (dispatch) => {
    dispatch({
        type: CLEAR_COPRSQUAWK,
    });
};

// Get COPR squawks
export const getCOPRSquawks =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprsquawks?keyword=${keyword}`);

            dispatch({
                type: GET_COPRSQUAWKS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Get COPR squawks -- user repo
export const getCOPRSquawksRepo =
    (keyword = "") =>
    async (dispatch) => {
        try {
            const res = await api.get(`/coprsquawks/repo?keyword=${keyword}`);

            dispatch({
                type: GET_COPRSQUAWKS_REPO,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: COPRSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Add like
export const addLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprsquawks/like/${id}`);

        dispatch({
            type: UPDATE_COPRLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/coprsquawks/unlike/${id}`);

        dispatch({
            type: UPDATE_COPRLIKES,
            payload: { id, likes: res.data },
        });
    } catch (err) {
        dispatch({
            type: COPRSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Delete COPR squawk
export const deleteCOPRSquawk = (id) => async (dispatch) => {
    if (window.confirm("Are you sure? This cannot be undone!")) {
        try {
            await api.delete(`/coprsquawks/${id}`);

            dispatch({
                type: DELETE_COPRSQUAWK,
                payload: id,
            });

            dispatch(setAlert("Deal squawk deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    }
};

// Add COPR squawk
export const addCOPRSquawk = (formdata) => async (dispatch) => {
    try {
        const res = await api.post("/coprsquawks", formdata);

        dispatch({
            type: ADD_COPRSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal squawked", "success"));
    } catch (err) {
        dispatch({
            type: COPRSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Update COPR squawk
export const updateCOPRSquawk = (id, updatedFormdata) => async (dispatch) => {
    try {
        const res = await api.put(`/coprsquawks/${id}`, updatedFormdata);

        dispatch({
            type: UPDATE_COPRSQUAWK,
            payload: res.data,
        });

        dispatch(setAlert("Deal Updated", "success"));
    } catch (err) {
        dispatch({
            type: COPRSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Get COPR squawk
export const getCOPRSquawk = (id) => async (dispatch) => {
    try {
        const res = await api.get(`/coprsquawks/${id}`);

        dispatch({
            type: GET_COPRSQUAWK,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: COPRSQUAWK_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });
    }
};

// Add COPR squawk comment
export const addCOPRSquawkComment =
    (coprsquawkId, formdata) => async (dispatch) => {
        try {
            const res = await api.post(
                `/coprsquawks/comment/${coprsquawkId}`,
                formdata
            );

            dispatch({
                type: ADD_COPRSQUAWK_COMMENT,
                payload: res.data,
            });

            dispatch(setAlert("Wire added", "success"));
        } catch (err) {
            dispatch({
                type: COPRSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };

// Delete COPR squawk comment
export const deleteCOPRSquawkComment =
    (coprsquawkId, coprcommentId) => async (dispatch) => {
        try {
            await api.delete(
                `/coprsquawks/comment/${coprsquawkId}/${coprcommentId}`
            );

            dispatch({
                type: REMOVE_COPRSQUAWK_COMMENT,
                payload: coprcommentId,
            });

            dispatch(setAlert("Wire deleted", "success"));
        } catch (err) {
            dispatch({
                type: COPRSQUAWK_ERROR,
                payload: {
                    msg: err.response.statusText,
                    status: err.response.status,
                },
            });
        }
    };
